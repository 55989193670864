import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Gravatar from 'react-gravatar'
import { Menu, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { handleLogout, getSafeAddress, getEmail } from '../utils'
import { useAccount } from 'wagmi'
import getAvatar from 'dravatar'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const UserDropdown = _ => {
  let email = getEmail()

  const [avatar, setAvatar] = useState()
  const [loading, setLoading] = useState(false)

  const { data: accountData } = useAccount({
    fetchEns: true
  })

  useEffect(() => {
    async function fetch_avatar() {
      let address = getSafeAddress()
      setLoading(true)
      let avatar = await getAvatar(address)
      setAvatar(avatar)
      setLoading(false)
    }

    fetch_avatar()
  }, [])

  if (loading) return null

  return (
    <div className="flex items-center">
      <Menu as="div" className="px-3 relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700">
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    {email ? (
                      <Gravatar
                        email={email}
                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                      />
                    ) : (
                      <div className="mr-2">
                        <img
                          className="inline-block h-6 w-6 rounded-full"
                          alt=""
                          src={avatar}
                        />
                      </div>
                    )}
                  </span>
                  <SelectorIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-10 mx-3 origin-top absolute right-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
              >
                {email && (
                  <Menu.Item>
                    <div className="block px-4 py-4 text-sm">
                      <span className="text-gray-500 opacity-70 text-sm truncate">
                        {email}
                      </span>
                    </div>
                  </Menu.Item>
                )}
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Settings
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        onClick={handleLogout}
                        to="/logout"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Logout
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default UserDropdown
