import { useState } from 'react'

import { request } from '../../utils'

import Spinner from '../../components/Spinner'

import ConfirmEmailForm from './ConfirmEmailForm'
import ConfirmEmailFormSubmitted from './ConfirmEmailFormSubmitted'

export default function ConfirmEmail() {
  let url = `/api/account_recovery/confirm_email`
  let [email, setEmail] = useState('')
  let [error, setError] = useState(false)
  let [loading, setLoading] = useState(false)
  let [submitted, setSubmitted] = useState(false)

  const submit = async () => {
    setError(false)
    setLoading(true)

    request(url, {
      method: 'POST',
      body: JSON.stringify({ email })
    })
      .then(res => res.json())
      .then(async ({ error, errors }) => {
        if (error || errors) {
          throw error || errors
        }

        setLoading(false)
        setSubmitted(true)
      })
  }

  if (loading) return <Spinner />

  return (
    <div className="min-h-screen flex mt-10 items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      {submitted ? (
        <ConfirmEmailFormSubmitted />
      ) : (
        <ConfirmEmailForm
          url={url}
          setEmail={setEmail}
          email={email}
          error={error}
          loading={loading}
          submit={submit}
        />
      )}
    </div>
  )
}
