const NumberBox = props => (
  <div className={props.className}>
    <h2 className="uppercase tracking-widest opacity-50 text-gray-600 text-xs">
      {props.title}
    </h2>
    <p
      className={`text-complimantary-800 ${
        props.smaller ? 'text-2xl' : 'mt-2 text-4xl'
      }`}
    >
      {props.number}
    </p>
    <small className="mt-2 text-xs text-gray-500">{props.bottomText}</small>
    {props.children}
  </div>
)

export default NumberBox
