import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns'
import 'date-fns'
import { format, parse } from 'date-fns'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const chartOptions = params => ({
  responsive: true,
  plugins: {
    filler: {
      propagate: false
    },
    title: {
      display: false,
      text: 'APR'
    },
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      displayColors: false,
      bodyFont: { weight: 'bold', size: 16 },
      backgroundColor: 'rgba(203, 80, 27, .9)',
      callbacks: {
        label: context => {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }

          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            }).format(context.parsed.y)
          }

          return label
        }
      }
    }
  },
  interaction: {
    intersect: false
  },
  scales: {
    x: {
      title: {
        display: false,
        text: 'Date'
      },
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false
      },
      ticks: {
        maxTicksLimit: 5,
        maxRotation: 0,
        padding: 25,
        color: 'rgba(88, 83, 85, .7)',
        font: { size: 14, family: 'Rubik' }
      }
    },
    y: {
      beginAtZero: true,
      title: {
        display: false,
        text: 'value'
      },
      ticks: {
        callback: (value, _index, _values) =>
          params.percentage ? `${value}%` : `$${value.toFixed(2)}`,
        min: 0,
        padding: 25,
        maxTicksLimit: 6,
        color: 'rgba(11, 67, 81, .4)',
        font: { size: 14, family: 'Rubik' }
      },
      grid: {
        color: '#D1EEF5',
        drawBorder: false
      }
    }
  }
})

function getGradient(ctx, chartArea, theme) {
  let width, height, gradient
  let chartWidth = chartArea.right - chartArea.left
  let chartHeight = chartArea.bottom - chartArea.top

  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)

    switch (theme) {
      case 'blue':
        gradient.addColorStop(0, 'rgba(73, 185, 213, 0)')
        gradient.addColorStop(1, 'rgba(73, 185, 213, 0.44)')
        break
      default:
        // green
        gradient.addColorStop(0, 'rgb(255, 255, 255)')
        gradient.addColorStop(1, 'rgba(118, 176, 65, 0)')
    }
  }

  return gradient
}

const chartData = ({ label, dates, values, theme }) => ({
  labels: dates,
  datasets: [
    {
      label,
      data: values,
      borderColor:
        theme === 'blue' ? 'rgba(4, 125, 156, 0.44)' : 'rgb(118, 176, 65)',
      backgroundColor(context) {
        let chart = context.chart
        let { ctx, chartArea } = chart

        if (!chartArea) {
          return
        }

        return getGradient(ctx, chartArea, theme)
      },
      pointStyle: 'circle',
      pointRadius: context =>
        theme === 'green' &&
        context.dataIndex === context.dataset.data.length - 1
          ? 10
          : 0,
      pointBorderColor: 'rgb(94, 141, 52)',
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(255, 255, 255)',
      pointHoverRadius: theme === 'green' ? 8 : 0,
      pointHoverBackgroundColor: 'rgb(255, 255, 255)',
      tension: 0.4,
      fill: 'start'
    }
  ]
})

let defaultDates = [
  new Date(2021, 1, 1),
  new Date(2021, 2, 1),
  new Date(2021, 3, 1),
  new Date(2021, 4, 1),
  new Date(2021, 5, 1),
  new Date(2021, 6, 1),
  new Date(2021, 7, 1),
  new Date(2021, 8, 1),
  new Date(2021, 9, 1),
  new Date(2021, 10, 1),
  new Date(2021, 11, 1),
  new Date(2021, 12, 1)
]

let defaultValues = [0, 5, 10, 15, 30, 100, 60, 70, 80, 100, 110, 120]

const LineChart = ({
  dates = defaultDates,
  values = defaultValues,
  theme = 'green',
  label = 'Savings',
  percentage,
  rawDate
}) => {
  dates = rawDate
    ? dates.map(d => format(d, 'dd MMM'))
    : dates.map(d => format(parse(d, 'yyyy-MM-dd', new Date(d)), 'dd MMM'))
  dates[dates.length - 1] = 'now'

  let data = chartData({ label, theme, dates, values })
  let options = chartOptions({ percentage })

  return <Line data={data} options={options} />
}

export default LineChart
