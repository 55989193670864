import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSigner, request } from '../utils'
import { safeSignTypedData } from '../utils/safe_utils'
import PendingTransactionModal from '../components/PendingTransactionModal'
import WebsocketContext from '../context/WebsocketContext'

import {
  getKashiBalance,
  getAaveBalance,
  getimUSDBalance
} from '../forge/index'

export default function Withdraw() {
  const location = useLocation()
  const protocol = location.state?.protocol
  const asset = location.state?.asset

  const wsProvider = useContext(WebsocketContext)
  const [tokenBalance, setTokenBalance] = useState(null)
  const [open, setOpen] = useState(false)
  const [txHash, setTxHash] = useState(null)

  let [signedTransactions, setSignedTransactions] = useState([])
  let [amount, setAmount] = useState(0)
  let [fetchTransactions, setFetchTransactions] = useState(false)

  let safeAddress = sessionStorage.getItem('safeAddress')
  let signer = getSigner()

  useEffect(() => {
    async function fetchData(safeAddress) {
      switch (protocol) {
        case 'kashi':
          let kashiBalance = await getKashiBalance(safeAddress, asset)
          setTokenBalance(kashiBalance)
          break
        case 'aave':
          let aaveBalance = await getAaveBalance(safeAddress, asset)
          setTokenBalance(aaveBalance)
          break
        case 'mstable':
          let imUSDBalance = await getimUSDBalance(safeAddress, asset)
          setTokenBalance(imUSDBalance)
          break
        default:
          setTokenBalance(0)
      }
    }

    fetchData(safeAddress)
  }, [safeAddress, asset, protocol])

  useQuery(
    'getWithdrawalTransactions',
    () =>
      request(
        `/api/transactions/new?type=yield_withdrawal&amount=${amount}&asset=${asset}&protocol=${protocol}`,
        {
          method: 'GET'
        }
      ).then(res => res.json()),
    {
      enabled: Boolean(fetchTransactions),
      onSuccess: async result => {
        let tx = await result
        let payload = {
          to: tx.to,
          value: tx.value,
          data: tx.data,
          operation: tx.operation,
          safeTxGas: tx.safeTxGas,
          baseGas: tx.baseGas,
          gasPrice: tx.gasPrice,
          gasToken: tx.gasToken,
          refundReceiver: tx.refundReceiver,
          nonce: tx.nonce
        }

        let { data: signature } = await safeSignTypedData(
          signer,
          { address: safeAddress },
          payload
        )

        setSignedTransactions([{ ...tx, signatures: [signature] }])
      }
    }
  )

  useQuery(
    'createTransactionJobs',
    () =>
      request('/api/transactions', {
        method: 'POST',
        body: JSON.stringify({ transaction: signedTransactions[0] })
      }).then(res => res.json()),
    {
      enabled: Boolean(signedTransactions.length),
      onSuccess: ({ hash }) => {
        setTxHash(hash)
        setOpen(true)
      }
    }
  )

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <PendingTransactionModal
        open={open}
        setOpen={setOpen}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
      />
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-copy-800 sm:truncate">
            Withdraw
          </h1>
        </div>
      </div>
      <form className="space-y-8 divide-y divide-gray-200 ml-8">
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 pt-4 mb-5"
              >
                How much would you like to withdraw ?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex justify-between items-stretch rounded-md shadow-sm bg-gray-50 border border-gray-200 h-full block p-5 max-w-lg mr-8">
                  <button
                    onClick={e => {
                      setAmount(tokenBalance)
                      e.preventDefault()
                    }}
                    className="text-sm text-gray-500 items-baseline text-left"
                  >
                    Balance: {parseFloat(tokenBalance).toFixed(2)} {protocol}/
                    {asset}
                  </button>

                  <input
                    onFocus={() => amount <= 0 && setAmount('')}
                    onBlur={() => amount <= 0 && setAmount(0)}
                    onChange={e => setAmount(e.target.value)}
                    value={amount}
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="text-right text-2xl items-center self-center border-0 rounded-md focus:ring-0 outline-none bg-transparent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-beginning">
            <button
              type="submit"
              onClick={e => {
                setOpen(true)
                setFetchTransactions(true)
                e.preventDefault()
              }}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </main>
  )
}
