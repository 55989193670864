import React, { useState } from 'react'

const EquifaxQuestions = ({
  questions,
  answers,
  handleAnswerChange,
  handleAnswersSubmit
}) => {
  const [submitting, setSubmitting] = useState(false)

  return (
    <div className="pr-12">
      <div className="mb-4">
        <div>
          <h3 className="text-md leading-6 font-medium text-copy-800">
            Credit Report Questions
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Please answer the following questions to help us verify your
            identity
          </p>
        </div>
      </div>
      <div className="space-y-6 sm:space-y-5">
        {questions.map((question, index) => {
          return (
            <div
              key={index}
              className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5"
            >
              <label
                htmlFor="first-name"
                className="block text-sm font-normal text-gray-700 sm:mt-px sm:pt-2 col-span-2"
              >
                {question}
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-1">
                <select
                  id="country"
                  name="country"
                  onChange={e => handleAnswerChange(e, index)}
                  autoComplete="country"
                  className="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option></option>
                  {answers[index].map((answer, index) => {
                    return (
                      <option key={answer} value={index + 1}>
                        {answer}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )
        })}
      </div>

      <div className="pt-20">
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={e => {
              handleAnswersSubmit(e)
              setSubmitting(true)
            }}
            disabled={submitting}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50"
          >
            {submitting ? 'Loading...' : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EquifaxQuestions
