import { Fragment, useState, useContext } from 'react'
import { DownloadIcon } from '@heroicons/react/solid'

import { currencyFormat, request, getSigner } from '../utils'
import { safeSignTypedData } from '../utils/safe_utils'
import WebsocketContext from '../context/WebsocketContext'

import Modal from './Modal'
import NumberBox from './NumberBox'
import PendingTransactionModal from './PendingTransactionModal'
import Spinner from '../components/Spinner'

import useAccountSigner from '../hooks/useAccountSigner'

import {
  createTransactionsJob,
  payloadToSign,
  useTransaction
} from '../utils/transactions'

import TwoFactorModal from './TwoFactorModal'

const getWithdrawalTX = (amount, mta_balance) =>
  request(
    `/api/transactions/new?type=yield_withdrawal&amount=${amount}&asset=imusd&protocol=mstable&mta_balance=${mta_balance}`
  ).then(res => res.json())

const SaveWithdraw = ({
  availableBalance,
  exchangeRate,
  vimUSDBalance,
  mtaBalance
}) => {
  const [open, setOpen] = useState(false)
  const [txModalOpen, setTxModalOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const [txHash, setTxHash] = useState(null)
  const [transaction, setTransaction] = useState()
  const wsProvider = useContext(WebsocketContext)

  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState()
  const [wrong2FACode, setWrong2FACode] = useState()

  const [loading, setLoading, withErrorHandling] = useTransaction()

  let signer = useAccountSigner()
  let safeAddress = sessionStorage.getItem('safeAddress')

  const reSendCode = async () => {
    setWrong2FACode(false)
    send2FACode()
  }

  const send2FACode = async () => {
    setLoading(true)

    request(`/api/users/send_2fa_code`, {
      method: 'POST',
      body: ''
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
      })
  }

  const makeWithdrawal = async () => {
    let withdrawalAmount = Number(amount)

    setLoading(true)

    // the availableBalance is v-imUSD converted to USD (10 v-imUSD ~= 1 USD)
    // when we send this to forge, we need to send v-imUSD balance
    if (withdrawalAmount >= availableBalance) {
      withdrawalAmount = vimUSDBalance
    } else {
      withdrawalAmount = withdrawalAmount / exchangeRate
    }

    let amountInvalid = isNaN(withdrawalAmount) || withdrawalAmount <= 0
    if (amountInvalid) return

    let tx

    if (confirmationCode) {
      tx = transaction
    } else {
      tx = await getWithdrawalTX(withdrawalAmount, mtaBalance)
      setTransaction(tx)
    }

    if (tx.type === 'multi_sig' && !confirmationCode) {
      setOpenTwoFactorModal(true)
    } else {
      let payload = payloadToSign(tx)

      let signature = await safeSignTypedData(
        signer,
        { address: safeAddress },
        payload
      )

      tx = { ...tx, signatures: [signature] }
      let response = await createTransactionsJob({
        tx,
        amount,
        type: 'withdrawal',
        confirmation_code: confirmationCode
      })

      if (response.error) {
        setTxModalOpen(false)
        setOpenTwoFactorModal(true)
        setWrong2FACode(true)
        return
      } else {
        setTxModalOpen(true)
        setTxHash(response)
        return
      }
    }
  }

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="w-full md:w-auto justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-orange-500 bg-orange-100 hover:bg-orange-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:order-1 sm:ml-3"
      >
        Withdraw
        <DownloadIcon className="ml-5 h-4 w-4" aria-hidden="true" />
      </button>

      <TwoFactorModal
        open={openTwoFactorModal}
        setOpen={setOpenTwoFactorModal}
        setConfirmationCode={setConfirmationCode}
        confirmationCode={confirmationCode}
        withErrorHandling={withErrorHandling}
        executeTx={makeWithdrawal}
        wrong2FACode={wrong2FACode}
        reSendCode={reSendCode}
        loading={loading}
      />

      <PendingTransactionModal
        open={txModalOpen}
        setOpen={setTxModalOpen}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
      />

      <Modal isOpen={open} close={() => setOpen(false)} as={Fragment}>
        <div className="bg-white overflow-hidden shadow rounded-lg self-start">
          <div className="px-4 py-5 sm:p-6">
            <NumberBox
              title="Available balance"
              number={currencyFormat(availableBalance, 2)}
              bottomText="USDC"
              smaller
            />

            <div className="mt-5">
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                ENTER WITHDRAWAL AMOUNT
              </label>

              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>

                <input
                  type="number"
                  name="price"
                  id="price"
                  onFocus={() => amount <= 0 && setAmount('')}
                  onBlur={() => amount <= 0 && setAmount(0)}
                  onChange={e => setAmount(e.target.value)}
                  className="focus:ring-orange-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                />

                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    USD
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-5 flex justify-center">
              {!loading ? (
                <button
                  onClick={() => withErrorHandling(makeWithdrawal)}
                  className="justify-center w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:order-1"
                >
                  Withdraw
                </button>
              ) : (
                <Spinner small />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SaveWithdraw
