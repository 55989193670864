import React from 'react'
import SectionHeading from '../components/SectionHeading'
import Transfer from '../components/Transfer'

export default function TransferPage() {
  return (
    <section>
      <SectionHeading heading="Withdraw/Send to another wallet" />
      <Transfer />
    </section>
  )
}
