import { useState, useCallback, useEffect, useRef } from 'react'
import { ethers } from 'ethers'
import WalletConnect from '@walletconnect/client'
import { areStringsEqual } from '../utils'

import { request } from '../utils'
import { safeSignTypedData, signMessage } from '../utils/safe_utils'
import useAccountSigner from '../hooks/useAccountSigner'

import { createTransactionsJob, payloadToSign } from '../utils/transactions'

const rejectWithMessage = (connector, id, message) => {
  connector.rejectRequest({ id, error: { message } })
}

const useWalletConnect = ({ callback, safeAddress }) => {
  const [wcClientData, setWcClientData] = useState(null)
  const [connector, setConnector] = useState()
  const [open, setOpen] = useState(false)
  const [promptAction, setPromptAction] = useState()

  let signer = useAccountSigner()

  const localStorageSessionKey = useRef(`session_${safeAddress}`)

  const wcDisconnect = useCallback(async () => {
    try {
      await connector?.killSession()
      setConnector(undefined)
      setWcClientData(null)
    } catch (error) {
      console.log('Error trying to close WC session: ', error)
    }
  }, [connector])

  const getNonce = address => request(`/api/safe/nonce`).then(res => res.json())

  const wcConnect = useCallback(
    async ({ uri, session }) => {
      const wcConnector = new WalletConnect({
        uri,
        session,
        storageId: localStorageSessionKey.current
      })
      setConnector(wcConnector)
      setWcClientData(wcConnector.peerMeta)

      wcConnector.on('session_request', (error, payload) => {
        if (error) {
          throw error
        }

        wcConnector.approveSession({
          accounts: [safeAddress],
          chainId: 137
        })

        setWcClientData(payload.params[0].peerMeta)
      })

      wcConnector.on('call_request', async (error, payload) => {
        if (error) {
          throw error
        }

        try {
          let result = '0x'

          switch (payload.method) {
            case 'eth_sendTransaction': {
              let txInfo = payload.params[0]
              setOpen(true)

              let { status, confirmation_code } = await callback()

              if (status) {
                let { nonce } = await getNonce(safeAddress)

                txInfo = {
                  ...txInfo,
                  nonce: ethers.BigNumber.from(nonce).toNumber(),
                  gasPrice: 0
                }
                let tx = payloadToSign(txInfo)

                let signature = await safeSignTypedData(
                  signer,
                  { address: safeAddress },
                  tx
                )

                tx = { ...tx, signatures: [signature] }

                let hash = await createTransactionsJob({
                  tx,
                  confirmation_code
                })

                result = hash
              } else {
                rejectWithMessage(
                  wcConnector,
                  payload.id,
                  'User did not accept the transaction'
                )
              }

              break
            }
            case 'personal_sign': {
              setOpen(true)

              let { status } = await callback()

              if (status) {
                const [message, address] = payload.params

                if (!areStringsEqual(address, safeAddress)) {
                  throw new Error('The address or message hash is invalid')
                }

                let { data } = await signMessage(signer, message)

                result = data
              } else {
                rejectWithMessage(
                  wcConnector,
                  payload.id,
                  'User did not accept the transaction'
                )
              }
              break
            }

            default: {
              rejectWithMessage(wcConnector, payload.id, 'METHOD_NOT_SUPPORTED')
              break
            }
          }

          wcConnector.approveRequest({
            id: payload.id,
            result: result
          })
        } catch (err) {
          rejectWithMessage(wcConnector, payload.id, err.message)
          throw err
        }
      })

      wcConnector.on('disconnect', (error, payload) => {
        if (error) {
          throw error
        }
        wcDisconnect()
      })
    },
    [wcDisconnect]
  )

  useEffect(() => {
    if (!connector) {
      const session = localStorage.getItem(localStorageSessionKey.current)
      if (session) {
        wcConnect({ session: JSON.parse(session) })
      }
    }
  }, [connector, wcConnect])

  return {
    wcClientData,
    wcConnect,
    wcDisconnect,
    open,
    setOpen,
    promptAction,
    setPromptAction
  }
}

export default useWalletConnect
