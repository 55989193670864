import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

import { useConnect } from 'wagmi'
import ConnectModal from './ConnectModal'
import ConnectNotification from './ConnectNotification'

export const Connect = ({ callback }) => {
  const [open, setOpen] = useState(false)
  const { connect, connectors, error, isConnecting, pendingConnector } =
    useConnect({
      onConnect(data) {
        callback(data)
      }
    })

  return (
    <div className="flex justify-center items-center">
      <button
        onClick={() => setOpen(true)}
        className="inline-flex items-center px-6 py-2 border border-transparent text-base rounded-md shadow-sm text-orange-500 border shadow shadow-xs border-gray-200 bg-white hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
      >
        Connect Wallet
      </button>

      {error && (
        <ConnectNotification text={error?.message || 'Failed to connect'} />
      )}
      <ConnectModal
        open={open}
        setOpen={setOpen}
        connectors={connectors}
        loading={isConnecting}
        connect={connect}
      />
    </div>
  )
}
