import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { request } from '../utils'
import { CogIcon, RssIcon, KeyIcon } from '@heroicons/react/solid'

import Spinner from '../components/Spinner'
import SectionHeading from '../components/SectionHeading'

function NotificationIcon({ type }) {
  switch (type) {
    case 'safe_creation':
      return (
        <CogIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      )

    case 'signature_required':
      return (
        <KeyIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      )

    case 'transaction':
      return (
        <RssIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      )
    default:
      return ''
  }
}

function Notification({ notification }) {
  return (
    <tr key={notification.id}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 mt-4">
            <NotificationIcon type={notification.type} />
          </div>
          <div className="ml-4">
            <div className="text-sm text-gray-500">{notification.message}</div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default function Notifications() {
  const [notifications, setNotifications] = useState([])

  let { isLoading } = useQuery(
    'getNotifications',
    () =>
      request(`/api/notifications`, {
        method: 'GET'
      }).then(res => res.json()),
    {
      onSuccess: ({ data: notifications }) => {
        setNotifications(notifications)
      },
      retry: 2
    }
  )

  if (isLoading) return <Spinner />

  return (
    <section>
      <SectionHeading heading="Notifications" />
      <table className="min-w-full divide-y divide-gray-200 mt-5">
        <tbody className="bg-white divide-y divide-gray-200">
          {notifications.map(notification => (
            <Notification notification={notification} key={notification.id} />
          ))}
        </tbody>
      </table>
    </section>
  )
}
