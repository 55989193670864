import { Fragment, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/solid'
import {
  request,
  saveToken,
  saveSignerAddress,
  saveAddress,
  walletFromPasswordAndEmail
} from '../utils'

import AuthError from '../components/AuthError'
import Spinner from '../components/Spinner'
import Modal from '../components/Modal'
import metamaskSVG from '../images/metamask.svg'
import { useAccount } from 'wagmi'

import { Connect } from '../components/Connect'
import { Account } from '../components/Account'
import NetworkSwitcher from '../components/NetworkSwitcher'

import { truncateAddress } from '../utils'

const MetaMaskRegistration = ({
  open,
  setOpen,
  termsAccepted,
  setTermsAccepted,
  submit,
  loading,
  error,
  errorText
}) => {
  const { data: accountData } = useAccount({
    fetchEns: true
  })

  return (
    <Modal isOpen={open} close={() => setOpen(false)} as={Fragment}>
      <h2 className="mt-6 text-xl font-bold text-gray-900 text-center sm:text-left">
        Signup with your wallet
      </h2>
      {error && (
        <div className="mt-4">
          <AuthError title="Something went wrong" text={errorText} />
        </div>
      )}
      <p className="mt-4 text-gray-600">
        Please connect your wallet. We will use it to bootstrap your
        multi-signature Zyield account.
      </p>
      <div className="mt-8 text-center">
        {accountData ? (
          <div className="bg-gray-100 py-2 px-4 rounded-md text-gray-800">
            {accountData?.address}
          </div>
        ) : (
          <Connect />
        )}
      </div>

      {accountData && (
        <div className="flex items-center pt-4">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            required
            className="h-4 w-4 text-orange-500 focus:ring-orange-500 rounded"
            onChange={e => setTermsAccepted(e.target.checked)}
          />
          <label
            htmlFor="remember-me"
            className="ml-2 block text-sm text-gray-900"
          >
            I agree to the{' '}
            <a
              className="text-orange-500 underline hover:text-orange-600"
              rel="noreferrer"
              href="/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href="/privacy"
              rel="noreferrer"
              className="text-orange-500 underline hover:text-orange-600"
              target="_blank"
            >
              Privacy policy
            </a>
            .
          </label>
        </div>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="text-center">
          <button
            onClick={() => submit(accountData?.address)}
            disabled={!termsAccepted}
            type="button"
            className="disabled:opacity-50 opacity-100 mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Create my account
          </button>
        </div>
      )}
    </Modal>
  )
}

export default function Registration(props) {
  let history = useHistory()
  let search = props.location.search
  let email_param = new URLSearchParams(search).get('email') || null

  let url = `/api/sign_up`
  let [email, setEmail] = useState(email_param)
  let [termsAccepted, setTermsAccepted] = useState(false)
  let [metamaskTermsAccepted, setMetamaskTermsAccepted] = useState(false)
  let [password, setPassword] = useState('')
  let [passwordConfirmation, setPasswordConfirmation] = useState('')
  let [error, setError] = useState(false)
  let [metamaskError, setMetamaskError] = useState(false)
  let [errorText, setErrorText] = useState('')
  let [metamaskErrorText, setMetamaskErrorText] = useState('')
  let [loading, setLoading] = useState(false)
  let [loadingMetamask, setLoadingMetamask] = useState(false)
  let [open, setOpen] = useState(false)

  let confirmationError =
    'Please make sure password and password confirmation match!'
  let emailTakenError =
    "We already have this email on file. If that's you - try to log in instead"
  let walletAlreadyUsedError =
    "We already have this wallet on file. If that's you - try to log in instead"
  let genericError = 'Please, double check your information and try again'

  const { data } = useAccount()

  const onError = error => {
    setError(true)

    if (error?.email && error.email[0] === 'has already been taken') {
      setErrorText(emailTakenError)
      return
    }

    setErrorText(genericError)
  }

  const onMetamaskError = error => {
    setMetamaskError(true)

    if (
      error?.signer_address &&
      error.signer_address[0] === 'has already been taken'
    ) {
      setMetamaskErrorText(walletAlreadyUsedError)
      return
    }

    setMetamaskErrorText(genericError)
  }

  const submitMetamask = async signer_address => {
    setMetamaskError(false)
    setLoadingMetamask(true)

    let payload = {
      signer_address,
      terms_accepted: metamaskTermsAccepted,
      type: 'user_provided_wallet'
    }

    request(`/api/sign_up`, {
      method: 'post',
      body: JSON.stringify({
        user: payload
      })
    })
      .then(res => res.json())
      .then(async ({ error, errors, jwt, safe_address }) => {
        if (error || errors) {
          throw error || errors
        }

        if (jwt) {
          saveToken(jwt)
          saveAddress(safe_address)
          saveSignerAddress(signer_address)
          history.push('/dashboard')
        } else {
          history.push('/registration_successful')
        }
      })
      .catch(onMetamaskError)
      .finally(() => setLoadingMetamask(false))
  }

  const submit = async e => {
    e.preventDefault()
    setError(false)
    setLoading(true)

    if (password !== passwordConfirmation) {
      setError(true)
      setErrorText(confirmationError)
      setLoading(false)
      return
    }

    request(`/api/users/new?email=${email}`)
      .then(res => res.json())
      .then(async ({ error, errors, user: { salt } }) => {
        if (error || errors) {
          throw error || errors
        }

        let wallet = await walletFromPasswordAndEmail(password, email, salt)
        let payload = {
          signer_address: wallet.address,
          salt: salt,
          terms_accepted: termsAccepted
        }

        localStorage.setItem('email', email)

        return request(url, {
          method: 'post',
          body: JSON.stringify({
            user: {
              email,
              ...payload
            }
          })
        })
      })
      .then(res => res.json())
      .then(async ({ error, errors }) => {
        if (error || errors) {
          throw error || errors
        }

        history.push('/registration_successful')
      })
      .catch(onError)
      .finally(() => setLoading(false))
  }

  return (
    <div className="min-h-screen sm:flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md text-center w-full mr-16 sm:mt-48">
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          Signup with your wallet
        </h2>
        <div>
          <p className="mt-2 text-center text-sm text-gray-600">
            If you sign up with your wallet, you don't need to provide an email
            and password.
          </p>
        </div>

        <div className="text-center">
          <div className="py-8 px-4">
            {data ? (
              <button
                onClick={() => setOpen(true)}
                className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-2"
              >
                Sign Up with Wallet
                <div className="text-yellow-300 ml-3">
                  {truncateAddress(data?.address)}
                </div>
              </button>
            ) : (
              <Connect callback={() => setOpen(true)} />
            )}
          </div>
        </div>
      </div>

      <div className="max-w-md w-full space-y-8 mr-16">
        <div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-center">
            Sign-up with email
          </h2>
          <p className="mt-2 text-sm text-gray-600 text-center">
            Or{' '}
            <Link
              to="/login"
              className="font-medium text-orange-600 hover:text-orange-500"
            >
              Log in if you already have an account
            </Link>
          </p>
        </div>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            onSubmit={submit}
            className="mt-8 space-y-6"
            action={url}
            method="POST"
          >
            <div className="rounded-md -space-y-px">
              <div>
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email || ''}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm mb-3"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password || ''}
                  onChange={({ target }) => setPassword(target.value)}
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm mb-3"
                />
              </div>
              <div>
                <label
                  htmlFor="password-confirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password Confirmation
                </label>
                <input
                  id="password-confirmation"
                  name="password-confirmation"
                  type="password"
                  value={passwordConfirmation || ''}
                  onChange={({ target }) =>
                    setPasswordConfirmation(target.value)
                  }
                  autoComplete="current-password-confirmation"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                />
              </div>

              <div className="flex items-center pt-4">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  required
                  className="h-4 w-4 text-orange-500 focus:ring-orange-500 rounded"
                  onChange={e => setTermsAccepted(e.target.checked)}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  I agree to the{' '}
                  <a
                    className="text-orange-500 underline hover:text-orange-600"
                    rel="noreferrer"
                    href="/terms-of-use"
                    target="_blank"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="/privacy"
                    rel="noreferrer"
                    className="text-orange-500 underline hover:text-orange-600"
                    target="_blank"
                  >
                    Privacy policy
                  </a>
                  .
                </label>
              </div>
            </div>

            {error && (
              <AuthError title="Something went wrong" text={errorText} />
            )}
            <div>
              {loading ? (
                <Spinner />
              ) : (
                <button
                  disabled={!termsAccepted}
                  type="submit"
                  className="disabled:opacity-50 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-white group-hover:text-white"
                      aria-hidden="true"
                    />
                  </span>
                  Sign Up
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <MetaMaskRegistration
        loading={loadingMetamask}
        open={open}
        setOpen={setOpen}
        error={metamaskError}
        errorText={metamaskErrorText}
        termsAccepted={metamaskTermsAccepted}
        setTermsAccepted={setMetamaskTermsAccepted}
        submit={submitMetamask}
      />
      <NetworkSwitcher />
    </div>
  )
}
