export const EIP712_SAFE_TX_TYPE = {
  SafeTx: [
    { type: 'address', name: 'to' },
    { type: 'uint256', name: 'value' },
    { type: 'bytes', name: 'data' },
    { type: 'uint8', name: 'operation' },
    { type: 'uint256', name: 'safeTxGas' },
    { type: 'uint256', name: 'baseGas' },
    { type: 'uint256', name: 'gasPrice' },
    { type: 'address', name: 'gasToken' },
    { type: 'address', name: 'refundReceiver' },
    { type: 'uint256', name: 'nonce' }
  ]
}

export const safeSignTypedData = async (signer, safe, safeTx) => {
  let signerAddress

  try {
    signerAddress = await signer.getAddress()
  } catch (e) {
    signerAddress = signer._address
  }

  return {
    signer: signerAddress,
    data: await signer._signTypedData(
      { verifyingContract: safe.address, chainId: 137 },
      EIP712_SAFE_TX_TYPE,
      safeTx
    )
  }
}

export const signMessage = async (signer, message) => {
  const signerAddress = await signer.getAddress()

  return {
    signer: signerAddress,
    data: await signer.signMessage(message)
  }
}
