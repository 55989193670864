import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BadgeCheckIcon } from '@heroicons/react/solid'
import { request } from '../utils'

import AuthError from '../components/AuthError'
import Spinner from '../components/Spinner'

export default function EmailConfirmation(props) {
  let search = props.location.search
  let token = new URLSearchParams(search).get('token')
  let [error, setError] = useState(false)
  let [success, setSuccess] = useState(false)
  let [errorText, setErrorText] = useState('')
  let [loading, setLoading] = useState(false)
  let [confirmed, setConfirmed] = useState(false)

  let genericError = 'Please, double check your information and try again'

  const onError = error => {
    console.error(error)
    setError(true)
    setErrorText(genericError)
  }

  useEffect(() => {
    async function confirmEmail(token) {
      request('/api/confirm', {
        method: 'POST',
        body: JSON.stringify({ confirmation_token: token })
      })
        .then(res => res.json())
        .then(async ({ error, errors }) => {
          if (error || errors) {
            throw error || errors
          }

          setSuccess(true)
          setConfirmed(true)
          setLoading(false)
        })
        .catch(onError)
        .finally(() => setLoading(false))
    }

    setLoading(true)
    if (!confirmed) {
      confirmEmail(token)
    }
  }, [confirmed, token])

  return (
    <div className="min-h-screen flex mt-10 items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Email confirmation
          </h2>
        </div>

        {error && <AuthError title="Something went wrong" text={errorText} />}
        {success && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BadgeCheckIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Email confirmed
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>
                    Your account is now confirmed. Please{' '}
                    <Link
                      to="/login"
                      className="text-green-500 hover:text-green-600 font-bold"
                    >
                      Sign in
                    </Link>{' '}
                    to continue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? <Spinner /> : ''}
      </div>
    </div>
  )
}
