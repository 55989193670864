import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { Buffer } from 'buffer'
import {
  chain,
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains
} from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

// Pick chains
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer
}

const { chains, provider } = configureChains(
  [chain.polygon],
  [
    alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider()
  ]
)

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Zyield Finance'
      }
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true
      }
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true
      }
    })
  ],
  provider
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  //const { worker } = require('./mocks/browser')
  //worker.start()
} else {
  Sentry.init({
    dsn: 'https://4575ae9dd990472e87d558a1ad659f0d@o1171570.ingest.sentry.io/6266111',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5
  })
}

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
