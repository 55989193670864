import React, { useState } from 'react'
import SectionHeading from '../components/SectionHeading'

import { CashIcon } from '@heroicons/react/solid'

import PaytrieWalkthrough from '../components/PaytrieWalkthrough'
import MoonpayWalkthrough from '../components/MoonpayWalkthrough'
import RampWalkthrough from '../components/RampWalkthrough'
import RampWidget from '../components/RampWidget'
import Transfer from '../components/Transfer'

import moonPayImg from '../images/moonpay.svg'
import paytrieImg from '../images/paytrie.png'
import rampImg from '../images/ramp.svg'

const options = [
  {
    name: 'Paytrie',
    image: paytrieImg,
    country: '🇨🇦',
    countryTooltip: 'Canada',
    method: 'Interac Transfer'
  },
  {
    name: 'Moonpay',
    image: moonPayImg,
    country: '🇺🇸  🇪🇺  🇨🇦*',
    countryTooltip: 'USA, EU, Canada',
    method: 'Credit Card, Debit Card, Bank Transfer',
    asterix: `'buy-only' in Canada`
  },
  {
    name: 'Ramp',
    image: rampImg,
    country: '🇪🇺 🌏',
    countryTooltip: 'EU, Worldwide',
    method: 'Credit Card, Debit Card, Bank Transfer'
  }
]

const WithdrawalSection = ({ setSelected }) => (
  <section className="mt-20">
    <SectionHeading
      heading="Withdrawals"
      subheading="Withdrawing funds from your Zyield account wallet"
    />
    <p className="text-complimantary-800 mt-5 text-md text-gray-500">
      Currently easy withdrawals are only available for our Canadian users using
      the Paytrie service.
      <br />
      We’re working on making more options available in the coming months for
      our users in other countries.
    </p>
    <div className="mt-4 flow-root">
      <div className="box-content py-2 relative">
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-start">
          {[{ name: 'Withdraw to wallet' }].map(category => (
            <button
              type="button"
              onClick={() => setSelected(category.name.toLowerCase())}
              key={category.name}
              className={`relative mr-6 block text-center w-80 h-80 rounded-lg p-6 py-10 flex flex-col items-center justify-center overflow-hidden shadow-lg ${
                category.disabled ? 'grayscale opacity-40' : 'hover:opacity-75'
              }`}
            >
              <CashIcon className="h-20" />
              <span className="block relative mt-7 text-center text-lg font-bold">
                {category.name}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  </section>
)

const renderOptions = ({ setSelected }) => (
  <>
    <div className="mt-4 flow-root">
      <div className="box-content py-2 relative">
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-start">
          {options.map(category => (
            <button
              type="button"
              onClick={() =>
                !category.disabled && setSelected(category.name.toLowerCase())
              }
              disabled={category.disabled}
              key={category.name}
              className={`relative mr-6 block text-center w-80 h-80 rounded-lg p-6 py-10 flex flex-col items-center justify-start overflow-hidden shadow-lg ${
                category.disabled ? 'grayscale opacity-40' : 'hover:opacity-75'
              }`}
            >
              <div className="h-20">
                <img src={category.image} alt="" className="h-20 " />
              </div>
              <span
                title={category.countryTooltip}
                className="block relative mt-7 text-center text-3xl font-bold"
              >
                {category.country}
              </span>
              <span className="block relative mt-7 text-center text-lg font-bold">
                {category.method}
              </span>
              {category.asterix && (
                <small className="mt-10 absolute bottom-5">
                  * {category.asterix}{' '}
                </small>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
    <WithdrawalSection setSelected={setSelected} />
  </>
)

const renderContent = (selected, setSelected) => {
  switch (selected) {
    case 'paytrie':
      return <PaytrieWalkthrough />
    case 'moonpay':
      return <MoonpayWalkthrough />
    case 'ramp':
      return <RampWidget />
    case 'withdraw to wallet':
      return <Transfer />
    default:
      return renderOptions({ setSelected })
  }
}

const ExternalFunding = () => {
  const [selected, setSelected] = useState(null)

  return (
    <>
      <section>
        <SectionHeading
          heading={
            selected !== 'withdraw to wallet'
              ? 'Fund your account'
              : 'Withdraw/Send to another wallet'
          }
          subheading={
            selected !== 'withdraw to wallet'
              ? 'Add funds using one of our partners'
              : ''
          }
        />
        {selected && (
          <button
            className="mt-5 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
            onClick={() => setSelected(null)}
          >
            🔙 Back to options
          </button>
        )}
        {renderContent(selected, setSelected)}
      </section>
    </>
  )
}

export default ExternalFunding
