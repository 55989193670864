import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import SectionHeading from '../components/SectionHeading'
import SwapsWidget from '../components/SwapsWidget'
import LineChart from '../components/LineChart'
import { getTransactions } from '../pages/Activity'

import {
  DEFI_INDEXES,
  currencyFormat,
  getSafeAddress,
  getTokenList,
  request
} from '../utils'
import { getBalance } from '../forge/'

const CoinGeckoKey = process.env.REACT_APP_COINGECKO_KEY

const tokenList = getTokenList()

const normalizeSymbol = symbol =>
  symbol === 'eth2x-fli' ? `${symbol}-p` : symbol

const InvestIndex = () => {
  let safeAddress = getSafeAddress()
  let chainId = parseInt(process.env.REACT_APP_CHAIN_ID)
  let { ticker } = useParams()
  let history = useHistory()

  const [indexData, setIndexData] = useState({})
  const [balance, setBalance] = useState(0)
  const [next, setNext] = useState()
  const [previous, setPrevious] = useState()
  const [chartStats, setChartStats] = useState([])

  let index = DEFI_INDEXES.find(index => index.ticker.toLowerCase() === ticker)

  useEffect(() => {
    if (!index || Object.keys(indexData).length) return

    fetch(`${index.url}?x_cg_pro_api_key=${CoinGeckoKey}`)
      .then(res => res.json())
      .then(setIndexData)

    request(`/api/protocols/${ticker}/stats/30_days`)
      .then(res => res.json())
      .then(({ stats }) => setChartStats(stats || []))
      .catch(console.error)
  }, [index, indexData, ticker])

  const priceUp = indexData.market_data?.price_change_percentage_24h >= 0

  useQuery('getTransactions', () => getTransactions(), {
    onSuccess: ({ data: transactions }) => {
      setNext(next)
      setPrevious(previous)
    },
    retry: 5,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 10000)
  })

  useEffect(() => {
    async function fetchData() {
      let filteredTokens = tokenList.filter(t => t.chainId === chainId)

      let token = filteredTokens.find(
        ({ symbol }) => symbol.toLowerCase() === normalizeSymbol(ticker)
      )

      let balance = await getBalance({
        address: safeAddress,
        token_address: token.address,
        decimals: token.decimals
      })

      setBalance(balance)
    }

    if (index) {
      fetchData()
    }
  }, [index, chainId, safeAddress, ticker])

  const formatSupply = (supply = 0) => {
    let { format } = Intl.NumberFormat()
    return format(parseInt(supply))
  }

  let price = indexData.market_data?.current_price?.usd || 0

  return (
    <section>
      <button
        className="mb-5 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        type="button"
        onClick={() => history.goBack()}
      >
        🔙
      </button>
      <SectionHeading heading={indexData.name || index.name || ''} />
      <p className="text-complimantary-800 mt-3 text-md text-gray-500">
        {indexData.description?.en}
      </p>

      <p className="text-complimantary-800 max-w-md mt-8 border-t-2 pt-10 text-2xl text-gray-500">
        <span>{currencyFormat(price, 2)}</span>
        <span
          className={`ml-5 text-base ${
            priceUp ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {priceUp && '+'}
          {indexData.market_data?.price_change_percentage_24h.toFixed(2)}%
        </span>
        <small className="text-xs"> 24h </small>
      </p>
      <p className="balance-block text-complimantary-800 mt-3 text-2xl text-gray-500">
        <span className="uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          Balance:{' '}
        </span>
        <span className="pr-5 sm:border-r">{`${Number(balance).toFixed(
          4
        )} ${ticker.toUpperCase()} `}</span>
        <span className="sm:ml-5 uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          Value:{' '}
        </span>
        <span>{currencyFormat(balance * price, 2)}</span>
      </p>

      <div className="flex-0 lg:flex lg:flex-row items-start mt-10">
        <SwapsWidget predefined from="USDC" to={normalizeSymbol(ticker)} />
        <div className="hidden sm:flex flex-1">
          <LineChart
            rawDate
            label=""
            dates={chartStats.map(s => new Date(s[0]))}
            values={chartStats.map(s => s[1])}
          />
        </div>
      </div>
      <div className="mt-8 px-3 border-t-2">
        <h2 className="mt-10 text-xl font-medium leading-6 text-copy-800 sm:truncate">
          Stats
        </h2>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 max-w-xl">
          <div>
            <h3 className="text-base font-medium">Market Cap</h3>
            <p className="mt-1">
              {currencyFormat(indexData.market_data?.market_cap?.usd, 2)}
            </p>
          </div>
          <div>
            <h3 className="text-base font-medium">Volume</h3>
            <p className="mt-1">
              {currencyFormat(indexData.market_data?.total_volume?.usd, 2)}
            </p>
          </div>
          <div>
            <h3 className="text-base font-medium">Total Supply</h3>
            <p className="mt-1">
              {formatSupply(indexData.market_data?.total_supply)}
            </p>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 max-w-xl">
          <div>
            <h3 className="text-base font-medium">All Time High</h3>
            <p className="mt-1">
              {currencyFormat(indexData.market_data?.ath?.usd, 2)}
            </p>
          </div>
          <div>
            <h3 className="text-base font-medium">Market Cap Rank</h3>
            <p className="mt-1">{indexData.market_cap_rank || '?'}</p>
          </div>
          <div>
            <h3 className="text-base font-medium">Circulating Supply</h3>
            <p className="mt-1">
              {formatSupply(indexData.market_data?.circulating_supply)}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InvestIndex
