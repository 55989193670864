import { useState, useEffect } from 'react'
import { getSafeAddress, request, currencyFormat } from '../utils'
import { format, subDays } from 'date-fns'
import { Link } from 'react-router-dom'
import NumberBox from '../components/NumberBox'
import Spinner from '../components/Spinner'
import TokensTable from '../components/TokensTable'
import SaveTokensTable from '../components/SaveTokensTable'

import { getUSDCBalance } from '../forge/index'

const weekAgo = () => format(subDays(new Date(), 7), 'yyyy-MM-dd')
const today = () => format(new Date(), 'yyyy-MM-dd')

const getSaveBalance = () =>
  request(`/api/balances/save_balance`).then(res => res.json())

const getWalletBalance = () =>
  request(`/api/balances/wallet_balance`).then(res => res.json())

const getHistoricalBalances = (from = weekAgo(), to = today()) =>
  request(`/api/balances/historical?from=${from}&to=${to}`).then(res =>
    res.json()
  )

const getExchangeRate = async () => {
  let { exchange_rate } = await request(
    `/api/protocols/mstable/exchange_rate`
  ).then(res => res.json())

  return exchange_rate
}

const calculateNetWorth = (...args) =>
  args.reduce((sum, arg) => (sum += Number(arg) || 0), 0)

const getSupplyAPR = async () => {
  let { supply_apr } = await request(
    `/api/protocols/mstable/asset/USDC/supply_apr`
  ).then(res => res.json())

  return supply_apr
}

const getWalletBalances = address =>
  request(`/api/balances/wallet?address=${address}`).then(res => res.json())

const Dashboard = props => {
  const [loading, setLoading] = useState(false)
  const [assetBalance, setAssetBalance] = useState(0)
  const [walletBalances, setWalletBalances] = useState()
  const [saveBalance, setSaveBalance] = useState()
  const [walletBalance, setWalletBalance] = useState()

  const safeAddress = getSafeAddress()
  let netWorth = calculateNetWorth(saveBalance, walletBalance)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getUSDCBalance(safeAddress),
      getExchangeRate(),
      getHistoricalBalances(),
      getWalletBalances(safeAddress),
      getWalletBalance(),
      getSaveBalance()
    ])
      .then(
        ([
          assetBalance,
          exchangeRate,
          { data: balances },
          { data: walletBalances },
          { balance: _walletBalance },
          { balance: _saveBalance }
        ]) => {
          setAssetBalance(assetBalance)
          setWalletBalances(walletBalances)
          setSaveBalance(_saveBalance)
          setWalletBalance(_walletBalance)
        }
      )
      .finally(() => setLoading(false))
  }, [safeAddress])

  if (loading) return <Spinner />

  return (
    <section>
      <div className="w-full flex flex-col md:flex-row justify-between md:items-center border-b border-gray-200 mb-10">
        <div className="flex flex-col md:flex-row">
          <NumberBox
            className="mt-4 md:mt-0 border-b md:border-b-0 md:border-r md:pr-10 pb-5 border-gray-200"
            title="Available balance"
            number={currencyFormat(assetBalance)}
            bottomText="USDC"
          />
          <NumberBox
            className="mt-4 md:mt-0 md:pl-10 pb-5"
            title="Net worth"
            number={currencyFormat(netWorth)}
            bottomText="USDC"
          />
        </div>

        <Link
          to="/fiat_deposit"
          className="justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:order-1 z-0"
        >
          Get started funding
        </Link>
      </div>

      <SaveTokensTable title="Save" tokens={walletBalances} type="save" />

      {walletBalances && (
        <TokensTable
          title="Investments"
          tokens={walletBalances}
          type="investment"
        />
      )}
      {walletBalances && (
        <TokensTable title="Coins" tokens={walletBalances} type="token" />
      )}
    </section>
  )
}

export default Dashboard
