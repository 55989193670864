import {
  LockClosedIcon,
  RefreshIcon,
  ShieldCheckIcon,
  CreditCardIcon,
  CheckIcon
} from '@heroicons/react/outline'

const features = [
  {
    title: 'Easy Funding and Withdrawals',
    icon: CreditCardIcon,
    text: 'Fund your account (wallet) easily from your bank account, debit Visa or credit card.'
  },
  {
    title: 'Non-custodial',
    icon: LockClosedIcon,
    text: 'Zyield is a non-custodial service. We do not hold or have access to your funds. You’re in full control of the funding, deposits and withdrawals from your own smart contract wallet.'
  },
  {
    title: 'Account Recovery',
    icon: RefreshIcon,
    text: 'We can help you recover your account (smart contract wallet) in case you forget or lose your password through a social recovery process.'
  },
  {
    title: 'Deposit Cover',
    icon: ShieldCheckIcon,
    text: 'Save feature deposits are covered by Nexus Mutual, a blockchain cover service protocol, in the case of smart contract bugs, economic attacks, including oracle failures and governance attacks.'
  }
]

export default function Features() {
  return (
    <div className="relative sm:px-16 py-24">
      <div className="bg-stick absolute bg-orange-500 w-2 top-48 left-1/2 h-5/6 -z-20">
        <div className="bg-stick-ball absolute bg-orange-500 w-6 h-6 -top-1 -left-2 rounded-full"></div>
      </div>
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-4xl font-500 font-bold text-copy-800 tracking-tight">
          Why use Zyield?
        </p>
        <div className="mt-12 md:px-16">
          <div className="flex flex-col">
            {features.map((feature, index) => (
              <div
                key={feature.title}
                style={{ width: '55%', minWidth: '280px', maxWidth: '568px' }}
                className={`z-10 w-full md:w-1/2 mt-16 p-8 bg-white shadow-md self-center ${
                  index % 2 === 0 ? 'sm:self-start' : 'sm:self-end'
                }`}
              >
                <div className="flow-root rounded-lg text-left">
                  <div className="flex justify-start items-center">
                    <CheckIcon className="w-7 text-copy-800 opacity-80 mr-3" />
                    <span className="text-copy-800 font-bold text-2xl">
                      {feature.title}
                    </span>
                  </div>
                  <h3 className="mt-3 text-md text-copy-800 opacity-80 tracking-light leading-loose font-light">
                    {feature.text}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
