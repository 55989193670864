import React, { useState, useContext } from 'react'
import { getSigner, request } from '../utils'
import { safeSignTypedData } from '../utils/safe_utils'
import PendingTransactionModal from '../components/PendingTransactionModal'
import TwoFactorModal from './TwoFactorModal'
import WebsocketContext from '../context/WebsocketContext'
import Spinner from '../components/Spinner'
import useAccountSigner from '../hooks/useAccountSigner'

import {
  createTransactionsJob,
  payloadToSign,
  useTransaction
} from '../utils/transactions'

const defaultTokenOut = {
  name: 'USDC',
  address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  avatar: 'https://www.cryptologos.cc/logos/usd-coin-usdc-logo.png?v=013',
  decimals: 6
}

const getTransferTX = (amount, to, tokenAddress) =>
  request(
    `/api/transactions/new?type=transfer&amount=${amount}&to=${to}&token_address=${tokenAddress.address}&decimals=${tokenAddress.decimals}`
  ).then(res => res.json())

export default function Transfer() {
  const wsProvider = useContext(WebsocketContext)
  const [open, setOpen] = useState(false)
  const [txHash, setTxHash] = useState(null)
  const [amount, setAmount] = useState(0)
  const [to, setTo] = useState('0x')

  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState()
  const [wrong2FACode, setWrong2FACode] = useState()
  const [loading, setLoading, withErrorHandling] = useTransaction()
  const [transaction, setTransaction] = useState()

  let tokenAddress = defaultTokenOut

  let safeAddress = sessionStorage.getItem('safeAddress')
  let signer = useAccountSigner()

  const reSendCode = async () => {
    setWrong2FACode(false)
    send2FACode()
  }

  const send2FACode = async () => {
    setLoading(true)

    request(`/api/users/send_2fa_code`, {
      method: 'POST',
      body: ''
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
      })
  }

  const makeTransfer = async () => {
    let tx

    if (confirmationCode) {
      tx = transaction
    } else {
      tx = await getTransferTX(amount, to, tokenAddress)
      setTransaction(tx)
    }

    if (tx.type === 'multi_sig' && !confirmationCode) {
      setOpenTwoFactorModal(true)
    } else {
      let payload = payloadToSign(tx)

      let signature = await safeSignTypedData(
        signer,
        { address: safeAddress },
        payload
      )

      tx = { ...tx, signatures: [signature] }

      let response = await createTransactionsJob({
        tx,
        confirmation_code: confirmationCode
      })

      if (response.error) {
        setConfirmationCode(false)
        setOpen(false)
        setOpenTwoFactorModal(true)
        setWrong2FACode(true)
      }

      setTxHash(response)
      setOpen(true)
    }
  }

  return (
    <div>
      <div className="mt-6 md:w-1/3">
        <h3 className="uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          Amount
        </h3>
        <div className="mt-3 border-b-2 pb-2 flex flex-row relative justify-between flex-1 mr-2">
          <button className="flex flex-row items-center">
            {tokenAddress && tokenAddress.avatar && (
              <img
                src={tokenAddress.avatar}
                className="hidden sm:block h-6 w-6"
                alt="Token"
              />
            )}
            <span className="ml-2 mr-1">{tokenAddress.symbol}</span>
          </button>

          <input
            onChange={e => setAmount(e.target.value)}
            name="amount"
            id="amount"
            type="numeric"
            value={amount}
            placeholder="0.0"
            className="focus:outline-none flex text-right"
          />
        </div>
      </div>

      <div className="mt-6 md:w-1/3">
        <h3 className="uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          To address
        </h3>
        <div className="mt-3 border-b-2 pb-2 flex flex-row relative justify-between flex-1">
          <input
            onChange={e => setTo(e.target.value)}
            name="to"
            id="to"
            className="flex-1 border-transparent focus:border-transparent outline-none focus:outline-none flex text-right"
            placeholder="0x"
          />
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <button
            onClick={() => withErrorHandling(makeTransfer)}
            type="button"
            className="mt-6 inline-flex justify-center items-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Withdraw
          </button>
        )}
      </div>

      <PendingTransactionModal
        open={open}
        setOpen={setOpen}
        txHash={txHash}
        wsProvider={wsProvider}
      />

      <TwoFactorModal
        open={openTwoFactorModal}
        setOpen={setOpenTwoFactorModal}
        setConfirmationCode={setConfirmationCode}
        confirmationCode={confirmationCode}
        withErrorHandling={withErrorHandling}
        wrong2FACode={wrong2FACode}
        reSendCode={reSendCode}
        loading={loading}
        executeTx={makeTransfer}
      />
    </div>
  )
}
