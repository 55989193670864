export default function ConfirmEmailFormSubmitted() {
  return (
    <div className="max-w-md w-full space-y-8">
      <p className="mt-2 text-left text-lg text-gray-600">
        If the email you provided exists in our system, you will receive an
        email with instructions on how to reset your password.
      </p>
      <p className="mt-0 text-left text-sm text-gray-600">
        For security reasons, your smart contract wallet has a 48 hour time lock
        before owners can be swapped. Once you click on the link you receive in
        your email, we will initiate the recovery.
      </p>
    </div>
  )
}
