import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LockClosedIcon } from '@heroicons/react/outline'

import Spinner from '../components/Spinner'
import Wrong2FACode from '../components/Wrong2FACode'

import { request } from '../utils'
import { safeSignTypedData } from '../utils/safe_utils'

import useAccountSigner from '../hooks/useAccountSigner'

import {
  createTransactionsJob,
  payloadToSign,
  useTransaction
} from '../utils/transactions'

const getRemoveOwnerTX = () =>
  request(`/api/transactions/new?type=remove_owner`).then(res => res.json())

export default function Disable2FAModal({
  openTxModal,
  setOpenTxModal,
  txHash,
  setTxHash,
  open,
  setOpen,
  enabled
}) {
  const cancelButtonRef = useRef(null)
  const [confirmationCode, setConfirmationCode] = useState()
  const [transaction, setTransaction] = useState()
  const [isMultiSig, setIsMultiSig] = useState()
  const [wrong2FACode, setWrong2FACode] = useState()
  const [loading, setLoading, withErrorHandling] = useTransaction()

  let signer = useAccountSigner()
  let safeAddress = sessionStorage.getItem('safeAddress')

  const reSendCode = async () => {
    setWrong2FACode(false)
    getTransaction()
  }

  const getTransaction = async () => {
    setLoading(true)
    let transaction = await getRemoveOwnerTX()
    if (transaction.type === 'multi_sig') {
      setIsMultiSig(true)
      setTransaction(transaction)
      setLoading(false)
    }
  }

  const removeOwner = async () => {
    let tx

    if (transaction) {
      tx = transaction
    } else {
      tx = await getRemoveOwnerTX()
      setTransaction(tx)
    }

    if (tx.type === 'multi_sig' && !confirmationCode) {
      setIsMultiSig(true)
    } else {
      let payload = payloadToSign(tx)
      let signature = await safeSignTypedData(
        signer,
        { address: safeAddress },
        payload
      )
      tx = { ...tx, signatures: [signature] }

      let response = await createTransactionsJob({
        tx,
        confirmation_code: confirmationCode
      })

      if (response.error) {
        setWrong2FACode(true)
      } else {
        setTxHash(response)
        setOpenTxModal(true)
      }
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <LockClosedIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  {wrong2FACode ? (
                    <Wrong2FACode
                      title="Disable 2FA"
                      description="The code you entered is invalid"
                      callback={reSendCode}
                    />
                  ) : (
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Disable 2FA
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Disabling the 2FA weakens the security of your wallet.
                          Are you sure ?
                        </p>
                      </div>
                      {isMultiSig && (
                        <div className="mt-4 w-2/3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            We emailed you a confirmation code
                          </label>
                          <div className="mt-1">
                            <input
                              type="number"
                              name="confirmation_code"
                              id="email"
                              onChange={e =>
                                setConfirmationCode(e.target.value)
                              }
                              className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="______"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {loading ? (
                  <Spinner />
                ) : (
                  <div>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    {transaction ? (
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-500 text-base font-medium text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={e => {
                          withErrorHandling(removeOwner)
                          e.preventDefault()
                        }}
                      >
                        Deactivate
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-500 text-base font-medium text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={e => {
                          getTransaction()
                          e.preventDefault()
                        }}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
