import hero_image from '../images/save_screen.jpg'
import { Link } from 'react-router-dom'
import { getEmail } from '../utils'

export default function Hero() {
  let userIsIn = Boolean(getEmail())

  return (
    <div>
      <div className="max-w-7xl mx-auto px-5 lg:px-8 sm:py-24 sm:px-6 lg:px-10 lg:flex lg:justify-between lg:items-center">
        <div className="max-w-lg mr-10">
          <h2 className="text-4xl font-500 text-copy-800 sm:text-5xl sm:tracking-tight lg:text-6xl">
            <span className="block">Your interface</span>
            <span className="block">to DeFi</span>
          </h2>
          <p className="mt-5 opacity-70 leading-9 text-xl font-400 text-copy-400">
            Get better returns on your savings and investments through curated
            decentralized finance opportunities.{' '}
            <b>Grow your money with DeFi.</b>
          </p>
          <div className="mt-12">
            <Link
              to={userIsIn ? '/dashboard' : '/register'}
              className="whitespace-nowrap inline-flex items-center justify-center bg-orange-500 bg-origin-border px-6 py-4 rounded-md border border-transparent shadow-sm font-medium text-white hover:bg-orange-600"
            >
              Get Started
            </Link>
          </div>
        </div>
        {/*<div className="backdrop-ellipsis"></div> */}
        <div className="mt-12 lg:mt-4 w-full">
          <div className="screen-window rounded-2xl p-6 pt-10">
            <div className="absolute top-4 left-5 flex justify-center">
              <div className="bg-white w-3 h-3 mr-2 rounded-full"></div>
              <div className="bg-white w-3 h-3 mr-2 rounded-full opacity-60"></div>
              <div className="bg-white w-3 h-3 rounded-full opacity-30"></div>
            </div>
            <div className="inner-screen bg-white">
              <img className="w-full" src={hero_image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
