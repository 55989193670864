import { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { request, walletFromPasswordAndEmail } from '../../utils'

import Spinner from '../../components/Spinner'

export default function SwapOwner() {
  let history = useHistory()
  let [password, setPassword] = useState('')
  let [passwordConfirmation, setPasswordConfirmation] = useState('')
  let [loading, setLoading] = useState(false)

  let search = useLocation().search
  let token = new URLSearchParams(search).get('token')

  const submit = async () => {
    setLoading(true)

    request(`/api/account_recovery/validate_swap_owner_token?token=${token}`)
      .then(res => res.json())
      .then(async ({ error, errors, email, salt }) => {
        if (error || errors) {
          throw error || errors
        }

        let wallet = await walletFromPasswordAndEmail(password, email, salt)

        return request('/api/account_recovery/swap_owner', {
          method: 'POST',
          body: JSON.stringify({ token: token, new_owner: wallet.address })
        })
      })
      .then(res => res.json())
      .then(({ error, errors, hash }) => {
        if (error || errors) {
          throw error || errors
        }

        history.push('/login')
      })
  }

  return (
    <div className="min-h-screen flex mt-10 items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset your password and recover your wallet
          </h2>
        </div>
        <form className="mt-8 space-y-6" method="POST">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 rounded-t-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
            <div>
              <label htmlFor="password-confirmation" className="sr-only">
                Password Confirmation
              </label>
              <input
                id="password-confirmation"
                name="password-confirmation"
                type="password"
                value={passwordConfirmation}
                onChange={({ target }) => setPasswordConfirmation(target.value)}
                autoComplete="current-password-confirmation"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                placeholder="Password Confirmation"
              />
            </div>
          </div>

          <div>
            {loading ? (
              <Spinner />
            ) : (
              <button
                type="button"
                onClick={submit}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Recover
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
