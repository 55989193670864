import React, { useState, useEffect, useContext, useCallback } from 'react'
import MinimalNotification from '../components/MinimalNotification'
import Enable2FAModal from '../components/Enable2FAModal'
import Disable2FAModal from '../components/Disable2FAModal'
import SectionHeading from '../components/SectionHeading'
import Spinner from '../components/Spinner'
import { request, getSignerAddress, getEmail } from '../utils'

import WebsocketContext from '../context/WebsocketContext'
import PendingTransactionModal from '../components/PendingTransactionModal'

import {
  ExternalLinkIcon,
  ClipboardCopyIcon,
  LibraryIcon
} from '@heroicons/react/solid'

import useWalletConnect from '../hooks/useWalletConnect'
import ConfirmationModal from '../components/ConfirmationModal'

import { useAccount } from 'wagmi'

const wait = (timeout = 1000) =>
  new Promise(resolve => setTimeout(() => resolve(), timeout))

let feedback, status, confirmation_code

const WalletConnectCard = ({ safeAddress, twoFactorEnabled }) => {
  const [isConnecting, setIsConnecting] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const {
    wcClientData,
    wcConnect,
    wcDisconnect,
    open: openConfirmation,
    setOpen: setOpenConfirmation,
    setPromptAction
  } = useWalletConnect({
    safeAddress,
    callback: (function () {
      return async function callback() {
        await wait()

        if (feedback) return Promise.resolve({ status, confirmation_code })
        return await callback()
      }
    })()
  })

  const getConnectedContent = () => (
    <>
      <div className="bg-gray-50 py-5 px-5 flex items-center justify-between mt-4">
        <img
          className="w-10"
          src={wcClientData.icons[0]}
          alt={wcClientData.name}
        />
        {wcClientData.name}
        <button
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="button"
          onClick={() => wcDisconnect()}
        >
          Disconnect
        </button>
      </div>
    </>
  )

  const onPaste = useCallback(
    event => {
      const connectWithUri = data => {
        if (data.startsWith('wc:')) {
          setIsConnecting(true)
          wcConnect({ uri: data })
        }
      }

      setInputValue('')

      if (wcClientData) {
        return
      }

      const items = event.clipboardData.items

      for (const index in items) {
        const item = items[index]

        if (item.kind === 'string' && item.type === 'text/plain') {
          connectWithUri(event.clipboardData.getData('Text'))
        }
      }
    },
    [wcClientData, wcConnect]
  )

  // WalletConnect does not provide a loading/connecting status
  // This effects simulates a connecting status, and prevents
  // the user to initiate two connections in simultaneous.
  useEffect(() => {
    let interval
    if (isConnecting) {
      interval = setTimeout(() => setIsConnecting(false), 5_000)
    }
    return () => clearTimeout(interval)
  }, [isConnecting])

  return (
    <div>
      <h2 className="mt-8 mb-4 text-xl font-bold">Wallet Connect</h2>
      <p>
        Wallet Connect allows you to use your Zyield Wallet with any protocol
        running on the Polygon network.
      </p>
      <p className="mb-4">
        That means that you can connect your Zyield wallet to that website/app
        and make payments/transactions directly from your Zyield wallet.
      </p>
      <p>How to use it:</p>
      <ol className="mt-4">
        <li>
          1. When visiting a DeFi/NFT website/app select the Wallet Connect
          option to connect.
        </li>
        <li>
          2. Click on the Copy To Clipboard link at the bottom of the QR code.
        </li>
        <li>3. Paste the connection URI in the Wallet Connect field below.</li>
      </ol>

      <p className="mt-4">
        That’s it, you’re now connected and can use your Zyield funds with the
        Polygon app/protocol of your choice.
      </p>

      {wcClientData ? (
        getConnectedContent()
      ) : (
        <div className="mt-4">
          <label
            htmlFor="connection-uri"
            className="block text-sm font-medium text-gray-700"
          >
            WalletConnect QR or connection URI
          </label>
          <div className="flex items-center">
            {isConnecting ? (
              <Spinner />
            ) : (
              <input
                id="connection-uri"
                name="connection-uri"
                type="text"
                value={inputValue}
                onPaste={onPaste}
                onChange={({ target }) => setInputValue(target.value)}
                required
                className="appearance-none block w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              />
            )}
          </div>
        </div>
      )}

      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Confirm Transaction"
        body="Incoming transaction from Wallet Connect"
        cancel_text="Cancel"
        confirm_text="Approve"
        twoFactorEnabled={twoFactorEnabled}
        resolveFun={confirmationCode => {
          confirmation_code = confirmationCode
          feedback = true
          status = true
        }}
        rejectFun={() => {
          feedback = true
          status = false
        }}
        setPromptAction={setPromptAction}
      />
    </div>
  )
}

const SecurityCard = ({
  open,
  setOpen,
  owners,
  threshold,
  setTxHash,
  txHash,
  openTxModal,
  setOpenTxModal
}) => {
  const [openEnable2FA, setOpenEnable2FA] = useState(false)
  const [openDisable2FA, setOpenDisable2FA] = useState(false)

  let twoFactorEnabled = owners && owners.length > 1

  const enabled = () => {
    return (
      <div>
        <button
          onClick={() => setOpenDisable2FA(true)}
          type="button"
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Disable 2FA
        </button>
      </div>
    )
  }

  return (
    <div>
      <h2 className="mt-8 text-xl font-bold">Security</h2>
      <div className="mt-5">
        {twoFactorEnabled ? (
          enabled()
        ) : (
          <button
            onClick={() => setOpenEnable2FA(true)}
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Enable 2FA
          </button>
        )}
      </div>
      <Enable2FAModal
        open={openEnable2FA}
        setOpen={setOpenEnable2FA}
        openTxModal={openTxModal}
        setOpenTxModal={setOpenTxModal}
        txHash={txHash}
        setTxHash={setTxHash}
      />

      <Disable2FAModal
        open={openDisable2FA}
        setOpen={setOpenDisable2FA}
        openTxModal={openTxModal}
        setOpenTxModal={setOpenTxModal}
        txHash={txHash}
        setTxHash={setTxHash}
      />
    </div>
  )
}

const SwapGuardianCard = ({ accountData }) => {
  return (
    <div>
      <h2 className="mt-8 text-xl font-bold">Swap Guardian</h2>
      <div className="mt-5">
        <p className="mb-4">
          Replace the default Zyield Guardian with your own Metamask account.
        </p>
        <div className="bg-gray-50 py-5 px-5 sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex items-center">
            Guardian Wallet
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <div
                style={{ wordBreak: 'break-all' }}
                className="inline-flex items-center text-sm text-gray-800 opacity-70"
              >
                {accountData?.address}
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
            <a
              href="https://polygonscan.com/address/${safeAddress}"
              rel="noreferrer"
              target="_blank"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-800 bg-gray-300 hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:order-1 sm:ml-3"
            >
              Swap guardian
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const WalletCard = ({ safeAddress, action }) => (
  <div>
    <h2 className="mt-8 text-xl font-bold">Wallet</h2>
    <div className="mt-5">
      <div className="bg-gray-50 py-5 px-5 sm:flex sm:items-center sm:justify-between">
        <div className="sm:flex items-center">
          <LibraryIcon className="w-7" />
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <div
              style={{ wordBreak: 'break-all' }}
              className="inline-flex items-center text-sm text-gray-800 opacity-70"
            >
              {safeAddress}
              <button className="ml-3" onClick={() => action()}>
                <ClipboardCopyIcon
                  className="h-5 w-5 text-gray-400 hover:text-gray-600"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <a
            href={`https://polygonscan.com/address/${safeAddress}`}
            rel="noreferrer"
            target="_blank"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-800 bg-gray-300 hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:order-1 sm:ml-3"
          >
            <ExternalLinkIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Polygonscan Explorer
          </a>
        </div>
      </div>
    </div>
  </div>
)

const getOwners = () => request(`/api/safe/owners`).then(res => res.json())

const getThreshold = () =>
  request(`/api/safe/threshold`).then(res => res.json())

export default function Settings() {
  let safeAddress = sessionStorage.getItem('safeAddress')
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [show, setShow] = useState(false)
  const [notificationText, setNotificationText] = useState()
  const [threshold, setThreshold] = useState()
  const [owners, setOwners] = useState()
  const [loading, setLoading] = useState(true)
  const [openTxModal, setOpenTxModal] = useState(false)
  const [txHash, setTxHash] = useState()
  const wsProvider = useContext(WebsocketContext)

  let email = getEmail()
  let signerAddress = getSignerAddress()
  let twoFactorEnabled = owners && owners.length > 1

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(safeAddress)
    setNotificationText('Copied to clipboard')
    setShow(true)
    setTimeout(() => setShow(false), 3000)
  }

  useEffect(() => {
    Promise.all([getOwners(), getThreshold()])
      .then(([{ owners }, { threshold }]) => {
        setOwners(owners)
        setThreshold(threshold)
      })
      .then(() => setLoading(false))
  }, [])

  const { data: accountData } = useAccount({
    fetchEns: true
  })

  if (loading) return <Spinner />

  return (
    <section>
      <MinimalNotification
        text={notificationText}
        show={show}
        setShow={setShow}
      />

      <SectionHeading heading="Settings" />

      <WalletCard action={handleCopyToClipboard} safeAddress={safeAddress} />

      {email && owners && owners.length > 0 && (
        <SecurityCard
          owners={owners}
          threshold={threshold}
          open={openTwoFactorModal}
          setOpen={setOpenTwoFactorModal}
          openTxModal={openTxModal}
          setOpenTxModal={setOpenTxModal}
          txHash={txHash}
          setTxHash={setTxHash}
        />
      )}

      {owners && owners.length > 0 && (
        <WalletConnectCard
          safeAddress={safeAddress}
          twoFactorEnabled={twoFactorEnabled}
        />
      )}

      <PendingTransactionModal
        open={openTxModal}
        setOpen={setOpenTxModal}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
      />
    </section>
  )
}
