import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { request, getTokenList, rounder, MATIC_ADDRESS } from '../utils'
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  XCircleIcon,
  CogIcon,
  ArrowDownIcon,
  TrendingUpIcon,
  ArrowUpIcon,
  BriefcaseIcon,
  ChevronRightIcon,
  SwitchHorizontalIcon
} from '@heroicons/react/solid'
import Spinner from '../components/Spinner'
import SectionHeading from '../components/SectionHeading'

export function Transaction({ transaction, tokens }) {
  return (
    <TransactionRow
      transaction={transaction}
      key={transaction.hash}
      tokens={tokens}
    />
  )
}

function TransactionIcon({ type, direction }) {
  switch (type) {
    case 'Receive':
      return <ArrowDownIcon className="h-5 w-5 text-green-500" />
    case 'Send':
      return <ArrowUpIcon className="h-5 w-5 text-red-500" />
    case 'Investment buy':
      return <TrendingUpIcon className="h-5 w-5 text-gray-500" />
    case 'Investment sell':
      return <TrendingUpIcon className="h-5 w-5 text-gray-500" />
    case 'Save deposit':
      return <BriefcaseIcon className="h-5 w-5 text-gray-500" />
    case 'Save withdrawal':
      return <BriefcaseIcon className="h-5 w-5 text-gray-500" />
    case 'Exchange':
      return <SwitchHorizontalIcon className="h-5 w-5 text-gray-500" />
    default:
      return <CogIcon className="h-5 w-5 text-gray-500" />
  }
}

function TransactionDetail({ name, incoming, outgoing, tokens }) {
  let incomingToken = tokens[incoming?.address?.toLowerCase()]
  let outgoingToken = tokens[outgoing?.address?.toLowerCase()]

  if (outgoing && outgoing?.symbol === 'MATIC') {
    outgoingToken = tokens[MATIC_ADDRESS.toLowerCase()]
  }

  if (incoming && incoming?.symbol === 'MATIC') {
    incomingToken = tokens[MATIC_ADDRESS.toLowerCase()]
  }

  switch (name) {
    case 'Receive':
      return (
        <div>
          {rounder(incoming?.amount, 6, 0)}
          <span className="ml-1 font-bold">{incoming?.symbol}</span>
        </div>
      )
    case 'Send':
      return (
        <div>
          {rounder(outgoing?.amount, 6, 0)}
          <span className="ml-1 font-bold">{outgoing?.symbol}</span>
        </div>
      )
    case 'Investment buy':
      return (
        <div>
          {rounder(incoming?.amount, 6, 0)}
          <span className="ml-1 font-bold">{incoming?.symbol}</span>
        </div>
      )
    case 'Investment sell':
      return (
        <div>
          {rounder(outgoing?.amount, 6, 0)}
          <span className="ml-1 font-bold">{outgoing?.symbol}</span>
        </div>
      )
    case 'Save deposit':
      return (
        <div>
          {rounder(outgoing?.amount, 6, 0)}
          <span className="ml-1 font-bold">{outgoing?.symbol}</span>
        </div>
      )
    case 'Save withdrawal':
      return (
        <div>
          {rounder(incoming?.amount, 6, 0)}
          <span className="ml-1 font-bold">{incoming?.symbol}</span>
        </div>
      )
    case 'Exchange':
      return (
        <span className="flex items-center">
          <img
            className="w-6"
            src={outgoingToken?.logo}
            alt={outgoingToken?.symbol}
          />{' '}
          <ChevronRightIcon className="w-6" />{' '}
          <img
            className="w-6"
            src={incomingToken?.logo}
            alt={incomingToken?.symbol}
          />
        </span>
      )
    default:
      return <span></span>
  }
}

const SuccessBadge = () => (
  <div className="flex justify-end">
    <CheckCircleIcon className="w-7 text-green-400" aria-hidden="true" />
  </div>
)

const FailBadge = () => (
  <div className="flex justify-end">
    <XCircleIcon className="h-7 w-7 text-red-400" aria-hidden="true" />
  </div>
)

function TransactionRow({ transaction, tokens }) {
  let {
    txSuccessful,
    name,
    incoming,
    outgoing,
    direction,
    hash,
    subTransactions
  } = transaction

  return (
    <tr key={hash}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10 mt-4">
            <TransactionIcon type={name} direction={direction} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-500">
              <TransactionDetail
                incoming={incoming}
                outgoing={outgoing}
                subTransactions={subTransactions}
                name={name}
                tokens={tokens}
              />
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className="flex justify-end items-center">
          {txSuccessful ? <SuccessBadge /> : <FailBadge />}

          <a
            target="_blank"
            rel="noreferrer"
            href={`https://polygonscan.com/tx/${hash}`}
            className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded-md text-gray-700 hover:text-gray-100 bg-gray-200 hover:bg-gray-500 ml-3"
          >
            <ExternalLinkIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Details
          </a>
        </div>
      </td>
    </tr>
  )
}

export const getTransactions = (_limit, _offset) => {
  return request(`/api/transactions`, {
    method: 'GET'
  }).then(res => res.json())
}

const tokenList = getTokenList()

export default function Activity() {
  const [transactions, setTransactions] = useState([])
  const [tokens, setTokens] = useState()

  let { isLoading: isLoadingTransactions } = useQuery(
    'getTransactions',
    () => getTransactions(),
    {
      onSuccess: ({ data: transactions }) => {
        setTransactions(transactions)
      },
      retry: 2
    }
  )

  useEffect(() => {
    let reverseLookup = {}

    tokenList.forEach(token => {
      reverseLookup[token?.address?.toLowerCase()] = {
        name: token.name,
        symbol: token.symbol,
        logo: token.imageURL
      }
    })

    setTokens(reverseLookup)
  }, [])

  if (isLoadingTransactions) return <Spinner />

  return (
    <section>
      <SectionHeading
        heading="Activity"
        subheading="If your latest transaction does not appear in the list below, please refresh the page, as the transactional data is delayed by a few minutes."
      />
      <table className="min-w-full divide-y divide-gray-200 mt-5">
        <tbody className="bg-white divide-y divide-gray-200">
          {transactions &&
            transactions.map((transaction, index) => (
              <Transaction
                transaction={transaction}
                key={index}
                tokens={tokens}
              />
            ))}
        </tbody>
      </table>
    </section>
  )
}
