import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getEmail, request, chunk } from '../utils/'
import OnboardingSteps from '../components/OnboardingSteps'
import Spinner from '../components/Spinner'
import { useInterval } from '../utils/useInterval'

import DocumentUploads from '../partials/paytrie_kyc/DocumentUploads'
import EquifaxQuestions from '../partials/paytrie_kyc/EquifaxQuestions'
import PersonalInformation from '../partials/paytrie_kyc/PersonalInformation'
import EmailVerification from '../partials/paytrie_kyc/EmailVerification'
import PhoneNumberVerification from '../partials/paytrie_kyc/PhoneNumberVerification'

const initialSteps = [
  {
    name: 'Verify Email',
    description: 'We will send a verification code to your email.',
    status: 'current',
    index: 0
  },
  {
    name: 'Verify Phone',
    description: 'You will receive an SMS code you have to confirm.',
    status: 'upcoming',
    index: 1
  },
  {
    name: 'KYC',
    description: 'Provide your personal information.',
    status: 'upcoming',
    index: 2
  },
  {
    name: 'Identity Verification',
    description: 'Prove your identity',
    status: 'upcoming',
    index: 3
  }
]

const markStepAsComplete = (step, steps, updateSteps) => {
  let currentSteps = steps
  currentSteps[step].status = 'complete'
  updateSteps(currentSteps)
}

const PaytrieKYC = props => {
  const [documentNumber, setDocumentNumber] = useState()
  const [documentType, setDocumentType] = useState()
  const [IDNumber, setIDNumber] = useState()
  const [IDType, setIDType] = useState()
  const [emailCode, setEmailCode] = useState('')
  const [email, setEmail] = useState(getEmail() || '')
  const [passportImage, setPassportImage] = useState()
  const [selfieImage, setSelfieImage] = useState()
  const [utilityImage, setUtilityImage] = useState()
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [dob, setDob] = useState('')
  const [occupation, setOccupation] = useState('')
  const [address1, setAddress1] = useState('')
  const [city, setCity] = useState('')
  const [province, setState] = useState('')
  const [postal, setZip] = useState('')
  const [pep, setPep] = useState(false)
  const [tpd, setTpd] = useState(false)
  const [emailVerificationSent, setEmailVerificationSent] = useState(false)
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false)
  const [kycSent, setKycSent] = useState(false)
  const [step, setStep] = useState(0)
  const [steps, updateSteps] = useState(initialSteps)
  const [phone, setPhone] = useState('')
  const [phoneCode, setPhoneCode] = useState('')
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(5))
  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState([])
  const [txKey, setTxKey] = useState('')
  const [gotKycQuestions, setGotKycQuestions] = useState(false)
  const [kycPending, setKycPending] = useState()
  const [pendingDocuments, setPendingDocuments] = useState()
  const [kycComplete, setKycComplete] = useState()
  const [kycStep, setKycStep] = useState()
  const [kycState, setKycState] = useState()

  let history = useHistory()
  let currentStep = steps[step]

  useQuery(
    'getKycQuestions',
    () =>
      request(`/api/fiat_gateway/generate_questions`, {
        method: 'POST',
        body: JSON.stringify({ email })
      }).then(res => res.json()),
    {
      enabled: Boolean(kycSent),
      onSuccess: ({ answer, questions, txKey }) => {
        setAnswers(chunk(answer, 5))
        setSelectedAnswers(new Array(5))
        setQuestions(questions)
        setTxKey(txKey)
        setGotKycQuestions(true)
      },
      onError: () => {
        setGotKycQuestions(false)
      }
    }
  )

  let { isLoading } = useQuery(
    'getUserInfo',
    () =>
      request(`/api/me`, {
        method: 'GET'
      }).then(res => res.json()),
    {
      onSuccess: result => {
        setKycComplete(result.kyc_completed)
        setKycState(result.kyc_state)
        setKycStep(result.kyc_step)
        updateKycState(result)
      },
      retry: 2
    }
  )

  useInterval(() => {
    request(`/api/me`, {
      method: 'GET'
    })
      .then(res => res.json())
      .then(result => {
        if (result.kyc_complete) {
          history.push('/fiat_deposit')
          //setKycComplete(true)
        }
      })
  }, 1000 * 3)

  const updateKycState = ({ kyc_state, kyc_step, kyc_completed }) => {
    // data
    // { email, kyc_state, kyc_step
    switch (kyc_step) {
      case 'email_validation':
        if (kyc_state === 'successful') {
          markStepAsComplete(0, steps, updateSteps)
          setStep(1)
        } else if (kyc_state === 'submitted') {
          setEmailVerificationSent(true)
          setStep(0)
        } else {
          setEmailVerificationSent(false)
          setStep(0)
        }
        break
      case 'phone_validation':
        if (kyc_state === 'successful') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          setStep(2)
        } else if (kyc_state === 'submitted') {
          markStepAsComplete(0, steps, updateSteps)
          setPhoneVerificationSent(true)
          setStep(1)
        } else {
          setPhoneVerificationSent(false)
          setStep(1)
        }
        break
      case 'pi_equifax':
        if (kyc_state === 'successful') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(2)
        } else {
          setKycSent(false)
          setStep(2)
        }
        break
      case 'pi_equifax_questions':
        if (kyc_state === 'successful') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setKycSent(true)
          setStep(3)
        } else {
          setKycSent(true)
          setStep(2)
        }
        break
      case 'pi_equifax_answers':
        if (kyc_state === 'submitted') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(3)
          setKycPending(true)
        } else if (kyc_state === 'failed') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(3)
        } else if (kyc_state === 'successful') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(3)
        }
        break
      case 'pi_documents':
        if (kyc_state === 'new') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(3)
        } else if (kyc_state === 'submitted') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setPendingDocuments(true)
          setStep(3)
        } else if (kyc_state === 'failed') {
          markStepAsComplete(0, steps, updateSteps)
          markStepAsComplete(1, steps, updateSteps)
          markStepAsComplete(2, steps, updateSteps)
          setStep(3)
        }
        break
      default:
    }
  }

  if (isLoading) return <Spinner />

  const kycFormValid = () => {
    return (
      first_name === '' ||
      last_name === '' ||
      dob === '' ||
      occupation === '' ||
      address1 === '' ||
      city === '' ||
      province === '' ||
      postal === '' ||
      pep === false ||
      tpd === false
    )
  }

  const handleAnswerChange = (e, index) => {
    let answers = selectedAnswers
    answers[index] = parseInt(e.target.value)
    setSelectedAnswers(answers)
  }

  const handleDocumentUpload = e => {
    e.preventDefault()

    request('/api/fiat_gateway/upload_documents', {
      method: 'POST',
      body: JSON.stringify({
        email,
        base64image: [passportImage, selfieImage, utilityImage],
        idType: IDType,
        idNumber: IDNumber,
        accountType: documentType,
        accountNumber: documentNumber
      })
    })
      .then(res => res.json())
      .then(({ status }) => {
        setKycPending(true)
      })
      .catch(() => {
        console.log('document upload failed')
      })
  }

  const handleKycSubmit = e => {
    e.preventDefault()

    request('/api/fiat_gateway/send_kyc', {
      method: 'POST',
      body: JSON.stringify({
        email,
        phone,
        first_name,
        last_name,
        dob,
        address1,
        city,
        province,
        postal,
        occupation,
        pep,
        tpd
      })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'success') {
          setKycSent(true)
          markStepAsComplete(step, steps, updateSteps)
          setStep(3)
        }
      })
      .catch(() => {
        console.log('failed to send personal information')
      })
  }

  const handleAnswersSubmit = e => {
    e.preventDefault()

    request('/api/fiat_gateway/submit_answers', {
      method: 'POST',
      body: JSON.stringify({
        email,
        tx_key: txKey,
        answers: selectedAnswers
      })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'pending') {
          setKycPending(true)
        }
      })
      .catch(() => {
        console.log('answers not correct')
      })
  }

  const handlePhoneSubmit = e => {
    e.preventDefault()

    request('/api/fiat_gateway/send_sms', {
      method: 'POST',
      body: JSON.stringify({ email: email, phone: phone })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'SMS sent') {
          setPhoneVerificationSent(true)
        }
      })
      .catch(() => {
        console.log('failed to send sms')
      })
  }

  const handlePhoneCode = e => {
    e.preventDefault()

    request('/api/fiat_gateway/verify_sms', {
      method: 'POST',
      body: JSON.stringify({ email: email, phone_code: phoneCode })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'verified') {
          markStepAsComplete(step, steps, updateSteps)
          setStep(2)
        }
      })
      .catch(() => {
        console.log('failed to verify sms')
      })
  }

  const handleEmailSubmit = e => {
    e.preventDefault()

    request('/api/fiat_gateway/check_email', {
      method: 'POST',
      body: JSON.stringify({ email: email })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'success') {
          setEmailVerificationSent(true)
        }
      })
      .catch(() => {
        console.log('email not there')
      })
  }

  const handleEmailCode = e => {
    e.preventDefault()

    request('/api/fiat_gateway/verify_email', {
      method: 'POST',
      body: JSON.stringify({ email: email, email_code: emailCode })
    })
      .then(res => res.json())
      .then(({ status }) => {
        if (status === 'verified') {
          markStepAsComplete(step, steps, updateSteps)
          setStep(1)
        }
      })
      .catch(() => {
        console.log('failed to verify email')
      })
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <EmailVerification
            email={email}
            setEmail={setEmail}
            emailCode={emailCode}
            setEmailCode={setEmailCode}
            handleEmailCode={handleEmailCode}
            handleEmailSubmit={handleEmailSubmit}
            emailVerificationSent={emailVerificationSent}
          />
        )

      case 1:
        return (
          <PhoneNumberVerification
            phoneVerificationSent={phoneVerificationSent}
            setPhoneCode={setPhoneCode}
            phoneCode={phoneCode}
            setPhone={setPhone}
            phone={phone}
            handlePhoneCode={handlePhoneCode}
            handlePhoneSubmit={handlePhoneSubmit}
          />
        )

      case 2:
        return (
          <PersonalInformation
            phone={phone}
            setPhone={setPhone}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setDob={setDob}
            setOccupation={setOccupation}
            setAddress1={setAddress1}
            setCity={setCity}
            setState={setState}
            setZip={setZip}
            setPep={setPep}
            setTpd={setTpd}
            handleKycSubmit={handleKycSubmit}
            kycFormValid={kycFormValid}
          />
        )

      case 3:
        if (kycComplete) {
          return (
            <div className="pr-12">
              <div className="mb-4">
                <div>
                  <h3 className="text-md leading-6 font-medium text-copy-800">
                    Congratulations
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Your verification is complete, head over to the{' '}
                    <a
                      href="/fiat_deposit"
                      className="text-orange-500 font-bold hover:text-orange-600"
                    >
                      Fund
                    </a>
                    page to fund your account.
                  </p>
                </div>
              </div>
            </div>
          )
        } else if (kycPending || pendingDocuments) {
          return (
            <div className="pr-12">
              <div className="mb-4">
                {' '}
                <div>
                  <h3 className="text-md leading-6 font-medium text-copy-800">
                    Thanks for submitting your information.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please allow a few minutes for Paytrie to clear your KYC
                    verification. You will receive an email when this is
                    complete.
                  </p>
                </div>
              </div>
            </div>
          )
        } else if (gotKycQuestions) {
          return (
            <EquifaxQuestions
              questions={questions}
              handleAnswerChange={handleAnswerChange}
              answers={answers}
              handleAnswersSubmit={handleAnswersSubmit}
            />
          )
        } else if (
          (kycStep === 'pi_documents' && kycState === 'new') ||
          (kycStep === 'pi_equifax_answers' && kycState === 'failed')
        ) {
          return (
            <DocumentUploads
              setPassportImage={setPassportImage}
              setSelfieImage={setSelfieImage}
              setUtilityImage={setUtilityImage}
              setDocumentNumber={setDocumentNumber}
              setDocumentType={setDocumentType}
              setIDNumber={setIDNumber}
              setIDType={setIDType}
              handleDocumentUpload={handleDocumentUpload}
            />
          )
        } else if (kycStep === 'pi_documents' && kycState === 'failed') {
          return (
            <div className="pr-12">
              <div className="mb-4">
                {' '}
                <div>
                  <h3 className="text-md leading-6 font-medium text-copy-800">
                    The documents you uploaded failed to validate your identity.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please reach out to our support at{' '}
                    <span className="text-orange-500">support@zyield.fi</span>{' '}
                    for help.
                  </p>
                </div>
              </div>
            </div>
          )
        }
        break

      default:
      // do nothing
    }
  }

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-copy-800 sm:truncate">
            Paytrie Onboarding
          </h1>
        </div>
      </div>
      <div className="ml-8 mt-8 mr-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
        <div className="mb-10">{renderStep()}</div>
        {kycComplete ? (
          ''
        ) : (
          <OnboardingSteps steps={steps} currentStep={currentStep} />
        )}
      </div>
    </main>
  )
}

export default PaytrieKYC
