import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from '../images/logo.svg'
import { isLoggedIn } from '../utils'

const navigation = []

export default function Header({ location: { pathname } }) {
  let userIsIn = isLoggedIn()

  const renderLink = () => {
    if (!userIsIn) {
      return (
        <Link
          to="/login"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-orange-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:bg-orange-600"
        >
          Sign In
        </Link>
      )
    }

    return (
      <Link
        to="/dashboard"
        className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-origin-border px-4 py-2 rounded-md text-base font-medium text-orange-500 hover:text-white hover:bg-orange-600"
      >
        View Dashboard
      </Link>
    )
  }

  return (
    <header>
      <Popover className="relative">
        {({ open }) => (
          <>
            <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">ZYield</span>
                  <img className="h-14" src={logo} alt="ZYield" />
                </Link>
              </div>
              <div className="mr-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-3 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                {pathname === '/' && (
                  <>
                    <a
                      href="#why-zyield"
                      className="text-base font-medium text-gray-500 hover:text-gray-900"
                    >
                      Why Zyield?
                    </a>
                    <a
                      href="#faq"
                      className="text-base font-medium text-gray-500 hover:text-gray-900"
                    >
                      FAQ
                    </a>
                  </>
                )}
              </Popover.Group>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                {pathname === '/register' || pathname === '/login'
                  ? null
                  : renderLink()}
              </div>
            </div>

            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={logo} alt="Workflow" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-6">
                    <Popover.Button
                      as={Link}
                      to="/register"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-500 hover:bg-orange-600"
                    >
                      Sign up
                    </Popover.Button>
                    <p className="mt-6 text-center text-base text-gray-500">
                      Existing customer?
                      <Popover.Button
                        as={Link}
                        to="/login"
                        className="text-gray-900 p-2 ml-3"
                      >
                        Sign in
                      </Popover.Button>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </header>
  )
}
