import React, { useState, useEffect, useContext } from 'react'
import { getEmail } from '../utils'
import SectionHeading from '../components/SectionHeading'

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'

export default function RampWidget() {
  const [assetBalance, setAssetBalance] = useState(null)
  const [apr, setAPR] = useState(null)
  const [open, setOpen] = useState(false)
  const [txHash, setTxHash] = useState(null)
  const [sdk, setSDK] = useState()

  let defaultStablecoin = process.env.REACT_APP_DEFAULT_STABLECOIN
  let safeAddress = sessionStorage.getItem('safeAddress')

  const handleEvent = event => {
    switch (event.type) {
      case 'WIDGET_CLOSE_REQUEST':
        return 'xx'

      default:
        return ''
    }
  }

  const createWidget = () => {
    let key = process.env.REACT_APP_RAMP_KEY
    let options = {
      hostAppName: 'Zyield',
      hostApiKey: key,
      hostLogoUrl: 'https://zyield.fi/static/media/logo.aa9b9348.svg',
      swapAsset: 'MATIC_USDC',
      variant: 'auto',
      userAddress: safeAddress,
      userEmailAddress: getEmail()
    }

    let rampSDK = new RampInstantSDK(options)

    rampSDK.on('*', event => handleEvent(event))
    rampSDK.show()
    setSDK(rampSDK)
  }

  useEffect(() => createWidget(), [safeAddress])

  return (
    <section>
      <button
        type="button"
        onClick={createWidget}
        className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
      >
        Show Ramp widget
      </button>
    </section>
  )
}
