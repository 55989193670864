import { useLocation, Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DesktopSidebar({ navigation }) {
  const location = useLocation()

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col px-7 border-r border-gray-200 pt-5 pb-4">
        <div className="h-0 flex-1 flex flex-col items-center overflow-y-auto">
          <nav>
            <div className="space-y-1">
              {navigation.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? 'bg-orange-100 text-orange-600'
                      : 'text-gray-700 hover:text-orange-900 hover:bg-orange-50',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      location.pathname === item.href
                        ? 'text-orange-500'
                        : 'text-gray-400 group-hover:text-orange-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
