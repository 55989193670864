import { Link } from 'react-router-dom'
import SectionHeading from '../components/SectionHeading'
import { DEFI_INDEXES } from '../utils'

const Indexes = () => {
  return (
    <section>
      <SectionHeading heading="Invest" />
      <h2 className="mt-10 text-xl font-medium leading-6 text-copy-800 sm:truncate">
        DeFi Indexes
      </h2>
      <p className="text-complimantary-800 mt-3 text-md text-gray-500">
        Tokenized baskets of high quality DeFi projects
      </p>
      <div className="mt-5 flex flex-col flex-wrap justify-center items-center md:flex-row md:justify-start">
        {DEFI_INDEXES.map(index => (
          <Link
            key={index.name}
            to={`/invest/${index.ticker.toLowerCase()}`}
            className="w-60 h-40 relative mr-6 block text-center rounded-lg py-8 px-4 flex flex-col items-center justify-start overflow-hidden shadow-lg"
          >
            <img src={index.image} alt="" className="w-10 mb-5" />
            {index.name}
          </Link>
        ))}
      </div>
    </section>
  )
}

export default Indexes
