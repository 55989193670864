import { useEffect, useState } from 'react'
import { getvimUSDBalance } from '../forge/index'
import { currencyFormat, getSafeAddress } from '../utils'

import Spinner from './Spinner'
import SectionHeading from './SectionHeading'

import {
  getSaveReturns,
  getSaveBalance,
  getSupplyAPR,
  getExchangeRate
} from '../utils/save'

export default function SaveTokensTable({ tokens, title, type }) {
  const [tokenBalance, setTokenBalance] = useState()
  const [loading, setLoading] = useState(true)
  const [returns, setReturns] = useState(0)
  const [apr, setAPR] = useState(0)

  let safeAddress = getSafeAddress()

  useEffect(() => {
    Promise.all([
      getvimUSDBalance(safeAddress),
      getExchangeRate(),
      getSupplyAPR(),
      getSaveBalance()
    ])
      .then(([vimUSDBalance, exchangeRate, supplyAPR, currentBalance]) => {
        let depositValue = parseFloat(vimUSDBalance) * parseFloat(exchangeRate)

        setTokenBalance(depositValue)
        setAPR(parseFloat(supplyAPR).toFixed(2))
        return { currentBalance: currentBalance.balance }
      })
      .then(({ currentBalance }) => getSaveReturns(currentBalance))
      .then(({ return: saveReturn }) => setReturns(saveReturn))
      .then(() => setLoading(false))
  }, [])

  if (loading) return <Spinner />

  let filteredTokens =
    tokens &&
    tokens.filter(
      token => token.type === type && token.contract_ticker_symbol === 'v-imUSD'
    )

  if (!filteredTokens || filteredTokens.length <= 0) return null

  return (
    <section className="mb-10">
      <SectionHeading heading={title} />
      <table className="min-w-full divide-y divide-gray-200 mt-8">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="pl-2 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Asset
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Current APR
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Earnings
            </th>
            <th
              scope="col"
              className="py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTokens &&
            filteredTokens.map((token, tokenIdx) => (
              <tr
                key={tokenIdx}
                className={tokenIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
              >
                <td className="pl-2 py-4 whitespace-nowrap text-md font-medium text-gray-900">
                  <div className="flex items-center">
                    <img
                      className="h-7 w-7"
                      alt={
                        token.contract_ticker_symbol === 'v-imUSD'
                          ? 'Save USD'
                          : token.contract_ticker_symbol
                      }
                      src={token.logo_url}
                    />
                    <div className="mt-2 ml-4 pb-2">
                      {token.contract_ticker_symbol === 'v-imUSD'
                        ? 'Save USD'
                        : token.contract_ticker_symbol}
                    </div>
                  </div>
                </td>
                <td className="hidden sm:table-cell py-4 whitespace-nowrap text-md text-gray-500">
                  {apr}%
                </td>
                <td className="hidden sm:table-cell py-4 whitespace-nowrap text-md text-gray-500">
                  {currencyFormat(returns)}
                </td>
                <td className="py-4 whitespace-nowrap text-md text-gray-500">
                  {currencyFormat(tokenBalance)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  )
}
