import React from 'react'
import InlineWalletAddress from '../components/InlineWalletAddress'

import paytrieImg0 from '../images/paytrie_walkthrough_0'
import paytrieImg1 from '../images/paytrie_walkthrough_1'
import paytrieImg2 from '../images/paytrie_walkthrough_2'
import paytrieImg3 from '../images/paytrie_walkthrough_3'
import paytrieImg4 from '../images/paytrie_walkthrough_4'

const PaytrieWalkthrough = () => (
  <>
    <h3 className="mt-10 leading-6 text-3xl">
      How to fund your Zyield account wallet with PayTrie
    </h3>

    <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-md">
      <ol className="divide-y divide-gray-200">
        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 1.</b> Go to{' '}
            <a
              className="underline font-bold"
              target="_blank"
              rel="noreferrer"
              href="https://paytrie.com/"
            >
              Paytrie.com
            </a>{' '}
            and create an account.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-1/2" src={paytrieImg0} alt="Paytrie" />
          </figure>
          <p>
            You will have to go through a quick KYC (Know your customer)
            process, as required by Canadian legislation.
          </p>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <b>Step 2.</b> Once you’ve completed the account creation/ID
          verification process with Paytrie (shouldn’t take more than 10
          minutes), <b>you’re now ready to fund your Zyield wallet/account.</b>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 3.</b> Once logged in, from the Paytrie main page enter the
            CAD amount you’d like to exchange/fund into USDC-P stablecoin (
            <b>
              Make sure you select USDC-P and not something else, this is really
              important!
            </b>
            )
          </p>
          <p>
            Important Tip: For your first transaction we recommend first
            starting with a small amount between $20-$100 CAD to reduce the risk
            of losing your funds, in case you make any mistakes.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-1/2" src={paytrieImg1} alt="Paytrie" />
          </figure>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 4.</b> In the following screen, enter your{' '}
            <b>Zyield Wallet/Account</b> address. Make sure you double check
            when entering the address, before pressing the Submit button.
            Entering the wrong address will lead to loss of funds.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-1/3" src={paytrieImg2} alt="Paytrie" />
          </figure>
          <p>
            Your wallet address is: <InlineWalletAddress />
          </p>
          <p>
            Also, you can easily find your Zyield wallet address by going to
            Settings in the right-top corner of your account and copying the
            address string.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-1/2" src={paytrieImg3} alt="Paytrie" />
          </figure>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 5.</b> Submit your transaction and follow the instructions
            on the screen and email for confirmation:
          </p>
          <ol className="pl-5">
            <li>
              a. Check your email inbox for a Request Money Transfer email from
              Interac.
            </li>
            <li>
              b. Follow the instructions in the email to fund the transaction
              with your bank account.
            </li>
            <li>
              c. Wait for PayTrie to accept the funds (up to 60 mins, you will
              get an email notification when PayTrie accepts the funds)
            </li>
            <li>
              <b>
                d. Receive an email confirmation when the transaction is
                complete and the funds are in your Zyield account wallet.
              </b>
            </li>
          </ol>
        </li>
        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 6.</b> You should now be able to see your funds in your
            Zyield account wallet.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-1/2" src={paytrieImg4} alt="Paytrie" />
          </figure>
        </li>
      </ol>
    </div>
  </>
)

export default PaytrieWalkthrough
