import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, Link } from 'react-router-dom'

import { getSafeAddress } from '../utils/'
import { hexToDAI } from '../utils/eth_units'
import { getDaiBalance } from '../forge'

import Amount from '../partials/add_funds/Amount'
import Deposit from '../partials/add_funds/Deposit'
import Spinner from '../components/Spinner'

const ALCHEMY_URL = `https://${process.env.REACT_APP_NETWORK}.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`

const initialSteps = [
  {
    id: 'Step 1',
    number: 0,
    name: 'Select Amount',
    status: 'current'
  },
  {
    id: 'Step 2',
    number: 1,
    name: 'Confirmation',
    status: 'upcoming'
  }
]

export default function AddFunds() {
  let history = useHistory()
  let [walletBalance, setWalletBalance] = useState(null)
  let [step, setStep] = useState(0)
  let [amount, setAmount] = useState('')
  let [steps, updateSteps] = useState(initialSteps)
  let address = getSafeAddress()
  let walletAddress = address

  const { isLoading, error } = useQuery(
    'getDaiBalance',
    () => getDaiBalance(address),
    { onSuccess: setWalletBalance }
  )

  const { isLoading: isLoadingBalance, error: errorBalance } = useQuery(
    'getWalletBalance',
    () =>
      fetch(ALCHEMY_URL, {
        method: 'POST',
        body: JSON.stringify({
          id: 1337,
          jsonrpc: '2.0',
          method: 'eth_getBalance',
          params: [walletAddress, 'latest']
        })
      }).then(res => res.json()),
    {
      enabled: Boolean(walletAddress),
      onSuccess: data => setWalletBalance(hexToDAI(data.result))
    }
  )

  if (isLoading || isLoadingBalance) return <Spinner />
  if (error || errorBalance)
    return (
      'An error has occurred: ' + error?.message + '. ' + errorBalance?.message
    )

  let currentStep = steps[step]

  const nextStep = () => {
    let currentSteps = steps

    currentSteps[step].status = 'complete'

    if (!currentSteps[step + 1]) return history.push('/invest')

    currentSteps[step + 1].status = 'current'
    setStep(step + 1)
    updateSteps(currentSteps)
  }

  const goToStep = step => {
    let currentSteps = steps
    currentSteps[step].status = 'current'
    setStep(step)
    updateSteps(currentSteps)
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Amount
            step={step}
            amount={amount}
            setAmount={setAmount}
            nextStep={nextStep}
          />
        )

      case 1:
        return (
          <Deposit
            walletAddress={walletAddress}
            walletBalance={walletBalance}
            setWalletBalance={setWalletBalance}
            step={step}
            nextStep={nextStep}
            amount={amount}
          />
        )

      default:
      // do nothing
    }
  }

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      {/* Page title & actions */}
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-copy-800 sm:truncate">
            Add Funds
          </h1>
        </div>
      </div>
      <div className="px-4 mt-6 sm:px-6 lg:px-8">
        <nav aria-label="Progress">
          <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
            {steps.map(step => (
              <li key={step.name} className="md:flex-1">
                {step.status === 'complete' ? (
                  <Link
                    to={step.href}
                    onClick={() => goToStep(step.number)}
                    className="group pl-4 py-2 flex flex-col border-l-4 border-copy-400 hover:border-orange-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                  >
                    <span className="text-xs text-copy-400 font-semibold tracking-wide uppercase group-hover:text-orange-800">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </Link>
                ) : step === currentStep ? (
                  <span
                    className="pl-4 py-2 flex flex-col border-l-4 border-orange-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                    aria-current="step"
                  >
                    <span className="text-xs text-copy-400 font-semibold tracking-wide uppercase">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium text-copy-800">
                      {step.name}
                    </span>
                  </span>
                ) : (
                  <span className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                    <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </span>
                )}
              </li>
            ))}
          </ol>
        </nav>
        {renderStep()}
      </div>
    </main>
  )
}
