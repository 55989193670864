import Footer from '../components/Footer'

const Terms = () => (
  <main
    id="terms"
    className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"
  >
    <h1>Zyield Terms of Use</h1>
    <h2>1. Intro</h2>
    <p>
      Zyield operates a non-custodial interface and cryptocurrency wallet, which
      is accessible through the Zyield application that we make available
      through our website Zyield.fi (the "Zyield App"), which allows users to
      carry out and manage digital crypto assets transactions on compatible
      blockchain and similar networks (the "Zyield Wallet").
      <br />
      The Zyield App is operated by Zyield Finance Inc. ("Zyield", "we", "our",
      or "us").
    </p>
    <h2>2. Your relationship with us</h2>
    <p>
      This document and any documents referred to within it (collectively, the
      "Terms of Service") set out the terms of your relationship with us. It is
      important that you read and understand the Terms of Service before using
      the Zyield Wallet service.
      <br />
      By using and accessing the Zyield Wallet, you agree to these Terms of
      Service. If you do not agree to these Terms of Service, please do not use
      the Zyield App and Wallet.
    </p>
    <h2>3. About us</h2>
    <p>
      Zyield Finance Inc. is a company federally registered in Canada with its
      registered address at 229 Niagara Street, Toronto, On, M6J 2L5.
    </p>
    <p>
      If you would like to contact us, please write to us using the contact
      information at the end of these Terms of Service.
    </p>
    <h2>4. Information about you</h2>
    <p>
      Your privacy is important to us. Please read our Privacy Policy to
      understand how we collect, use and share information about you.
    </p>
    <h2>5. Important warnings about the Zyield App & Wallet</h2>
    <h3>a. Crypto transactions</h3>
    <p>
      The Zyield Wallet allows you to initiate and manage cryptoasset
      transactions on blockchains and similar networks compatible with the
      Zyield Wallet ("compatible networks"). Dealing or trading in cryptoassets
      is inherently risky, as the prices of cryptocurrency can change rapidly.
      We cannot guarantee that the value of any cryptoassets that you purchase
      will not fall. You therefore buy or sell any cryptoassets through the
      Zyield Wallet at your own risk.
    </p>
    <p>
      Completion of transactions that you initiate through the Zyield Wallet
      also depends on the availability and operation of the relevant compatible
      network you interact with using the Zyield Wallet. Errors or forks in the
      compatible network may cause transactions that you initiate through the
      Zyield Wallet to fail. This may mean that the transaction you were
      originally intending to perform will no longer be available.
      Unfortunately, due to the decentralised nature of these networks and
      blockchain technology, there is no one single point of failure, and so
      neither we nor any particular party will be responsible to you for errors
      or any losses that you suffer as a result.
    </p>
    <h3>b. Regulatory</h3>
    <p>
      The Zyield Wallet does not facilitate the storage or sending of fiat
      currencies and Zyield is not authorised to conduct regulated activities by
      the OSFI in Canada or any other regulator anywhere in the world. The
      crypto assets that you manage through the Zyield Wallet are not covered or
      underwritten by any regulatory guarantees or compensation schemes, such as
      the CDIC in Canada. The Zyield Wallet is therefore not like a regular bank
      account, and you should not use the Zyield Wallet as a substitute for your
      regular bank account.
    </p>
    <h3>c. Beta early access features</h3>
    <p>
      Blockchain technology changes on a regular basis, and we try to keep the
      functionalities of the Zyield Wallet up to date to keep up with new
      developments. We may, from time to time, offer access to new compatible
      networks and features of the Zyield Wallet that are still in an beta phase
      of their development. We will indicate to you when you are accessing such
      a feature or a network.
    </p>
    <p>
      While we do our best to make sure that any features that we make available
      are of a high standard, the new and relatively untested nature of these
      early access networks and features means that you are more likely to
      experience problems and unavailability than other parts of the Zyield
      Wallet. As a result, we cannot guarantee that these early access features
      and networks will always be available, free from errors or meet the
      standards you might expect of a more established and mainstream digital
      service.
    </p>
    <h3>e. Third party apps and exchanges</h3>
    <p>
      The Zyield Wallet allows you to access and use certain distributed
      applications ("Dapps") and cryptocurrency trading platforms ("Crypto
      Exchanges") that are integrated or compatible with the Zyield Wallet.
      These Dapps and Crypto Exchanges are provided by third parties that are
      not related to Zyield over compatible networks. Although we facilitate
      your access to these Dapps and Crypto Exchanges, we are not responsible
      for them in any way. Your use of the Dapps and Crypto Exchanges will be
      subject to separate terms and conditions. You should make sure that you
      check the Dapp or Crypto Exchange provider's website for the most up to
      date version of their terms and conditions before you access their
      services using the Zyield Wallet.
      <br />
      Not all Crypto Exchanges and Dapps support all types of cryptoassets. You
      should make sure, before you initiate any transactions through the Zyield
      Wallet, that the cryptoassets you are sending are compatible with the
      recipient's wallet. You will not be able to recover any cryptoassets that
      you have sent, and we will not be responsible to you for any losses you
      suffer as a result of any cryptoassets that you send being rejected by the
      recipient as incompatible or unsuitable.
      <br />
      Not all compatible networks will support the same Crypto Exchanges and
      Dapps. You should check which networks are compatible with the Crypto
      Exchanges and Dapps that you wish to use before you access or use them
      through the Zyield Wallet. Using Crypto Exchanges and Dapps on a different
      network to the compatible network you select when setting up your Zyield
      Wallet could cause the transactions to fail, and you may also not be able
      to recover any cryptoassets that you have send to those Crypto Exchanges
      or Dapps in connection with those transactions. We will not be responsible
      to you for any losses you suffer as a result of any use of Crypto
      Exchanges or Dapps on a different network to the compatible network on
      which you set up your Zyield Wallet.
    </p>
    <h3> f. Security</h3>
    <p>
      The cryptoassets you manage through the Zyield Wallet may be at risk if
      the security of your browser or computer on which you use and set up the
      Zyield App is compromised. You should therefore ensure that you keep your
      computer and browser safe and secure in accordance with best practices
      recommended by the provider of your operating system, such as setting up a
      "strong" password to prevent unauthorized access and ensuring that you
      keep your software up to date.
      <br />
      You are responsible for ensuring that your computer device is secure.
    </p>
    <h2>6. Accessing the Zyield Wallet</h2>
    <p>
      You must set up the Zyield App through your browser with a username(email)
      and a password in order to use the Zyield Wallet. You are responsible for
      maintaining the confidentiality of your password and any activities on the
      Zyield Wallet initiated through your browser and computer device.
      <br />
      You must be 18 years or older and capable in your country of residence of
      entering into a legally binding agreement to use the Zyield Wallet.
    </p>
    <h2>7. Important information about account wallet recovery</h2>
    <h3>
      a. If you lose or forget your Zyield account password, you can regain
      access to your account using a Zyield’s on-chain Recovery Module that
      comes installed with your Zyield smart contract wallet.
    </h3>
    <h3>b. Recovery module</h3>
    <h4>
      i. Each Zyield smart contract wallet is created with a special piece of
      software running on-chain that allows Zyield to replace the owner of a
      smart contract wallet. For security reasons, the recovery module will
      undergo a 48hrs timelock before the owner can actually be swapped. During
      this time, if you haven’t triggered the recovery and still have access to
      your account, you can cancel the recovery request.
    </h4>
    <h4>
      ii. When 48hrs elapse, Zyield will send you an email and prompt you to
      pick a new password for your account. Following that, Zyield will issue an
      on-chain transaction to swap the owner of your Zyield smart contract
      wallet with the new ownership (based on your new password).
    </h4>
    <h2>8. Your right to use the Zyield Wallet</h2>
    <p>
      The materials and content comprising the Zyield Wallet belong to us or our
      third party licensors, and we give you permission to use these materials
      and content for the sole purpose of using the Zyield Wallet in accordance
      with these Terms of Service.
      <br />
      Your right to use the Zyield Wallet is personal to you and you are not
      allowed to give this right to another person. Your right to use the Zyield
      Wallet does not stop us from giving other people the right to use the
      Zyield Wallet.
      <br />
      Unless allowed by these Terms of Service and as permitted by the
      functionality of the Zyield Wallet, you agree: i. not to copy any portion
      of the Zyield Wallet; ii. not to give or sell or otherwise make available
      any portion of the Zyield Wallet to anybody else; iii. not to change any
      portion of the Zyield Wallet in any way; iv. not to look for or access the
      code of any portion of the Zyield Wallet that we have not expressly
      published publicly for general use.
      <br />
      You agree that all confidential information, copyright and other
      intellectual property rights in the Zyield Wallet belong to us or the
      people who have licenced those rights to us.
      <br />
      You agree that you have no rights in or to any portion of the Zyield
      Wallet other than the right to use it in accordance with these Terms of
      Service.
    </p>
    <h2>9. Fees</h2>
    <p>0.5% fee on Token swaps through 1Inch Exchange (“Zyield Fee”)</p>
    <p>0.5% fee on investments (staking, farming, index investments, other)</p>
    <p>
      0.2% on account funding and withdrawals Fiat > Stablecoins > Fiat
      (integrated payment gateways)
    </p>
    <p>
      We will indicate to you if an Zyield Fee is payable in respect of the
      transaction before you confirm that transaction through the Zyield Wallet.
    </p>
    <p>
      We will ask you to pay the Zyield Fee at the same time as you initiate the
      transaction through 1Inch Exchange. If you decline to pay the Zyield Fee,
      or if you have insufficient cryptoassets to pay the Zyield Fee and the
      value of the transaction, we will decline to process the transaction
      through the Zyield Wallet.
    </p>
    <h2>10.Rules of Acceptable Use</h2>
    In addition to the other requirements within these Terms of Service, this
    section describes specific rules that apply to your use of the Zyield Wallet
    (the "Rules of Acceptable Use"). When using the Zyield Wallet you must not:
    circumvent, disable or otherwise interfere with any security related
    features of the Zyield Wallet; permit another person to use the Zyield
    Wallet on your behalf unless such person is authorised by you; use the
    Zyield Wallet if we have suspended or banned you from using it; advocate,
    promote or engage in any illegal or unlawful conduct, including any criminal
    activity, fraud or money laundering, or conduct that causes damage or injury
    to any person or property; undefined We may monitor the transactions you
    execute through the Zyield Wallet and perform checks on the cryptoassets
    that you manage using the Zyield Wallet to ensure that you comply with the
    Rules of Acceptable Use, including to ensure that the cryptoassets you
    manage are not, or are not purchased with funds obtained illegally. Failure
    to comply with Rules of Acceptable Use constitutes a serious breach of these
    Terms of Service, and may result in our taking all or any of the following
    actions (with or without notice): immediate, temporary or permanent
    withdrawal of your right to use our Zyield Wallet; issuing of a warning to
    you; legal action against you including proceedings for reimbursement of all
    costs (including, but not limited to, reasonable administrative and legal
    costs) resulting from the breach; disclosure of such information to law
    enforcement authorities as we reasonably feel is necessary. The responses
    described in paragraph 14(d) are not limited, and we may take any other
    action we reasonably deem appropriate.
    <h2>11. Ending our relationship</h2>
    If at any time you do not feel that you can agree to these Terms of Service
    or any changes made to the Terms of Service or the Zyield Wallet, you must
    immediately stop using the Zyield Wallet. We may immediately end your use of
    the Zyield Wallet if you break the Rules of Acceptable Use, any other
    important rule(s), or terms and conditions we set for accessing and using
    the Zyield Wallet including these Terms of Service. We may also withdraw the
    Zyield Wallet as long as we give you reasonable notice that we plan to do
    this. The termination of your use of the Zyield Wallet shall not affect any
    of your obligations to pay any sums due to us. Nothing in this paragraph 13
    affects any legal rights you may have under the law of the country in which
    you are resident.
    <h2>12. Our liability/responsibility to you</h2>
    While we do our best to ensure that the features and functionalities of the
    Zyield Wallet are of a reasonably satisfactory standard and match any
    descriptions we have provided to you. However, the Zyield Wallet facilitates
    your access to applications, services and networks operated by third parties
    that are beyond our control. As we do not own or produce such third party
    applications, services or networks, we cannot be responsible for them in any
    way or promise that the Zyield Wallet will always be compatible with such
    third party services. We will do our best to ensure that any elements of the
    Zyield Wallet that require a connection to the Internet and compatible
    networks will always be available to you. However, due to the nature of the
    Internet, the compatible networks and technology in general, any such
    features of the Zyield Wallet may be unavailable if we need to undertake any
    emergency or scheduled maintenance on our systems. In the event of claims
    arising out of the provision of the Zyield Wallet, our responsibility to you
    will never be more than the amount you have paid us, in the event that you
    have not paid us any money, we shall have no responsibility whatsoever to
    you. In every case, we will never be responsible for any loss or damage that
    is not reasonably foreseeable or that is caused by a failure by you to
    comply with these Terms of Service. Loss or damage is foreseeable if either
    it is obvious that it will happen or if, at the time you downloaded the
    Zyield Wallet, both we and you knew it might happen. The above does not
    affect your rights under the applicable law of the country in which you are
    resident, including our responsibility to you for any personal injury or
    death caused by our negligence, or if we deliberately breach our commitments
    in these Terms of Service.
    <h2>13. Resolving disputes</h2>
    We hope you enjoy your use of the Zyield Wallet. If, however, you have a
    complaint or dispute with us relating to the Zyield Wallet, in the first
    instance please contact us at legal@zyield.fi so that we can try to resolve
    the dispute informally. In the unlikely event that we have not been able to
    resolve a dispute informally, we will then discuss and agree with you the
    most effective way of resolving our dispute.
    <h2>14. Changes to the Zyield Wallet</h2>
    We are constantly updating and improving the Zyield App and Wallet to try
    and find ways to provide you with new and innovative features and services.
    In order to do this, we may need to update, reset, stop offering and/or
    supporting a particular part of the Zyield Wallet, or feature relating to
    the Zyield Wallet ("changes to the Zyield Wallet"). These changes to the
    Zyield Wallet may affect your past activities on the Zyield Wallet and
    certain features that you use ("Service Elements"). Any changes to the
    Zyield Wallet could involve your Service Elements being deleted or reset;
    however, this will not affect your rights to the cryptoassets you manage
    through the Zyield Wallet. You agree that a key characteristic of the Zyield
    Wallet is that changes to the Zyield Wallet will take place over time and
    this is an important basis on which we grant you access to the Zyield
    Wallet. Once we have made changes to the Zyield Wallet, your continued use
    of the Zyield Wallet will show that you have accepted any changes to the
    Zyield Wallet. You are always free to stop using the Zyield Wallet.
    <h2>15. Changes to the documents</h2>
    We may revise these Terms of Service from time to time but the most current
    version will always be at https://www.zyield.fi/terms-of-use/ Changes will
    usually occur because of new features being added to the Zyield Wallet,
    changes in the law or where we need to clarify our position on something.
    Normally, we will try to give you some warning before the new terms become
    effective. However, sometimes changes will need to be made immediately and
    if this happens we will not give you any notice.
    <h2>16. Documents that apply to our relationship with you</h2>
    The current version of the Terms of Service contains the only terms and
    conditions that apply to our relationship with you.
    <br />
    We intend to rely on these Terms of Service as setting out the written terms
    of our agreement with you for the provision of the Zyield Wallet. If part of
    the Terms of Service cannot be enforced then the remainder of the Terms of
    Service will still apply to our relationship.
    <br />
    If you do not comply with these Terms of Service and we do not take action
    immediately, this does not mean we have given up any right we have and we
    may still take action in the future.
    <h2>17. Law</h2>
    Canadian law will apply to all disputes and the interpretation of these
    Terms of Service. Ontario courts will have non-exclusive jurisdiction over
    any dispute arising from or related to your use of the Zyield Wallet. This
    does not affect your rights under the law of the country in which you are
    resident, including (where applicable) your right to have a dispute in
    relation to your use of the Zyield Wallet heard in the courts of that
    country.
    <h2>18. Contact, feedback and complaints</h2>
    If you need to contact us in relation to these Terms of Service or any other
    document mentioned in them, please email us at{' '}
    <a
      className="font-bold hover:opacity-70 underline"
      href="mailto:legal@zyield.fi"
    >
      legal@zyield.fi.
    </a>
    <Footer />
  </main>
)

export default Terms
