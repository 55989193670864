import * as React from 'react'
import { useNetwork } from 'wagmi'

import NetworkSwitchModal from './NetworkSwitchModal'

const NetworkSwitcher = () => {
  const { activeChain, chains, error, switchNetwork } = useNetwork()

  return (
    <div>
      {error && error?.message}

      {activeChain?.unsupported && (
        <NetworkSwitchModal
          activeChain={activeChain}
          chains={chains}
          switchNetwork={switchNetwork}
        />
      )}
    </div>
  )
}

export default NetworkSwitcher
