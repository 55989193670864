import logo from '../images/logo.svg'
import UserDropdown from '../components/UserDropdown'
import { Link } from 'react-router-dom'

import merge from 'lodash.merge'
import { Account } from '../components/Account'
import { getEmail } from '../utils'

const AuthHeader = ({ email }) => (
  <header className="w-full hidden lg:flex justify-between border-b border-gray-200 py-4">
    <div className="flex items-center flex-shrink-0 px-6">
      <Link to="/dashboard">
        <img className="h-12" src={logo} alt="Zyield" />
      </Link>
    </div>
    <div className="flex">
      {!email && <Account />}
      <UserDropdown />
    </div>
  </header>
)

export default AuthHeader
