import { Disclosure, Transition } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: 'What is DeFi?',
    __html: `
      Simply put, DeFi is a Lego-like ecosystem of open financial protocols and applications built on top of blockchain networks like Ethereum, Avalanche, Polygon and others.
      DeFi’s main advantages over the traditional financial system are its decentralization(for the most part, but not all networks and applications are fully decentralized), openness, transparency and interoperability.
      In comparison, the traditional financial system is extremely centralized, opaque and highly regulated with multiple rent-seeking intermediaries. Because of it, innovation in the space has been extremely slow or none at all for the past several decades and has offered limited opportunities for regular retail investors.
      `
  },
  {
    question: 'How does Zyield work?',
    __html: `
      Zyield is DeFi protocol and interface built on top of the Gnosis Safe smart contract wallet, one of the safest and most trusted crypto wallets, currently securing over $86 billion USD worth of digital assets. More on this <a class="underline font-bold" target="_blank" href="https://gnosis-safe.io/security/">here</a><br />
      Zyield is making investing in DeFi simple by abstracting away the complexities associated with managing a smart contract wallet, handling multiple transactions, signing transactions, network gas fees, safety and security.<br />
      Zyield is also curating the investing options by only selecting the best, safest and most trusted DeFi protocols and offerings across multiple networks and chains. We’re also offering smart contract insurance on our Save deposits through our partnership with Nexus Mutual, a smart contract cover protocol.
    `
  },
  {
    question: 'Do you control the funds?',
    __html: `We do not have access to or control any of the funds deposited through Zyield. You have complete control of your funds through the ownership of your smart contract wallet. We only enable and provide the interface for a seamless DeFi experience.`
  },
  {
    question: 'How is Zyield different from Crypto.com or similar services?',
    __html: `
      The main difference is that Zyield is a non-custodial service, whereas services like Crypto.com, BlockFi, Nesto, Celsius and others are full custodians of your funds.<br />
      Using Zyield is a fully transparent experience with a clear view of where your funds are allocated, these other services are blackboxes, similar to your bank, offering no view into how your funds are distributed and used.`
  },
  {
    question: 'What are your fees?',
    __html: `
    0.5% fee on Token swaps<br />
    1% fee on investments (staking, farming, liquidity pools)
    `
  },
  {
    question: 'How do I deposit and withdraw my funds?',
    __html: `
      We’ve partnered up with Paytrie, Moonpay, and Ramp Network, all well known and established fiat to crypto payment processors, to make it easy for our users to deposit and withdraw funds using Interac debit in Canada, debit cards and credit cards (Worldwide).
    `
  },
  {
    question: 'Is this safe?',
    __html: `
      We try to make the experience as safe and secure as possible, given the inherent risks associated with any new technology and innovation, especially in the personal finance space.<br />
      We only integrate with proven and audited protocols and smart contracts for our offerings and offer smart contract insurance on deposits through Nexus Mutual, a smart contract cover protocol. We follow all the industry standards and security best practices for handling on-chain transactions and user account management.<br />
      You should be aware that there is always a degree of risk associated with investing in cryptocurrencies and DeFi protocols.
`
  }
]

const FAQ = () => (
  <div id="faq" className="mt-20 relative py-16">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="font-bold tracking-tight text-copy-800 sm:text-4xl">
        <span className="block text-5xl">Frequently Asked Questions</span>
      </h2>

      <div className="mt-28 max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <dl className="space-y-9 divide-y divide-gray-200">
          {faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-9">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start">
                      <span className="text-copy-800 font-bold">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        {open ? (
                          <MinusIcon
                            className="text-copy-800 h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusIcon
                            className="text-copy-800 h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel as="dd" className="mt-6 pr-12">
                      <p
                        dangerouslySetInnerHTML={faq}
                        className="text-left text-copy-800 opacity-60 leading-loose"
                      ></p>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  </div>
)

export default FAQ
