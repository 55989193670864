import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom'
import { ethers } from 'ethers'
import Home from './pages/Home'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import Dashboard from './pages/Dashboard'
import Withdraw from './pages/Withdraw'
import Settings from './pages/Settings'
import Swaps from './pages/Swaps'
import Transfer from './pages/Transfer'
import Activity from './pages/Activity'
import Notifications from './pages/Notifications'
import AddFunds from './pages/AddFunds'
import Save from './pages/Save'
//import FiatDeposit from './pages/FiatDeposit'
import FiatWithdrawal from './pages/FiatWithdrawal'
import FiatDepositStatus from './pages/FiatDepositStatus'
import PaytrieKYC from './pages/PaytrieKYC'
import Indexes from './pages/Indexes'
import Coin from './pages/Coin'
import InvestIndex from './pages/InvestIndex'
import Registration from './pages/Registration'
import EmailConfirmation from './pages/EmailConfirmation'
import Login from './pages/Login'
import ConfirmEmail from './pages/account_recovery/ConfirmEmail'
import RegistrationSuccessful from './pages/RegistrationSuccessful'
import InitiateRecovery from './pages/account_recovery/InitiateRecovery'
import SwapOwner from './pages/account_recovery/SwapOwner'
import ExternalFunding from './pages/ExternalFunding'

import AuthLayout from './layouts/AuthLayout'
import AuthLayoutMinimal from './layouts/AuthLayoutMinimal'
import MainLayout from './layouts/MainLayout'

import { getEmail, getAuthToken } from './utils'

import WebsocketProvider from './context/WebsocketContext'

const initIntercom = () =>
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'cvry84h8'
  })

const initAuthorizedIntercom = email =>
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'cvry84h8',
    name: '', // Full name
    email,
    created_at: ''
  })

const updateIntercom = () => window.Intercom('update')

const MainLayoutRoute = ({ component: Component, ...rest }) => {
  initIntercom()

  return (
    <Route
      {...rest}
      render={matchProps => (
        <MainLayout {...matchProps}>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  )
}

const AuthLayoutRoute = ({ component: Component, ...rest }) => {
  let isAllowed = getAuthToken()
  let email = getEmail()

  initAuthorizedIntercom(email)

  // TODO: check on every auth route?
  if (!isAllowed) return <Redirect to="/register" />

  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  )
}

const AuthLayoutMinimalRoute = ({ component: Component, ...rest }) => {
  updateIntercom()
  let isAllowed = getAuthToken()

  // TODO: check on every auth route?
  if (!isAllowed) return <Redirect to="/register" />

  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthLayoutMinimal>
          <Component {...matchProps} />
        </AuthLayoutMinimal>
      )}
    />
  )
}

const InvestRouter = () => {
  updateIntercom()
  let { path } = useRouteMatch()

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <Indexes />
        </Route>
        <Route exact path={`${path}/:ticker`}>
          <InvestIndex />
        </Route>
      </Switch>
    </Router>
  )
}

export default function App() {
  let provider = new ethers.providers.WebSocketProvider(
    process.env.REACT_APP_ALCHEMY_WS_URL,
    'matic'
  )

  return (
    <WebsocketProvider.Provider value={provider}>
      <Router>
        <Switch>
          <AuthLayoutRoute path="/dashboard" component={Dashboard} />
          <AuthLayoutRoute path="/activity" component={Activity} />
          <AuthLayoutRoute path="/add_funds" component={AddFunds} />
          <AuthLayoutRoute path="/paytrie_kyc" component={PaytrieKYC} />
          <AuthLayoutRoute path="/swaps" component={Swaps} />
          <AuthLayoutMinimalRoute
            path="/fiat_deposit/status"
            component={FiatDepositStatus}
          />
          <AuthLayoutRoute path="/fiat_deposit" component={ExternalFunding} />
          <AuthLayoutRoute path="/transfer" component={Transfer} />
          <AuthLayoutRoute path="/fiat_withdrawal" component={FiatWithdrawal} />
          <AuthLayoutRoute path="/invest" component={InvestRouter} />
          <AuthLayoutRoute path="/coins/:ticker" component={Coin} />
          <AuthLayoutRoute path="/save" component={Save} />
          <AuthLayoutRoute path="/withdraw" component={Withdraw} />
          <AuthLayoutRoute path="/settings" component={Settings} />
          <AuthLayoutRoute path="/notifications" component={Notifications} />
          <MainLayoutRoute
            path="/account_recovery/confirm_email"
            component={ConfirmEmail}
          />
          <MainLayoutRoute
            path="/account_recovery/initiate_recovery"
            component={InitiateRecovery}
          />
          <MainLayoutRoute
            path="/account_recovery/swap_owner"
            component={SwapOwner}
          />
          <MainLayoutRoute path="/login" component={Login} />
          <MainLayoutRoute path="/register" component={Registration} />
          <MainLayoutRoute
            path="/registration_successful"
            component={RegistrationSuccessful}
          />
          <MainLayoutRoute
            path="/email_confirmation"
            component={EmailConfirmation}
          />
          <MainLayoutRoute path="/terms-of-use" component={Terms} />
          <MainLayoutRoute path="/privacy" component={Privacy} />
          <MainLayoutRoute path="/" component={Home} />
        </Switch>
      </Router>
    </WebsocketProvider.Provider>
  )
}
