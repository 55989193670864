import React from 'react'
import Spinner from '../../components/Spinner'

const Transfer = ({ amount, walletBalance, nextStep }) => {
  if (walletBalance >= amount) {
    return (
      <div className="flex justify-center">
        <button
          onClick={() => nextStep()}
          className="w-1/3 bg-orange-500 hover:bg-orange-600 text-center text-white shadow-sm rounded-md text-sm py-2 px-4 justify-center mt-5"
        >
          Continue
        </button>
      </div>
    )
  } else {
    return (
      <div className="bg-white py-4 px-4 sm:rounded-lg sm:px-10">
        <Spinner />
        <p className="text-center mt-4">
          Waiting for
          <span className="ml-1 font-bold text-orange-500">{amount} DAI</span>
        </p>
      </div>
    )
  }
}

export default Transfer
