import { request } from './index'
import { ethers } from 'ethers'
import { useState } from 'react'

export const createTransactionsJob = async ({
  tx,
  amount,
  type,
  confirmation_code,
  insured
}) => {
  let payload = { transaction: tx }

  if (insured) {
    payload.insured = insured
  }
  if (confirmation_code) {
    payload.confirmation_code = confirmation_code
  }
  if (amount) {
    payload.amount = amount
  }
  if (type) {
    payload.type = type
  }

  let { hash, error } = await request('/api/transactions', {
    method: 'POST',
    body: JSON.stringify(payload)
  }).then(res => res.json())

  if (error) return { error: error }

  return hash
}

export const useTransaction = () => {
  const [loading, setLoading] = useState(false)

  const withErrorHandling = async fn => {
    setLoading(true)

    try {
      await fn()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return [loading, setLoading, withErrorHandling]
}

export const payloadToSign = tx => ({
  to: tx.to,
  value: tx.value === '0x0' ? 0 : tx.value || 0,
  data: tx.data,
  operation: tx.operation || 0,
  safeTxGas: tx.safeTxGas || 68500,
  baseGas: tx.baseGas || 0,
  gasPrice: tx.gasPrice || 0,
  gasToken: tx.gasToken || ethers.constants.AddressZero,
  refundReceiver: tx.refundReceiver || ethers.constants.AddressZero,
  nonce: tx.nonce
})
