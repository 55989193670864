const ethers = require('ethers')
const { MATIC_ADDRESS } = require('../utils')

export const contracts = {
  rinkeby: {
    cdai: '0x6D7F0754FFeb405d23C51CE938289d4835bE3b14',
    dai: '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa',
    idle: '0x9Ea007843318B9EcD85f93eCC55D4e19143f007A'
  },
  homestead: {
    cdai: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
    idle: '0x3fE7940616e5Bc47b0775a0dccf6237893353bB4',
    yearn: '0xACd43E627e64355f1861cEC6d3a6688B31a6F952'
  },
  'polygon-mainnet': {
    cdai: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    usdc: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    imusd: '0x5290ad3d83476ca6a2b178cd9727ee1ef72432af',
    vimusd: '0x32aba856dc5ffd5a56bcd182b13380e5c855aa29',
    musd: '0xe840b73e5287865eec17d250bfb1536704b43b21',
    'kashi/DAI': '0x12d7906b1c9a2e0f73d251bafdbd369fed6f8c64',
    'kashi/USDC': '0xd51b929792cfcde30f2619e50e91513dcec89b23',
    'aave/USDC': '0x1a13F4Ca1d028320A707D99520AbFefca3998b7F',
    'aave/DAI': '0x27F8D03b3a2196956ED754baDc28D73be8830A6e'
  }
}

const { REACT_APP_NETWORK: network, REACT_APP_ALCHEMY_API_KEY: alchemy } =
  process.env

export const getDecimalsForAsset = asset => {
  if (asset?.toUpperCase() === 'USDC') {
    return 6
  } else {
    return 18
  }
}

export const getBalance = ({
  address,
  token,
  token_address,
  decimals = 18
}) => {
  let url = `https://${network}.g.alchemy.com/v2/${alchemy}`
  let provider = new ethers.providers.JsonRpcProvider(url)
  let contract_address = token_address
    ? token_address
    : contracts[network][token]
  let contract = new ethers.Contract(
    contract_address,
    [
      'function balanceOf(address _owner) public view returns (uint256 balance)'
    ],
    provider
  )

  if (token_address == MATIC_ADDRESS) {
    return getNativeBalance({ address, decimals })
  } else {
    return contract
      .balanceOf(address)
      .then(res => ethers.utils.formatUnits(res, decimals))
  }
}

const getNativeBalance = ({ address, decimals = 18 }) => {
  // https://polygon-mainnet.g.alchemyapi.io/v2/your-api-key
  let url = `https://${network}.g.alchemy.com/v2/${alchemy}`
  let payload = {
    jsonrpc: '2.0',
    method: 'eth_getBalance',
    params: [address, 'latest'],
    id: 0
  }

  return fetch(url, {
    body: JSON.stringify(payload),
    method: 'POST'
  })
    .then(res => res.json())
    .then(({ result }) => ethers.utils.formatUnits(result, decimals))
}

export const getDaiBalance = address => getBalance({ address, token: 'cdai' })

export const getUSDCBalance = address =>
  getBalance({ address, token: 'usdc', decimals: 6 })

export const getKashiBalance = (address, asset) =>
  getBalance({
    address,
    token: `kashi/${asset?.toUpperCase()}`,
    decimals: getDecimalsForAsset(asset)
  })

export const getimUSDBalance = (address, asset) =>
  getBalance({
    address,
    token: 'imusd',
    decimals: getDecimalsForAsset(asset)
  })

export const getvimUSDBalance = (address, asset) =>
  getBalance({
    address,
    token: 'vimusd',
    decimals: getDecimalsForAsset(asset)
  })

export const getmUSDBalance = (address, asset) =>
  getBalance({
    address,
    token: 'musd',
    decimals: getDecimalsForAsset(asset)
  })

export const getAaveBalance = (address, asset) =>
  getBalance({
    address,
    token: `aave/${asset?.toUpperCase()}`,
    decimals: getDecimalsForAsset(asset)
  })

export const getMATICBalance = address =>
  fetch(
    `https://api.polygonscan.com/api?module=account&action=balance&address=${address}&tag=latest&apikey=${process.env.REACT_APP_POLYGONSCAN_API_KEY}`
  ).then(res => res.json())
