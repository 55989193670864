import quotesImg from '../images/quotes.svg'

const testimonials = [
  {
    text: 'Heard a lot about DeFi and wanted to try it out. This is by far the easiest way to get started.',
    author: 'Daria F.'
  },
  {
    text: 'I’m blown away by how easy it is to start using DeFi through Zyield. Love it!',
    author: 'Ben W.'
  },
  {
    text: 'Your Save returns are unreal. You guys are great!',
    author: 'Maria M.'
  }
]

const Testimonials = () => (
  <div className="mt-20 relative py-16 lg:py-15 text-copy-800">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="font-bold tracking-tight text-copy-800 sm:text-4xl">
        <span className="block text-5xl">Testimonials</span>
      </h2>
      <p className="mt-4 text-copy-800 opacity-90 tracking-light text-xl">
        Feedback from our early users.
      </p>

      <div className="mt-20 grid md:grid md:gap-12 md:grid-cols-3 text-left">
        {testimonials.map(({ text, author }) => (
          <div
            key={author}
            className="mb-5 p-8 pb-16 bg-white rounded-3xl relative flex-1 shadow-lg"
          >
            <img src={quotesImg} aria-hidden alt="Quotes" />
            <p className="mt-8 mb-10 lg:mb-16 italic text-xl font-light leading-relaxed">
              “{text}”
            </p>
            <p className="text-lg bottom-8 font-bold absolute">{author}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Testimonials
