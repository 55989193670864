import Footer from '../components/Footer'

const Privacy = () => (
  <main
    id="terms"
    className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"
  >
    <h1>Privacy Policy</h1>
    Effective date: January 1st, 2022
    <br />
    Zyield Finance Inc. ("us", "we", or "our") operates the zyield.fi website
    and the Zyield non-custodial wallet and services (hereinafter referred to as
    the "Service").
    <br />
    This page informs you of our policies regarding the collection, use and
    disclosure of personal data when you use our Service and the choices you
    have associated with that data.
    <br />
    We use your data to provide and improve the Service. By using the Service,
    you agree to the collection and use of information in accordance with this
    policy.
    <br />
    <h2>Definitions</h2>
    <h3>Service</h3>
    Service is the zyield.fi website and associated non-custodial wallet
    operated by Zyield Finance Inc.
    <h3>Personal Data</h3>
    Personal Data means data about a living individual who can be identified
    from those data (or from those and other information either in our
    possession or likely to come into our possession).
    <h3>Usage Data</h3>
    Usage Data is data collected automatically either generated by the use of
    the Service or from the Service infrastructure itself (for example, the
    duration of a page visit).
    <h3>Cookies</h3>
    Cookies are small files stored on your device (computer or mobile device).
    <h2>Information Collection and Use</h2>
    We collect several different types of information for various purposes to
    provide and improve our Service to you.
    <h3>Types of Data Collected</h3>
    <h4>Personal Data</h4>
    While using our Service, we may ask you to provide us with certain
    personally identifiable information that can be used to contact or identify
    you ("Personal Data"). Personally identifiable information may include, but
    is not limited to:
    <h4>Email address</h4>
    <h4>Cookies and Usage Data</h4>
    We may use your Personal Data to contact you with newsletters, marketing or
    promotional materials and other information that may be of interest to you.
    You may opt out of receiving any, or all, of these communications from us by
    following the unsubscribe link or the instructions provided in any email we
    send.
    <h4>Usage Data</h4>
    We may also collect information on how the Service is accessed and used
    ("Usage Data"). This Usage Data may include information such as your
    computer's Internet Protocol address (e.g. IP address), browser type,
    browser version, the pages of our Service that you visit, the time and date
    of your visit, the time spent on those pages, unique device identifiers and
    other diagnostic data.
    <h4>Tracking Cookies Data</h4>
    We use cookies and similar tracking technologies to track the activity on
    our Service and we hold certain information. Cookies are files with a small
    amount of data which may include an anonymous unique identifier. Cookies are
    sent to your browser from a website and stored on your device. Other
    tracking technologies are also used such as beacons, tags and scripts to
    collect and track information and to improve and analyse our Service. You
    can instruct your browser to refuse all cookies or to indicate when a cookie
    is being sent. However, if you do not accept cookies, you may not be able to
    use some portions of our Service. <br />
    Examples of Cookies we use:
    <h5>Session Cookies. </h5>
    We use Session Cookies to operate our Service. Preference Cookies. We use
    Preference Cookies to remember your preferences and various settings.
    <h5>Security Cookies.</h5>
    We use Security Cookies for security purposes.
    <h2>Use of Data</h2>
    Zyield Finance Inc. uses the collected data for various purposes:
    <ul>
      <li>To provide and maintain our Service</li>
      <li>To notify you about changes to our Service</li>
      <li>
        To allow you to participate in interactive features of our Service when
        you choose to do so
      </li>
      <li>To provide customer support</li>
      <li>
        To gather analysis or valuable information so that we can improve our
        Service
      </li>
      <li>
        To monitor the usage of our Service To detect, prevent and address
        technical issues
      </li>
      <li>
        To provide you with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless you have
        opted not to receive such information
      </li>
    </ul>
    <h2>Transfer of Data</h2>
    Your information, including Personal Data, may be transferred to — and
    maintained on — computers located outside of your state, province, country
    or other governmental jurisdiction where the data protection laws may differ
    from those of your jurisdiction.
    <br />
    If you are located outside Canada and choose to provide information to us,
    please note that we transfer the data, including Personal Data, to Canada
    and process it there.
    <br />
    Your consent to this Privacy Policy followed by your submission of such
    information represents your agreement to that transfer.
    <br />
    Zyield Finance Inc. will take all the steps reasonably necessary to ensure
    that your data is treated securely and in accordance with this Privacy
    Policy and no transfer of your Personal Data will take place to an
    organisation or a country unless there are adequate controls in place
    including the security of your data and other personal information.
    <h2>Disclosure of Data</h2>
    <h3>Business Transaction</h3> If Zyield Finance Inc. is involved in a
    merger, acquisition or asset sale, your Personal Data may be transferred. We
    will provide notice before your Personal Data is transferred and becomes
    subject to a different Privacy Policy.
    <h3>Legal Requirements</h3>
    Zyield Finance Inc. may disclose your Personal Data in the good faith belief
    that such action is necessary to:
    <ul>
      <li>To comply with a legal obligation </li>
      <li>
        To protect and defend the rights or property of Zyield Finance Inc.  
      </li>
      <li>
        To prevent or investigate possible wrongdoing in connection with the
        Service{' '}
      </li>
      <li>
        To protect the personal safety of users of the Service or the public{' '}
      </li>
      <li>To protect against legal liability</li>
    </ul>
    <h2>Security of Data</h2>
    The security of your data is important to us but remember that no method of
    transmission over the Internet or method of electronic storage is 100%
    secure. While we strive to use commercially acceptable means to protect your
    Personal Data, we cannot guarantee its absolute security.
    <h2>Service Providers</h2>
    We may employ third party companies and individuals to facilitate our
    Service ("Service Providers"), provide the Service on our behalf, perform
    Service-related services or assist us in analysing how our Service is used.
    <br />
    These third parties have access to your Personal Data only to perform these
    tasks on our behalf and are obligated not to disclose or use it for any
    other purpose.
    <h3>Analytics</h3> We may use third-party Service Providers to monitor and
    analyse the use of our Service.
    <h4>Google Analytics</h4>
    Google Analytics is a web analytics service offered by Google that tracks
    and reports website traffic. Google uses the data collected to track and
    monitor the use of our Service. This data is shared with other Google
    services. Google may use the collected data to contextualise and personalise
    the ads of its own advertising network.
    <br />
    You can opt-out of having made your activity on the Service available to
    Google Analytics by installing the Google Analytics opt-out browser add-on.
    The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and
    dc.js) from sharing information with Google Analytics about visits activity.
    <br />
    For more information on the privacy practices of Google, please visit the
    Google Privacy Terms web page:{' '}
    <a
      className="font-bold undeline hover:opacity-70"
      href="https://policies.google.com/privacy?hl=en"
      target="_blank"
      rel="noreferrer"
    >
      https://policies.google.com/privacy?hl=en"
    </a>
    <h2>Links to Other Sites</h2>
    Our Service may contain links to other sites that are not operated by us. If
    you click a third party link, you will be directed to that third party's
    site. We strongly advise you to review the Privacy Policy of every site you
    visit. We have no control over and assume no responsibility for the content,
    privacy policies or practices of any third party sites or services.
    <h2>Children's Privacy</h2> Our Service does not address anyone under the
    age of 18 ("Children").
    <br />
    We do not knowingly collect personally identifiable information from anyone
    under the age of 18. If you are a parent or guardian and you are aware that
    your Child has provided us with Personal Data, please contact us. If we
    become aware that we have collected Personal Data from children without
    verification of parental consent, we take steps to remove that information
    from our servers.
    <h2>Changes to This Privacy Policy</h2>
    We may update our Privacy Policy from time to time. We will notify you of
    any changes by posting the new Privacy Policy on this page.
    <br />
    We will let you know via email and/or a prominent notice on our Service,
    prior to the change becoming effective and update the "effective date" at
    the top of this Privacy Policy.
    <br />
    You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this
    page.
    <h2>Contact Us</h2> If you have any questions about this Privacy Policy,
    please contact us:
    <br /> By email:{' '}
    <a
      className="font-bold hover:opacity-70 underline"
      href="mailto:support@zyield.fi"
    >
      support@zyield.fi
    </a>
    <Footer />
  </main>
)

export default Privacy
