import React from 'react'
import { BadgeCheckIcon } from '@heroicons/react/solid'

import { getEmail } from '../../utils'

const EmailVerification = ({
  emailVerificationSent,
  handleEmailCode,
  handleEmailSubmit,
  email = '',
  setEmail,
  setEmailCode,
  emailCode = ''
}) => {
  setEmail(getEmail())

  return (
    <div className="w-full">
      {!emailVerificationSent && (
        <div className="rounded-md bg-green-50 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <BadgeCheckIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Why do I need to verify my email again ?
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Although you verified your email with Zyield, in order to
                  process deposits and withdrawals to and from your bank account
                  we have to use a 3rd party service called{' '}
                  <a
                    className="underline font-bold hover:text-green-800"
                    href="https://faq.paytrie.com/article/32-is-paytrie-regulated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paytrie
                  </a>
                  . This and the following steps are verifying your identity
                  with Paytrie.{' '}
                  <strong>
                    We don't store any of your private information.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <h3 className="text-md leading-6 font-medium text-copy-800">
        {emailVerificationSent
          ? 'Please check your email'
          : 'Verify your email'}
      </h3>

      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          {emailVerificationSent
            ? 'Please enter the code you received from Paytrie'
            : 'Please enter your email address'}
        </p>
      </div>
      <div className="mt-5 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs">
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          {emailVerificationSent ? (
            <input
              onChange={e => setEmailCode(e.target.value)}
              type="text"
              name="code"
              id="code"
              className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={emailCode}
            />
          ) : (
            <input
              onChange={e => setEmail(e.target.value)}
              type="text"
              name="email"
              id="email"
              className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="you@example.com"
              value={email}
            />
          )}
        </div>
        {emailVerificationSent ? (
          <button
            onClick={handleEmailCode}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Verify code
          </button>
        ) : (
          <button
            onClick={handleEmailSubmit}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Send verification code
          </button>
        )}
      </div>
    </div>
  )
}

export default EmailVerification
