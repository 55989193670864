const SectionHeading = ({ heading, subheading }) => (
  <>
    <h1 className="text-2xl font-medium leading-6 text-copy-800 sm:truncate">
      {heading}
    </h1>
    {subheading ? (
      <p className="text-complimantary-800 mt-3 text-md text-gray-500">
        {subheading}
      </p>
    ) : null}
  </>
)

export default SectionHeading
