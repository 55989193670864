import React, { useState, useEffect, useContext } from 'react'
import {
  getSafeAddress,
  getSigner,
  getEmail,
  request,
  getPaytrieToken
} from '../utils/'
import { safeSignTypedData } from '../utils/safe_utils'
import {
  getDaiBalance,
  getUSDCBalance,
  getDecimalsForAsset,
  contracts
} from '../forge/index'
import PaytrieAuthModal from '../components/PaytrieAuthModal'
import PendingTransactionModal from '../components/PendingTransactionModal'
import WebsocketContext from '../context/WebsocketContext'
import Notification from '../components/Notification'
import SectionHeading from '../components/SectionHeading'

const FiatWithdrawal = props => {
  const wsProvider = useContext(WebsocketContext)
  const [txHash, setTxHash] = useState(null)
  const [showTxStatusModal, setTxStatusModalOpen] = useState(false)
  const [quoteId, setQuoteId] = useState(null)
  const [cadUsdRate, setCadUsdRate] = useState(null)
  const [fee, setFee] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [assetBalance, setAssetBalance] = useState(null)
  const [amount, setAmount] = useState(0)
  const [time, setTime] = useState(null)

  const [showNotification, setShowNotification] = useState(false)
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationBody, setNotificationBody] = useState('')
  const [isSuccessfulNotification, setIsSuccessfulNotification] = useState('')

  let token = getPaytrieToken()

  const successTitle = 'Withdrawal successfully initiated'
  const successBody =
    'Please follow the instructions in the email you will receive from PayTrie for depositing the Interac Transfer into your account.'

  let signer = getSigner()
  let safeAddress = getSafeAddress()
  let email = getEmail()
  let defaultStablecoin = process.env.REACT_APP_DEFAULT_STABLECOIN

  const { REACT_APP_NETWORK: network } = process.env

  const PAYTRIE_WITHDRAWAL_ADDRESS =
    '0x5b5ecfc8122ba166b21d6ea26268ef97e09b2e9f'

  const asset_address = contracts[network][defaultStablecoin.toLowerCase()]
  const asset_decimals = getDecimalsForAsset(defaultStablecoin)

  const calculateAmount = (amount, rate, fee) => {
    let conversion = amount * rate
    let feeAmount = conversion * fee
    if (feeAmount < 5) {
      return (conversion - 5).toFixed(2)
    } else {
      return (conversion - feeAmount).toFixed(2)
    }
  }

  useEffect(() => {
    async function fetchData() {
      request('/api/fiat_gateway/get_quote', {
        method: 'GET'
      })
        .then(res => res.json())
        .then(({ id, cadusd, fee }) => {
          setQuoteId(id)
          setFee(fee)
          setCadUsdRate(cadusd)
        })
    }

    fetchData()
  }, [])

  const handleSubmit = () => {
    if (token) {
      request('/api/fiat_gateway/submit_transaction', {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          quote_id: quoteId,
          value: amount,
          wallet: safeAddress,
          from: 'USDC-P',
          to: 'CAD',
          token: token
        })
      })
        .then(res => res.json())
        .then(({ tx, status, message }) => {
          if (status === 'error') throw message
          return request(
            `/api/transactions/new?type=transfer&amount=${amount}&to=${PAYTRIE_WITHDRAWAL_ADDRESS}&token_address=${asset_address}&decimals=${asset_decimals}`
          )
        })
        .then(res => res.json())
        .then(async tx => {
          let payload = {
            to: tx.to,
            value: tx.value,
            data: tx.data,
            operation: tx.operation,
            safeTxGas: tx.safeTxGas,
            baseGas: tx.baseGas,
            gasPrice: tx.gasPrice,
            gasToken: tx.gasToken,
            refundReceiver: tx.refundReceiver,
            nonce: tx.nonce
          }

          let { data: signature } = await safeSignTypedData(
            signer,
            { address: safeAddress },
            payload
          )

          return request('/api/transactions', {
            method: 'POST',
            body: JSON.stringify({
              transaction: { ...tx, signatures: [signature] }
            })
          })
        })
        .then(res => res.json())
        .then(({ hash }) => {
          setTxHash(hash)
          setTxStatusModalOpen(true)
        })
        .catch(message => {
          setIsSuccessfulNotification(false)
          setNotificationTitle('Withdrawal failed')
          setNotificationBody(message)
          setShowNotification(true)
        })
    } else {
      request('/api/fiat_gateway/authorize', {
        method: 'POST',
        body: JSON.stringify({ email: email })
      })
        .then(res => res.json())
        .then(({ time }) => {
          setTime(time)
          setShowModal(true)
        })
    }
  }

  useEffect(() => {
    async function fetchData(safeAddress) {
      let assetBalance
      if (defaultStablecoin === 'USDC') {
        assetBalance = await getUSDCBalance(safeAddress)
      } else {
        assetBalance = await getDaiBalance(safeAddress)
      }
      setAssetBalance(assetBalance)
    }

    fetchData(safeAddress)
  }, [safeAddress, defaultStablecoin])

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <SectionHeading heading="Withdrawal" />
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-6 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 pt-4 mb-5"
              >
                How much would you like to withdraw ?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex justify-between items-stretch rounded-md shadow-sm bg-gray-50 border border-gray-200 h-full block p-5 max-w-lg mr-8">
                  <button
                    onClick={e => {
                      setAmount(assetBalance)
                      e.preventDefault()
                    }}
                    className="text-sm text-gray-500 items-baseline text-left"
                  >
                    Balance: ${parseFloat(assetBalance || 0).toFixed(2)}{' '}
                    {defaultStablecoin}{' '}
                  </button>
                  <div className="flex-0 w-3/5 relative rounded-md bg-white">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>

                    <input
                      onFocus={() => amount <= 0 && setAmount('')}
                      onBlur={() => amount <= 0 && setAmount(0)}
                      onChange={e => setAmount(e.target.value)}
                      value={amount}
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="text-right block flex w-full pl-7 pr-14 text-2xl items-center self-center border-0 rounded-md focus:ring-0 outline-none bg-transparent"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        USDC
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Boolean(amount) && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 pt-4 mb-5"
                >
                  Conversion into CAD
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex justify-end rounded-md shadow-sm bg-gray-50 border border-gray-200 h-full block p-5 max-w-lg mr-8">
                    <div className="flex-0 w-3/5 ml-10 relative rounded-md bg-white">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        value={calculateAmount(amount, cadUsdRate, fee)}
                        readOnly
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className=" block flex w-full pl-7 pr-14 text-right text-2xl border-0 rounded-md focus:ring-0 outline-none bg-transparent"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency"
                        >
                          CAD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-beginning">
            <button
              onClick={e => {
                handleSubmit()
                e.preventDefault()
              }}
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              {token ? 'Finish Withdrawal' : 'Next'}
            </button>
          </div>
        </div>
      </form>
      <PaytrieAuthModal
        open={showModal}
        setOpen={setShowModal}
        time={time}
        email={email}
      />
      <PendingTransactionModal
        open={showTxStatusModal}
        setOpen={setTxStatusModalOpen}
        successBody={successBody}
        successTitle={successTitle}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
      />
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        notificationBody={notificationBody}
        notificationTitle={notificationTitle}
        isSuccessful={isSuccessfulNotification}
      />
    </main>
  )
}

export default FiatWithdrawal
