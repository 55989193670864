export default function RegistrationSuccessful(props) {
  return (
    <div className="min-h-screen flex mt-10 items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Registration Successful
          </h2>

          <p className="text-lg font-light text-gray-500 text-center mt-8">
            Please check your email to confirm your account.
          </p>
        </div>
      </div>
    </div>
  )
}
