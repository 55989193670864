import React, { useState } from 'react'

export default function SwapsTokenSearch({ tokenList, setResults }) {
  const [active, setActive] = useState(0)
  const [filtered, setFiltered] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [input, setInput] = useState('')

  const onChange = e => {
    setInput(e.target.value)
    const newFilteredSuggestions = tokenList.filter(token => {
      const regex = new RegExp(`^${e.target.value}`, 'gi')
      return (
        token.symbol.match(regex) ||
        token.name.match(regex) ||
        token.address === e.target.value
      )
    })
    //setActive(0);
    if (e.target.value === '') {
      setResults(tokenList)
    } else {
      setResults(newFilteredSuggestions)
    }
  }

  const onClick = e => {
    setActive(0)
    setFiltered([])
    setIsShow(false)
    setInput(e.currentTarget.innerText)
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0)
      setIsShow(false)
      setInput(filtered[active])
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setActive(active - 1)
    } else if (e.keyCode === 40) {
      // down arrow
      return active - 1 === filtered.length ? null : setActive(active + 1)
    }
  }

  const renderAutocomplete = () => {
    if (isShow && input) {
      if (filtered && filtered.length) {
        return (
          <ul className="autocomplete">
            {filtered.map((token, index) => {
              let className
              if (index === active) {
                className = 'active'
              }
              return (
                <li className={className} key={token.symbol} onClick={onClick}>
                  {token.name}
                </li>
              )
            })}
          </ul>
        )
      } else {
        return (
          <div className="no-autocomplete">
            <em>Not found</em>
          </div>
        )
      }
    }
    return <></>
  }

  return (
    <>
      <input
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
        name="token"
        id="token"
        className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block max-w-lg w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="Search by name or contract address"
      />
      {renderAutocomplete()}
    </>
  )
}
