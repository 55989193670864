import { useState } from 'react'
import MinimalNotification from '../components/MinimalNotification'
import { ClipboardCopyIcon } from '@heroicons/react/solid'

const WalletAddress = () => {
  let safeAddress = sessionStorage.getItem('safeAddress')

  const [show, setShow] = useState(false)
  const [notificationText, setNotificationText] = useState()

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(safeAddress)
    setNotificationText('Copied to clipboard')
    setShow(true)
    setTimeout(() => setShow(false), 3000)
  }

  return (
    <>
      <MinimalNotification
        text={notificationText}
        show={show}
        setShow={setShow}
      />
      <button
        className="inline-flex items-center text-gray-600 hover:text-gray-400 font-bold"
        onClick={handleCopyToClipboard}
      >
        {safeAddress}
        <ClipboardCopyIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>
  )
}

export default WalletAddress
