import { useState, useEffect } from 'react'
import { getSafeAddress, currencyFormat } from '../utils/'
import { getUSDCBalance, getvimUSDBalance } from '../forge/index'
import { useAccountSigner } from '../hooks/useAccountSigner'

import NumberBox from '../components/NumberBox'
import SaveDeposit from '../components/SaveDeposit'
import SaveWithdraw from '../components/SaveWithdraw'
import LineChart from '../components/LineChart'
import SectionHeading from '../components/SectionHeading'
import Spinner from '../components/Spinner'

import {
  getSaveReturns,
  getSaveBalance,
  getBalances,
  getSupplyAPR,
  getExchangeRate,
  today
} from '../utils/save'

const Save = () => {
  const [assetBalance, setAssetBalance] = useState(0)
  const [tokenBalance, setTokenBalance] = useState(null)
  const [vimUSDBalance, setVimUSDBalance] = useState()
  const [exchangeRate, setExchangeRate] = useState(1)
  const [balances, setBalances] = useState([])
  const [mtaBalance, setMtaBalance] = useState()
  const [currentBalance, setCurrentBalance] = useState(0)
  const [returns, setReturns] = useState(0)
  const [apr, setAPR] = useState(0)

  const [loading, setLoading] = useState(true)

  let safeAddress = getSafeAddress()

  useEffect(() => {
    Promise.all([
      getUSDCBalance(safeAddress),
      getvimUSDBalance(safeAddress),
      getExchangeRate(),
      getSupplyAPR(),
      getBalances(),
      getSaveBalance()
    ])
      .then(
        ([
          assetBalance,
          vimUSDBalance,
          exchangeRate,
          supplyAPR,
          { data: balances },
          currentBalance
        ]) => {
          let depositValue =
            parseFloat(vimUSDBalance) * parseFloat(exchangeRate)

          setVimUSDBalance(vimUSDBalance)
          setExchangeRate(exchangeRate)
          setAssetBalance(assetBalance)
          setTokenBalance(depositValue)
          setAPR(parseFloat(supplyAPR).toFixed(2))
          setBalances([...balances, { amount: depositValue, date: today() }])
          setCurrentBalance(currentBalance.balance)
          setMtaBalance(currentBalance.mta_balance)

          return { currentBalance: currentBalance.balance }
        }
      )
      .then(({ currentBalance }) => getSaveReturns())
      .then(({ return: saveReturn }) => setReturns(saveReturn))
      .then(() => setLoading(false))
  }, [safeAddress])

  if (loading) return <Spinner />

  return (
    <section>
      <SectionHeading
        heading="Save"
        subheading="Summary of your data in last month"
      />
      <div className="mt-10 flex flex-col md:flex-row justify-between">
        <div className="w-full flex flex-col md:flex-row justify-between md:items-center border-b border-gray-200">
          <div className="flex flex-col md:flex-row">
            <NumberBox
              className="mt-4 md:mt-0 border-b md:border-b-0 md:border-r md:pr-10 pb-5 border-gray-200"
              title="Deposit value"
              number={currencyFormat(currentBalance, 2)}
              bottomText="USDC"
            />
            <NumberBox
              className="mt-4 md:mt-0 border-b md:border-b-0 md:border-r md:px-10 pb-5 border-gray-200"
              title="Saving APR"
              number={`${apr}%`}
              bottomText=""
            />
            <NumberBox
              className="mt-4 md:mt-0 md:pl-10 pb-5 md:border-r md:px-10"
              title="Earnings to date"
              number={currencyFormat(returns, 2)}
              bottomText="USDC"
            />
          </div>
          <div className="pb-5">
            <SaveWithdraw
              availableBalance={tokenBalance}
              exchangeRate={exchangeRate}
              vimUSDBalance={vimUSDBalance}
              mtaBalance={mtaBalance}
            />
          </div>
        </div>
      </div>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4">
        <SaveDeposit availableBalance={assetBalance} />

        <div className="md:col-span-2 bg-white overflow-hidden shadow rounded-lg">
          <div className="py-5 sm:p-6">
            <h2 className="pl-5 font-bold text-xl">{`${apr}%`}</h2>
            <h3 className="pl-5 mt-2 font-normal text-sm opacity-70">APR</h3>
            <LineChart
              label=""
              dates={balances.map(({ date }) => date)}
              values={balances.map(({ amount }) => amount)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Save
