import React from 'react'
import InlineWalletAddress from '../components/InlineWalletAddress'

import moonpayImg0 from '../images/moonpay_walkthrough_0'
import moonpayImg1 from '../images/moonpay_walkthrough_1'
import moonpayImg2 from '../images/moonpay_walkthrough_2'
import moonpayImg3 from '../images/moonpay_walkthrough_3'
import moonpayImg4 from '../images/moonpay_walkthrough_4'
import moonpayImg5 from '../images/moonpay_walkthrough_5'

const MoonpayWalkthrough = () => (
  <>
    <h3 className="mt-10 leading-6 text-3xl">
      How to fund your Zyield account wallet with Moonpay
    </h3>

    <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-md">
      <ol className="divide-y divide-gray-200">
        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 1.</b> Go to{' '}
            <a
              className="underline font-bold"
              target="_blank"
              rel="noreferrer"
              href="https://www.moonpay.com/buy"
            >
              https://www.moonpay.com/buy
            </a>{' '}
            to begin the funding process.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" alt="Moonpay" src={moonpayImg0} />
          </figure>
          <p>
            <b>
              Make sure you select USDC - USD Coin (Polygon) in the Currency
              field. This is really important!
            </b>
          </p>
          <p>
            Tip: For your first transaction we recommend first starting with a
            small amount between $20-$100 CAD/USD/EUR, etc. to reduce the risk
            of losing your funds, in case you make any mistakes and to vet the
            funding process end-to-end.
          </p>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 2.</b> In the following screen, enter your Zyield
            Wallet/Account address. Make sure you double check when entering the
            address, before continuing. Entering the wrong address will lead to
            loss of funds.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" alt="Moonpay" src={moonpayImg1} />
          </figure>
          <p>
            Your wallet address is: <InlineWalletAddress />
          </p>
          <p>
            Also, you can easily find your Zyield wallet address by{' '}
            <b>
              going to Settings in the right-top corner of your account and
              copying the address string.
            </b>
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" src={moonpayImg2} alt="Moonpay" />
          </figure>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 3.</b> Continue your transaction and follow the instructions
            on the Moonpay screen for email confirmation and account creation.
            You will need to go through a quick KYC (ID verification) process to
            finalize your transaction. This is required by law in most
            jurisdictions.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" src={moonpayImg3} alt="Moonpay" />
          </figure>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 4.</b> Following email confirmation and account creation
            with Moonpay you will arrive at the payment screen.
            <br />
            Select a payment method of your choice from the drop-down and ensure
            the <b>Sending To</b> wallet address is the correct one, matching
            your Zyield account wallet.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" src={moonpayImg4} alt="Moonpay" />
          </figure>
        </li>

        <li className="px-4 py-4 sm:px-6">
          <p>
            <b>Step 5.</b> You should now be able to see your funds in your
            Zyield account wallet.
          </p>
          <figure className="py-5">
            <img className="w-full md:w-2/3" src={moonpayImg5} alt="Moonpay" />
          </figure>
        </li>
      </ol>
    </div>
  </>
)

export default MoonpayWalkthrough
