import React from 'react'
import Uploads from '../../components/Uploads'

const DocumentUploads = ({
  setPassportImage,
  setSelfieImage,
  setUtilityImage,
  setDocumentNumber,
  setDocumentType,
  setIDNumber,
  setIDType,
  handleDocumentUpload
}) => {
  return (
    <div>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Upload documents for manual verification
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Three documents are required
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-gray-300 sm:pt-5 border-b pb-4">
              <label
                htmlFor="username"
                className="block sm:text-sm text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 mb-4 sm:mb-0"
              >
                ID Document
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Document Type
                </label>
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px mb-4"
                >
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    onChange={e => setIDType(e.target.value)}
                    className="focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    <option value="canadian passport">Canadian Passport</option>
                    <option value="non canadian passport">
                      Non-Canadian Passport
                    </option>
                    <option value="provincial license">
                      Provincial Driver's License
                    </option>
                    <option value="provincial id">Provincial ID Card</option>
                  </select>
                </label>

                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  ID Number
                </label>
                <input
                  type="text"
                  name="id_number"
                  id="id_number"
                  onChange={e => setIDNumber(e.target.value)}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-2/3 sm:text-sm border-gray-300 rounded-md mb-4"
                />

                <Uploads setBase64={setPassportImage} />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:pt-5 border-gray-300 border-b pb-4">
              <label
                htmlFor="photo"
                className="block sm:text-sm text-lg font-medium text-gray-700 sm:mb-0 mb-4"
              >
                Bill or financial <br /> statement
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Document Type
                </label>
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 mr-4 mb-4"
                >
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    onChange={e => setDocumentType(e.target.value)}
                    className="max-w-lg block focus:ring-orange-500 focus:border-orange-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    <optgroup label="Name & Financial">
                      <option value="creditCardStatement">
                        Credit Card Statement
                      </option>
                      <option value="bankStatement">Bank Statement</option>
                      <option value="mortgageStatement">
                        Mortgage Statement
                      </option>
                    </optgroup>
                    <optgroup label="Name & Address">
                      <option value="utility">Utility Bill</option>
                      <option value="phonebill">Phone Bill</option>
                      <option value="propertyTax">Property Tax</option>
                      <option value="noa">Notice of Assessment</option>
                      <option value="t4Statement">T4 Statement</option>
                    </optgroup>
                    <optgroup label="Name & DOB">
                      <option value="birthCertificate">
                        Original Birth Certificate
                      </option>
                      <option value="marriageCertificate">
                        Marriage Certificate
                      </option>
                      <option value="permanentResidentCard">
                        Permanent Resident Card
                      </option>
                      <option value="insuranceDocument">
                        Insurance Document
                      </option>
                    </optgroup>
                  </select>
                </label>

                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Document Number
                </label>
                <input
                  type="text"
                  name="document_number"
                  id="document_number"
                  onChange={e => setDocumentNumber(e.target.value)}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-2/3 sm:text-sm border-gray-300 rounded-md mb-4"
                />

                <Uploads setBase64={setUtilityImage} />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start pb-4 sm:pt-5">
              <label
                htmlFor="about"
                className="block sm:text-sm text-lg font-medium text-gray-700 sm:mt-px sm:pt-2 sm:mb-0 mb-4"
              >
                Selfie
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Uploads setBase64={setSelfieImage} />
                <p className="mt-2 text-sm text-gray-500">
                  Selfie with passport or driver's license and a note with
                  "PayTrie" and the date on it
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end mt-5">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleDocumentUpload}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default DocumentUploads
