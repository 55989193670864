import { useState, useEffect, useContext } from 'react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { ethers } from 'ethers'

import { safeSignTypedData } from '../utils/safe_utils'
import PendingTransactionModal from './PendingTransactionModal'
import Spinner from './Spinner'
import WebsocketContext from '../context/WebsocketContext'

import { getBalance } from '../forge/'
import SwapsTokenSelect from './SwapsTokensSelect'

import TwoFactorModal from './TwoFactorModal'

import { request, getSafeAddress, getTokenList } from '../utils/'

import { createTransactionsJob, payloadToSign } from '../utils/transactions'

import useAccountSigner from '../hooks/useAccountSigner'

const tokens = getTokenList()

const getSwapTx = (
  amount,
  from_token,
  from_token_decimals,
  to_token,
  safe_address,
  slippage,
  protocol
) =>
  request(
    `/api/transactions/new?type=swap&amount=${amount}&from_token=${from_token}&to_token=${to_token}&safe_address=${safe_address}&slippage=${slippage}&protocol=${protocol}&from_token_decimals=${from_token_decimals}`
  ).then(res => res.json())

const modesMachine = {
  init: 'buy',
  buy: 'sell',
  sell: 'buy'
}

export default function SwapsWidget({ predefined, to, from }) {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const wsProvider = useContext(WebsocketContext)
  const [open, setOpen] = useState(false)
  const [openTxModal, setOpenTxModal] = useState(false)
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [amountIn, setAmountIn] = useState(0)
  const [amountOut, setAmountOut] = useState(0)
  const [exact, setExact] = useState()
  const [mode, setMode] = useState(modesMachine.init)
  const [loading, setLoading] = useState(false)
  const [transaction, setTransaction] = useState()

  const [confirmationCode, setConfirmationCode] = useState()

  const [txHash, setTxHash] = useState()
  const [txType, setTxType] = useState()

  const [fromToken, setFromToken] = useState({
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    symbol: 'USDC',
    decimals: 6,
    imageURL: 'https://polygonscan.com/token/images/centre-usdc_32.png'
  })

  const [direction, setDirection] = useState()

  const [tokenList, setTokenList] = useState([])
  const [slippage, setSlippage] = useState(2.0)
  const [insufficientLiquidity, setInsufficientLiquidity] = useState()
  const [fromTokenBalance, setFromTokenBalance] = useState()
  const [toToken, setToToken] = useState()
  const [timeout, setTimeoutId] = useState(null)
  const [wrong2FACode, setWrong2FACode] = useState()

  let protocol = '1inch'
  let signer = useAccountSigner()
  let safe_address = getSafeAddress()

  const reSendCode = async () => {
    setWrong2FACode(false)
    send2FACode()
  }

  const send2FACode = async () => {
    setLoading(true)

    request(`/api/users/send_2fa_code`, {
      method: 'POST',
      body: ''
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
      })
  }

  const withErrorHandling = async fn => {
    setLoading(true)

    try {
      await fn()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let defaultToToken = to
      ? tokenList.find(({ symbol }) => symbol.toLowerCase() === to)
      : null

    setToToken(defaultToToken)
  }, [to, tokenList])

  useEffect(() => {
    async function fetch_balance() {
      if (fromToken) {
        let balance = await getBalance({
          address: safe_address,
          token_address: fromToken.address,
          decimals: fromToken.decimals
        })
        setFromTokenBalance(balance)
      }
    }

    fetch_balance()
  }, [])

  const handleFromTokenSelect = async token => {
    setAmountIn(0)
    setInsufficientLiquidity(false)
    setFromToken(token)
    getTrades().catch(console.error)
    if (token) {
      let balance = await getBalance({
        address: safe_address,
        token_address: token.address,
        decimals: token.decimals
      })
      setFromTokenBalance(balance)
    } else {
      setFromTokenBalance()
    }
  }

  const handleToTokenSelect = async token => {
    setAmountIn(0)
    setInsufficientLiquidity(false)
    setToToken(token)
    getTrades().catch(console.error)
  }

  const handleFlip = () => {
    setMode(modesMachine[mode])

    let fromTokenTmp = fromToken
    let toTokenTmp = toToken

    handleFromTokenSelect(toTokenTmp)
    handleToTokenSelect(fromTokenTmp)
  }

  const getTrades = async () => {
    if (
      fromToken &&
      toToken &&
      (amountIn || amountOut) &&
      (amountIn > 0 || amountOut > 0) &&
      !isNaN(amountIn) &&
      !isNaN(amountOut)
    ) {
      setInsufficientLiquidity(false)
      let amountInFormatted = ethers.utils.parseUnits(
        `${amountIn}`,
        fromToken.decimals
      )

      request(
        `/api/protocols/1inch/swap_quote?amount=${amountInFormatted.toString()}&from_token=${
          fromToken.address
        }&to_token=${toToken.address}&from_token_decimals=${fromToken.decimals}`
      )
        .then(res => res.json())
        .then(({ toTokenAmount, fromTokenAmount, status }) => {
          if (status === 'insufficient liquidity') {
            setInsufficientLiquidity(true)
          } else {
            let formattedAmountOut = ethers.utils.formatUnits(
              toTokenAmount,
              toToken.decimals
            )

            setAmountOut(parseFloat(formattedAmountOut).toFixed(4))
          }
        })
    }
  }

  const makeSwap = async () => {
    let amountInFormatted = ethers.utils.parseUnits(
      amountIn,
      fromToken.decimals
    )

    let tx

    if (confirmationCode) {
      tx = transaction
    } else {
      tx = await getSwapTx(
        amountInFormatted,
        fromToken.address,
        fromToken.decimals,
        toToken.address,
        safe_address,
        slippage,
        protocol
      )

      setTxType(tx.type)
      setTransaction(tx)
    }

    if (tx.type === 'multi_sig' && !confirmationCode) {
      setOpenTwoFactorModal(true)
    } else {
      let payload = payloadToSign(tx)

      let signature = await safeSignTypedData(
        signer,
        { address: safe_address },
        payload
      )

      tx = { ...tx, signatures: [signature] }
      let response = await createTransactionsJob({
        tx,
        confirmation_code: confirmationCode
      })

      if (response.error) {
        setOpenTxModal(false)
        setOpenTwoFactorModal(true)
        setWrong2FACode(true)
      } else {
        setTxHash(response)
        setOpenTxModal(true)
      }
    }
  }

  useEffect(() => {
    let filteredTokens = tokens.filter(t => t.chainId === parseInt(chainId))
    setTokenList(filteredTokens)
  }, [tokens])

  useEffect(() => {
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        setLoading(true)
        getTrades().catch(console.error)
        setLoading(false)
      }, 300)
    )
  }, [amountIn, fromToken, toToken])

  const handleFromToken = e => {
    e.preventDefault()
    if (predefined) return

    setDirection('from')
    setOpen(true)
  }

  const handleToToken = e => {
    e.preventDefault()
    if (predefined) return

    setDirection('to')
    setOpen(true)
  }

  const renderButton = () => {
    if (loading) return <Spinner />

    if (amountIn > fromTokenBalance)
      return (
        <button
          type="button"
          className="mt-6 inline-flex justify-center items-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 text-gray-700 cursor-not-allowed"
        >
          Insufficient balance
        </button>
      )

    if (insufficientLiquidity)
      return (
        <button
          type="button"
          className="mt-6 inline-flex justify-center items-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 text-gray-700 cursor-not-allowed"
        >
          Insufficient liquidity
        </button>
      )

    if (amountIn <= 0 || amountOut <= 0)
      return (
        <button
          type="button"
          className="mt-6 inline-flex justify-center items-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 text-gray-700 cursor-not-allowed"
        >
          Swap
        </button>
      )

    return (
      <button
        type="button"
        onClick={() => withErrorHandling(makeSwap)}
        className="mt-6 inline-flex justify-center items-center w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
      >
        Swap
      </button>
    )
  }

  if (!tokenList) return <Spinner />

  return (
    <div className="max-w-md rounded-lg p-6 shadow-lg bg-white ">
      <div className="flex">
        <button
          type="button"
          onClick={handleFlip}
          className={`py-1 flex-1 rounded-xl ${
            mode === 'buy' && 'bg-gray-100'
          }`}
        >
          Buy With
        </button>
        <button
          type="button"
          onClick={handleFlip}
          className={`py-1 flex-1 rounded-xl ${
            mode === 'sell' && 'bg-gray-100'
          }`}
        >
          Sell
        </button>
      </div>
      <div className="mt-6 py-1">
        <h3 className="uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          {mode === 'buy' ? 'Buy with' : 'Sell'}
        </h3>
        <div className="flex flex-row justify-between">
          <div className="mt-3 border-b-2 pb-2 flex flex-row relative justify-between flex-1 mr-2">
            <button className="flex flex-row items-center">
              {fromToken && fromToken.imageURL && (
                <img
                  src={fromToken.imageURL}
                  className="hidden sm:block h-6 w-6"
                  alt="Token"
                />
              )}
              <span className="ml-2 mr-1" onClick={handleFromToken}>
                {fromToken ? fromToken.symbol : 'Select'}
              </span>
              {!predefined && <ChevronDownIcon className="h-4 w-4" />}
            </button>

            <input
              type="numeric"
              value={amountIn}
              placeholder="0.0"
              onChange={e => {
                setExact('in')
                setAmountIn(e.target.value)
              }}
              className="focus:outline-none flex text-right"
            />
          </div>
          <button
            className="w-8 text-sm opacity-80"
            onClick={() => setAmountIn(fromTokenBalance)}
          >
            max
          </button>
        </div>
      </div>

      {/*<div className="">
        <SwitchVerticalIcon
          onClick={() => handleFlip()}
          className="h-8 w-8 text-gray-500"
          aria-hidden="true"
        />
      </div> */}

      <div className="mt-6">
        <h3 className="uppercase tracking-widest opacity-50 text-gray-900 text-sm">
          Receive
        </h3>
        <div className="flex flex-row justify-between">
          <div className="mt-3 mr-10 border-b-2 pb-2 flex flex-row relative justify-between flex-1">
            <button
              type="button"
              className="flex flex-row items-center"
              onClick={handleToToken}
            >
              {toToken && toToken.imageURL && (
                <img
                  src={toToken.imageURL}
                  className="hidden sm:block h-6 w-6"
                  alt="Token"
                />
              )}
              <span className="ml-2 mr-1">
                {toToken ? toToken.symbol : 'Select'}
              </span>
              {!predefined && <ChevronDownIcon className="h-4 w-4" />}
            </button>

            <input
              type="numeric"
              value={amountOut}
              placeholder="0.0"
              onChange={e => {
                setExact('out')
                setAmountOut(e.target.value)
              }}
              className="focus:outline-none flex text-right"
            />
          </div>
        </div>
      </div>
      <SwapsTokenSelect
        open={open}
        tokenList={tokenList}
        setOpen={setOpen}
        setToken={
          direction === 'from' ? handleFromTokenSelect : handleToTokenSelect
        }
      />
      {renderButton()}
      <PendingTransactionModal
        open={openTxModal}
        setOpen={setOpenTxModal}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
        txType={txType}
      />
      <TwoFactorModal
        open={openTwoFactorModal}
        setOpen={setOpenTwoFactorModal}
        setConfirmationCode={setConfirmationCode}
        confirmationCode={confirmationCode}
        withErrorHandling={withErrorHandling}
        executeTx={makeSwap}
        wrong2FACode={wrong2FACode}
        reSendCode={reSendCode}
        loading={loading}
      />
    </div>
  )
}
