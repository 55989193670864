import Banner from 'react-cookie-banner'

const CookieBanner = props => {
  const initConsent = console.log
  return (
    <Banner
      className="cookie-banner"
      dismissOnScroll={false}
      message='We use cookies to improve your experience. By clicking "Agree" you declare your consent'
      onAccept={() => {}}
      buttonMessage="Agree"
    />
  )
}
export default CookieBanner
