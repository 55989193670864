import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import {
  BadgeCheckIcon,
  ExclamationCircleIcon,
  XIcon
} from '@heroicons/react/outline'
import Spinner from './Spinner'
import { request } from '../utils'

const isReverted = ({ hash }) =>
  request(`/api/transactions/is_reverted?hash=${hash}`).then(res => res.json())

function LoadingState({ txHash }) {
  return (
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10">
        <Spinner />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Transaction pending
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Your transaction was successfully submitted to the network. Feel
            free to close this, you can always check the status of the
            transaction in your{' '}
            <Link
              to="/activity"
              className="text-orange-500 hover:text-orange-600"
            >
              Activity
            </Link>{' '}
            page.
          </p>
        </div>
      </div>
    </div>
  )
}

function SuccessBody({ successBody }) {
  if (successBody) {
    return (
      <>
        <p className="text-normal text-gray-500">{successBody}</p>
      </>
    )
  } else {
    return (
      <p className="text-normal text-gray-500">
        Your transaction was mined.{' '}
        <Link className="text-orange-500 hover:text-orange-600" to="/dashboard">
          Dashboard
        </Link>
      </p>
    )
  }
}

function SuccessState({ successBody, successTitle }) {
  return (
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
        <BadgeCheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          {successTitle ? successTitle : 'Transaction successful'}
        </Dialog.Title>
        <div className="mt-2">
          <SuccessBody successBody={successBody} />
        </div>
      </div>
    </div>
  )
}

function ErrorState() {
  return (
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-500"
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Transaction failed
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-normal text-gray-500">
            There was an error executing your transaction, please try again.
          </p>
        </div>
      </div>
    </div>
  )
}

function ModalContent({
  txHash,
  successBody,
  successTitle,
  spinner,
  isMined,
  error
}) {
  if (spinner) return <LoadingState txHash={txHash} />

  if (error) return <ErrorState />

  return <SuccessState successBody={successBody} successTitle={successTitle} />
}

export default function PendingTransactionModal({
  open,
  setOpen,
  txHash,
  setTxHash,
  wsProvider,
  successTitle,
  successBody,
  callback
}) {
  const [spinner, setSpinner] = useState(true)
  const [error, setError] = useState()
  const [isMined, setIsMined] = useState(false)

  if (txHash) {
    wsProvider.once(txHash, transaction => {
      let { status, confirmations, transactionHash } = transaction

      if (status === 0) {
        setError(true)
        setIsMined(true)
        setSpinner(false)
      } else if (status === 1 && confirmations > 0) {
        setIsMined(true)
      }
    })
  }

  useEffect(() => {
    async function check_if_reverted() {
      let { is_reverted } = await isReverted({ hash: txHash })
      if (is_reverted) {
        setError(true)
      } else {
        if (callback) {
          callback()
        }
      }

      setSpinner(false)
    }

    if (isMined) {
      check_if_reverted()
    }
  }, [isMined])

  const handleOnClose = () => {
    setOpen(false)
    window.location.reload(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={handleOnClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <ModalContent
                txHash={txHash}
                successBody={successBody}
                successTitle={successTitle}
                spinner={spinner}
                isMined={isMined}
                error={error}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
