import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function InsuranceExplainer({ open, setOpen }) {
  open = Boolean(open) // Transition requires explicit true/false
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Deposit Covers
                  </Dialog.Title>
                  <div className="mb-5 mt-6 pb-5">
                    <p className="text-sm text-gray-800">
                      We offer Zyield Save (powered by mStable) protocol deposit
                      cover through Nexus Mutual, a decentralised alternative to
                      traditional insurance. The cover protects against a hack
                      on the mStable protocol, where the Zyield Save funds are
                      deposited for yield.
                    </p>
                    <p className="mt-2 text-sm text-gray-800">
                      The risks covered are: code being used in an unintended
                      way, economic design failure, severe oracle failure
                      governance Attacks, protection for assets on Layer 2
                      solutions, protection for non-Ethereum smart contracts,
                      protection for a protocol across multiple chains.
                    </p>
                    <p className="text-sm font-semibold hover:opacity-70 mt-5 sm:mt-6">
                      <a
                        href="https://nexusmutual.gitbook.io/docs/welcome/faq"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        For more info. please visit
                        https://nexusmutual.gitbook.io/docs/welcome/faq
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
