import React from 'react'
import SwapsWidget from '../components/SwapsWidget'
import SectionHeading from '../components/SectionHeading'

export default function Swaps() {
  return (
    <section>
      <SectionHeading heading="Trade" subheading="Buy or sell tokens" />
      <div className="pt-8 h-full">
        <SwapsWidget />
      </div>
    </section>
  )
}
