import logo from '../images/logo.svg'
import mailImg from '../images/mail.svg'
import twitterImg from '../images/twitter.svg'

export const MinimalFooter = () => (
  <div className="minimal-footer mt-20 pt-20 sm:pt-5 pb-10 inset-x-0">
    <div className="sm:px-16 text-center flex flex-col md:flex-row justify-between items-center text-copy-800">
      <ul className="flex justify-around">
        <li>
          <a href="/terms-of-use" className="hover:underline">
            Terms and Conditions
          </a>
        </li>
        <li className="mx-5">
          <a href="/privacy" className="hover:underline">
            Privacy Policy
          </a>
        </li>
      </ul>
      <p className="inset-x-0 text-sm bottom-0 love">
        Made with ❤️ in Toronto, Canada
      </p>
      <ul className="flex justify-around">
        <li className="flex-1">
          <a target="_blank" rel="noreferrer" href="mailto:hello@zyield.fi">
            <img
              className="max-w-md"
              width="21"
              height="18"
              src={mailImg}
              alt="mail icon"
            />
          </a>
        </li>
        <li className="ml-4 flex-1">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/zyieldfinance"
          >
            <img
              className="max-w-md"
              width="21"
              height="18"
              src={twitterImg}
              alt="twitter icon"
            />
          </a>
        </li>
      </ul>
      <p>{new Date().getUTCFullYear()} Zyield Finance Inc.</p>
    </div>
  </div>
)

const Footer = () => (
  <footer className="relative mt-20 py-16">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3>
        <span className="sr-only">ZYield</span>
        <img className="mx-auto h-16" src={logo} alt="ZYield" />
      </h3>
      <p className="mt-5 md:w-1/2 px-1 font-400 mx-auto text-copy-800 text-base">
        <b>Disclaimer:</b> Zyield Finance is a non-custodial DeFi interface and
        smart wallet. We do not have the ability to access or control user funds
        and users are responsible for keeping their account/wallet credentials
        in a safe place.
        <br />
        We are not a financial institution and stablecoins or “digital dollars”
        are not legal tender at present and should be treated as any other
        cryptocurrency.
      </p>
      <div className="mt-20 pt-10 sm:px-16 flex flex-col md:flex-row justify-between items-center text-copy-800">
        <ul className="flex justify-around">
          <li>
            <a href="/terms-of-use" className="hover:underline">
              Terms and Conditions
            </a>
          </li>
          <li className="mx-5">
            <a href="/privacy" className="hover:underline">
              Privacy Policy
            </a>
          </li>
        </ul>
        <p className="absolute inset-x-0 text-sm bottom-0 love">
          Made with ❤️ in Toronto, Canada
        </p>
        <div className="flex mt-5 md:mt-0 flex-col md:flex-row justify-between items-center">
          <ul className="flex justify-around">
            {/* <li>
              <a
                target="_blank"
                rel="noopener"
                href="https://discord.gg/X4RaJGSQ62"
              >
                <img src={discordImg} alt="discord icon" />
              </a>
              </li> */}
            <li className="mx-5 flex-1">
              <a target="_blank" rel="noreferrer" href="mailto:hello@zyield.fi">
                <img
                  className="max-w-md"
                  width="21"
                  height="18"
                  src={mailImg}
                  alt="mail icon"
                />
              </a>
            </li>
            <li className="flex-1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/zyieldfinance"
              >
                <img
                  className="max-w-md"
                  width="21"
                  height="18"
                  src={twitterImg}
                  alt="twitter icon"
                />
              </a>
            </li>
          </ul>
          <p className="ml-10 mt-10 md:mt-0">
            {new Date().getUTCFullYear()} Zyield Finance Inc.
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
