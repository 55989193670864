const baseUrl = 'https://pro-api.coingecko.com/api/v3'
const CoinGeckoKey = process.env.REACT_APP_COINGECKO_KEY

const allCoinsURL = `${baseUrl}/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&&sparkline=false`

const apiKeyParam = `x_cg_pro_api_key=${CoinGeckoKey}`

export const getCoinsPage = (page = 1) =>
  fetch(`${allCoinsURL}&page=${page}&${apiKeyParam}`).then(res => res.json())

export async function getAllCoins(total = [], page = 1) {
  if (page >= 10) return total // lol no more than 10 pages (2500 coins)

  let moar = await getCoinsPage(page)
  return moar.length ? getAllCoins([...total, ...moar], ++page) : total
}
