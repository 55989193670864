import React from 'react'

const AuthLayoutMinimal = ({ children }) => {
  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">{children}</div>
    </div>
  )
}

export default AuthLayoutMinimal
