import { ethers } from 'ethers'
import { useAccount, useSigner } from 'wagmi'

const useAccountSigner = () => {
  const { data: accountData } = useAccount({
    fetchEns: true
  })

  const getProvider = () => {
    let url = process.env.REACT_APP_ALCHEMY_URL
    let provider = new ethers.providers.JsonRpcProvider(url)

    return provider
  }

  let signer

  const { data: wagmiSigner, isError, isLoading } = useSigner()

  let pk = sessionStorage.getItem('pk')

  if (pk) {
    let pk = sessionStorage.getItem('pk')
    let provider = getProvider()
    signer = new ethers.Wallet(pk, provider)
  } else {
    if (!wagmiSigner) return null
    signer = wagmiSigner
  }

  return signer
}

export default useAccountSigner
