import React from 'react'
import Header from '../partials/Header'

const MainLayout = ({ children, ...props }) => (
  <div style={{ backgroundColor: 'rgba(250, 240, 245, .5)' }}>
    <Header {...props} />
    {children}
  </div>
)

export default MainLayout
