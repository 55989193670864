import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { UploadIcon } from '@heroicons/react/solid'

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}

export default function Uploads({ setBase64, text }) {
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/pdf'],
    maxFiles: 1,
    multiple: false,
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const binaryStr = reader.result
          setBase64({
            type: file.type,
            url: binaryStr,
            thumbUrl: binaryStr,
            status: 'done',
            uid: 1,
            name: file.name
          })
        }
      })

      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    }
  })

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="Preview" />
      </div>
    </div>
  ))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return (
    <div {...getRootProps({ className: 'flex' })}>
      <div className="space-y-1 text-center mr-4">
        <div className="flex text-sm text-gray-600">
          <button
            type="button"
            className="inline-flex items-center px-3 py-1 border border-transparent shadow-sm text-sm font-normal rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            <UploadIcon className="-ml-1 mr-2 h-3 w-3" aria-hidden="true" />
            Upload document
          </button>
          <input {...getInputProps({})} />
        </div>
      </div>
      <aside className="block">{thumbs}</aside>
    </div>
  )
}
