import Hero from '../partials/Hero'
import Features from '../partials/Features'
import CTA from '../partials/CTA'
import Testimonials from '../components/Testimonials'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'
import CookieBanner from '../components/CookieBanner'
import saveChart from '../images/save_chart.png'
import investBoxes from '../images/invest_boxes.png'

import payWithImg from '../images/pay_with.png'
import receiveImg from '../images/receive.png'
import swapIconImg from '../images/swap_icon.png'

import gnosisSafeImg from '../images/gnosis.svg'
import avaxImg from '../images/avax.svg'
import nexusImg from '../images/nexusmutual.png'
import polygonImg from '../images/polygon-logo.svg'
import mstableImg from '../images/mstable.svg'

const SwapImage = () => (
  <div className="mt-12 order-5 relative">
    <img width="381" src={payWithImg} alt="Pay with" />
    <div className="relative inline-block lg:left-20">
      <img width="381" src={receiveImg} alt="Receive" />
      <img
        width="64"
        className="absolute"
        style={{ top: '-32px', left: 'calc(50% - 32px)' }}
        src={swapIconImg}
        alt="Swap"
      />
    </div>
  </div>
)

const NewFeatures = () => (
  <div className="relative py-16 lg:py-15">
    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      {/*<div
        className="backdrop-ellipsis"
        style={{ left: 0, right: 'auto' }}
      ></div> */}
      <div
        id="why-zyield"
        className="text-copy-800 grid grid-cols-1 gap-12 justify-center items-center sm:grid-cols-2"
      >
        <div className="max-w-xl order-0">
          <h3 className="text-4xl">Save</h3>
          <p className="opacity-70 mt-8 tracking-light text-xl leading-loose">
            Get better returns on your savings with our high interest savings
            offering. <b>Variable APR between 5% and 20+%.</b>
          </p>
        </div>

        <img
          className="mt-12 order-1"
          width="488"
          src={saveChart}
          alt="Line chart going up"
        />

        <img
          className="mt-12 order-3 sm:order-2"
          width="492"
          src={investBoxes}
          alt="Index logos (DEFI pulse, NFT Platform Index, Metaverse Index, DEGEN Index"
        />
        <div className="order-2 sm:order-3">
          <h3 className="text-4xl">Invest</h3>
          <p className="opacity-70 mt-8 tracking-light text-xl leading-loose">
            Select from a wide range of DeFi investment opportunities. DeFi
            indexes, Metavers/NFT indexes, liquidity pools, staking and farming
            or participate in no-loss lotteries.
          </p>
        </div>
        <div className="order-4">
          <h3 className="text-4xl">Swap</h3>
          <p className="opacity-70 mt-8 tracking-light text-xl leading-loose">
            Buy and sell token/coins directly from your DeFi wallet and
            diversify your crypto portfolio.
          </p>
        </div>
        <SwapImage />
      </div>
    </div>
  </div>
)

const PoweredBy = () => (
  <div className="relative mt-20 py-16 lg:py-15">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3 className="mt-2 text-xl font-500 text-copy-800 tracking-tight sm:text-2xl">
        Powered by
      </h3>
      <div className="flex flex-col md:flex-row justify-around items-center">
        {[gnosisSafeImg, polygonImg, nexusImg, avaxImg, mstableImg].map(
          (image, index) => (
            <img
              key={index}
              className="mr-2 py-10 w-1/2 md:w-1/6"
              src={image}
              alt="Logos of Paytrie, Polygon, Gnosis, and MStable"
            />
          )
        )}
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <main>
      <Hero />
      <NewFeatures />
      <Features />
      <Testimonials />
      <PoweredBy />
      <FAQ />
      {null && <CTA />}
      <Footer />
      <CookieBanner />
    </main>
  )
}
