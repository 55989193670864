import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { request } from '../../utils'

import Spinner from '../../components/Spinner'

export default function InitiateRecovery() {
  let search = useLocation().search
  let token = new URLSearchParams(search).get('token')

  let { isLoading, error } = useQuery(
    'initiateRecovery',
    () =>
      request('/api/account_recovery/initiate_recovery', {
        method: 'POST',
        body: JSON.stringify({ token: token })
      }),
    {
      onSuccess: ({ status }) => {
        if (status === 'successful') {
          isLoading(false)
        }
      }
    }
  )

  if (isLoading) return <Spinner />
  if (error) return 'An error has occurred: ' + error?.message

  return (
    <div className="min-h-screen flex mt-10 items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <p className="mt-2 text-left text-lg text-gray-600">
          We triggered your account recovery.
        </p>
        <p className="mt-0 text-left text-sm text-gray-600">
          After 48 hours pass, we will send you an email with further
          instructions.
        </p>
      </div>
    </div>
  )
}
