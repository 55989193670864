import { Fragment, useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/solid'
import {
  request,
  saveToken,
  saveAddress,
  saveSignerAddress,
  decodeAndSave,
  walletFromPasswordAndEmail,
  truncateAddress
} from '../utils'

import AuthError from '../components/AuthError'
import Spinner from '../components/Spinner'
import TwoFactorModal from '../components/TwoFactorModal'
import LoginWithWallet from '../components/LoginWithWallet'

import { Connect } from '../components/Connect'
import { Account } from '../components/Account'
import NetworkSwitcher from '../components/NetworkSwitcher'

import metamaskSVG from '../images/metamask.svg'
import {
  useAccount,
  useSignMessage,
  useSigner,
  useConnect,
  useNetwork
} from 'wagmi'

export default function Login() {
  let url = `/api/sign_in`
  let history = useHistory()
  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  let [error, setError] = useState(false)
  let [loading, setLoading] = useState(false)
  let [loadingWalletLogin, setLoadingWalletLogin] = useState(false)
  let [open, setOpen] = useState(false)
  let [confirmationCode, setConfirmationCode] = useState()
  let [wrong2FACode, setWrong2FACode] = useState()

  const { data } = useAccount()
  const { activeChain, chains, switchNetwork } = useNetwork()

  const loginWithWallet = async data => {
    setLoadingWalletLogin(true)
    let { connector, account, address } = data
    let signer = await connector.getSigner()
    let signerAddress = account ? account : address
    request(`/api/users/nonce?signer_address=${signerAddress}`)
      .then(res => res.json())
      .then(async ({ error, errors, user: { nonce } }) => {
        if (error || errors) {
          throw error || errors
        }

        let message = `Sign in to Zyield with nonce: ${nonce}`
        let signature = await signer.signMessage(message)
        let payload = {
          signer_address: signerAddress,
          signature
        }

        return request(url, {
          method: 'post',
          body: JSON.stringify(payload)
        })
      })
      .then(res => res.json())
      .then(
        async ({
          jwt,
          safe_address,
          signer_address,
          error,
          errors,
          status,
          ...response
        }) => {
          if (error || errors) {
            throw error || errors
          }

          saveToken(jwt)
          saveAddress(safe_address)
          saveSignerAddress(signer_address)

          history.push('/dashboard')
        }
      )
      .catch(error => {
        console.error(error)
        setOpen(false)
      })
      .finally(() => setLoading(false))
  }

  const submit = async e => {
    e.preventDefault()
    setError(false)
    setWrong2FACode(false)
    setLoading(true)

    request(`/api/users/nonce_and_salt?email=${email}`)
      .then(res => res.json())
      .then(async ({ error, errors, user: { nonce, salt } }) => {
        if (error || errors) {
          throw error || errors
        }

        let wallet = await walletFromPasswordAndEmail(password, email, salt)
        let signature = await wallet.signMessage(
          `Sign in to Zyield with nonce: ${nonce}`
        )

        let payload = {
          email,
          signer_address: wallet.address,
          signature
        }

        if (confirmationCode) {
          payload.confirmation_code = confirmationCode
        }

        return request(url, {
          method: 'post',
          body: JSON.stringify(payload)
        })
      })
      .then(res => res.json())
      .then(async ({ jwt, error, errors, status, ...response }) => {
        if (status === 'requires_2fa') {
          setOpen(true)
          return
        }

        if (error || errors) {
          throw error || errors
        }

        await decodeAndSave({ ...response, password, email })
        saveToken(jwt)
        localStorage.setItem('email', email)
        history.push('/dashboard')
      })
      .catch(error => {
        console.error(error)
        setOpen(false)

        if (error === 'Invalid or missing 2FA code') {
          setConfirmationCode(null)
          setWrong2FACode(true)
        } else {
          setError(true)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="min-h-screen flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <TwoFactorModal
        open={open}
        setOpen={setOpen}
        setConfirmationCode={setConfirmationCode}
        confirmationCode={confirmationCode}
        callback={submit}
        loading={loading}
        title="2FA Confirmation"
      />

      <LoginWithWallet
        loading={loadingWalletLogin}
        callback={loginWithWallet}
      />

      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              to="/register"
              className="font-medium text-orange-600 hover:text-orange-500"
            >
              Sign up
            </Link>
          </p>
        </div>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            onSubmit={submit}
            className="mt-8 space-y-6"
            action={url}
            method="POST"
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm mb-3"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm mb-3"
                />
              </div>
            </div>

            {wrong2FACode && (
              <AuthError title="Invalid 2FA code" text="Please try again." />
            )}

            {error && (
              <AuthError
                title="Invalid credentials"
                text="Please, double check your credentials and try again"
              />
            )}

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  to="/account_recovery/confirm_email"
                  className="font-medium text-orange-500 hover:text-orange-600"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              {loading ? (
                <Spinner />
              ) : (
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-white group-hover:text-white"
                      aria-hidden="true"
                    />
                  </span>
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <NetworkSwitcher />
    </div>
  )
}
