import React from 'react'
import { useState } from 'react'
import {
  ChartBarIcon,
  TrendingUpIcon,
  CurrencyDollarIcon,
  SwitchVerticalIcon,
  CreditCardIcon,
  //  QuestionMarkCircleIcon,
  InboxInIcon
} from '@heroicons/react/solid'

import DesktopSidebar from '../partials/sidebar/DesktopSidebar'
import Sidebar from '../partials/sidebar/Sidebar'
import SidebarTransition from '../partials/sidebar/SidebarTransition'
import AuthHeader from '../partials/AuthHeader'
import { MinimalFooter } from '../components/Footer'
import NetworkSwitcher from '../components/NetworkSwitcher'

import { getEmail } from '../utils'

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: ChartBarIcon },
  { name: 'Save', href: '/save', icon: CreditCardIcon },
  { name: 'Invest', href: '/invest', icon: CurrencyDollarIcon },
  { name: 'Trade', href: '/swaps', icon: SwitchVerticalIcon },
  { name: 'Activity', href: '/activity', icon: TrendingUpIcon },
  // { name: 'LegacyDashboard', href: '/legacy_dashboard', icon: ChartBarIcon },
  //  { name: 'Add Funds', href: '/add_funds', icon: InboxInIcon },
  { name: 'Fund / Withdraw', href: '/fiat_deposit', icon: InboxInIcon }
  // { name: 'Withdrawal', href: '/fiat_withdrawal', icon: CreditCardIcon }
  //  { name: 'Help', href: '/help', icon: QuestionMarkCircleIcon }
]

const AuthLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  let email = getEmail()

  return (
    <>
      <AuthHeader email={email} />
      <div className="h-screen flex overflow-auto">
        <SidebarTransition
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={navigation}
        />

        <DesktopSidebar navigation={navigation} />

        <div className="flex flex-col w-0 flex-1 overflow-auto">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main className="relative text-complimantary-800 flex-1 p-10">
            {children}
          </main>
          <MinimalFooter />
          {!email && <NetworkSwitcher />}
        </div>
      </div>
    </>
  )
}

export default AuthLayout
