import { useState, useContext } from 'react'
import { currencyFormat, request, getSigner } from '../utils'
import { safeSignTypedData } from '../utils/safe_utils'
import WebsocketContext from '../context/WebsocketContext'
import { InformationCircleIcon } from '@heroicons/react/outline'

import NumberBox from './NumberBox'
import PendingTransactionModal from './PendingTransactionModal'
import Spinner from '../components/Spinner'
import InsuranceExplainer from '../components/InsuranceExplainer'

import useAccountSigner from '../hooks/useAccountSigner'

import {
  createTransactionsJob,
  payloadToSign,
  useTransaction
} from '../utils/transactions'

import TwoFactorModal from './TwoFactorModal'
const MINIMUM_DEPOSIT = 10

const getDepositTX = ({ amount, insured }) =>
  request(
    `/api/transactions/new?type=yield&amount=${amount}&asset=USDC&protocol=mstable&insured=${insured}`
  ).then(res => res.json())

const SaveDeposit = ({ availableBalance }) => {
  const [amount, setAmount] = useState(0)
  const [open, setOpen] = useState(false)
  const [txHash, setTxHash] = useState(null)
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState()
  const [transaction, setTransaction] = useState()
  const [insured, setInsured] = useState(false)
  const [explainerOpen, toggleExplainer] = useState(false)
  const wsProvider = useContext(WebsocketContext)

  const [wrong2FACode, setWrong2FACode] = useState()
  const [loading, setLoading, withErrorHandling] = useTransaction()

  let signer = useAccountSigner()
  let safeAddress = sessionStorage.getItem('safeAddress')

  const reSendCode = async () => {
    setWrong2FACode(false)
    send2FACode()
  }

  const send2FACode = async () => {
    setLoading(true)

    request(`/api/users/send_2fa_code`, {
      method: 'POST',
      body: ''
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
      })
  }

  const amountInvalid = () => {
    let depositAmount = Number(amount)
    return (
      isNaN(depositAmount) ||
      depositAmount > availableBalance ||
      depositAmount < MINIMUM_DEPOSIT
    )
  }

  const makeDeposit = async () => {
    let depositAmount = Number(amount)

    if (amountInvalid()) return

    let tx

    if (confirmationCode) {
      tx = transaction
    } else {
      tx = await getDepositTX({ amount: depositAmount, insured })
      setTransaction(tx)
    }

    if (tx.type === 'multi_sig' && !confirmationCode) {
      setOpenTwoFactorModal(true)
    } else {
      let payload = payloadToSign(tx)

      let signature = await safeSignTypedData(
        signer,
        { address: safeAddress },
        payload
      )

      tx = { ...tx, signatures: [signature] }
      let response = await createTransactionsJob({
        tx,
        amount,
        type: 'deposit',
        confirmation_code: confirmationCode,
        insured
      })

      if (response.error) {
        setConfirmationCode(false)
        setOpen(false)
        setOpenTwoFactorModal(true)
        setWrong2FACode(true)
        return
      }

      setTxHash(response)
      setOpen(true)
    }
  }

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg self-start">
      <PendingTransactionModal
        open={open}
        setOpen={setOpen}
        txHash={txHash}
        setTxHash={setTxHash}
        wsProvider={wsProvider}
      />
      <TwoFactorModal
        open={openTwoFactorModal}
        setOpen={setOpenTwoFactorModal}
        setConfirmationCode={setConfirmationCode}
        confirmationCode={confirmationCode}
        withErrorHandling={withErrorHandling}
        wrong2FACode={wrong2FACode}
        reSendCode={reSendCode}
        loading={loading}
        executeTx={makeDeposit}
      />
      <div className="px-4 py-5 sm:p-6">
        <NumberBox
          title="Available balance"
          number={currencyFormat(availableBalance, 2)}
          bottomText="USDC"
          smaller
        />

        <div className="mt-5">
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            ENTER DEPOSIT
          </label>

          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>

            <input
              type="number"
              name="price"
              id="price"
              onFocus={() => amount <= 0 && setAmount('')}
              onBlur={() => amount <= 0 && setAmount(0)}
              onChange={e => setAmount(e.target.value)}
              className="focus:ring-orange-500 focus:border-orange-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              aria-describedby="price-currency"
            />

            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USDC
              </span>
            </div>
          </div>
        </div>

        <span className="text-gray-500 sm:text-xs" id="price-currency">
          Minimum of $10
        </span>

        <div className="mt-5 relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="insurance"
              aria-describedby="insurance-purchase"
              onChange={_ => setInsured(!insured)}
              checked={insured}
              name="insurance"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="insurance" className="font-medium text-gray-700">
              Add Deposit Cover&nbsp;
            </label>
            (Recommended)
          </div>
        </div>
        <button
          type="button"
          className="flex flex-row items-center"
          onClick={toggleExplainer}
        >
          <span className="mt-1 text-gray-500 sm:text-xs" id="price-currency">
            Learn more about deposit coverage
          </span>
          <InformationCircleIcon className="ml-2 w-5" />
        </button>
        <InsuranceExplainer open={explainerOpen} setOpen={toggleExplainer} />

        <div className="mt-5 flex justify-center">
          {!loading ? (
            <button
              disabled={amountInvalid()}
              onClick={() => withErrorHandling(makeDeposit)}
              className={[
                'justify-center w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:order-1',
                amountInvalid() ? 'opacity-60' : ''
              ].join(' ')}
            >
              DEPOSIT
            </button>
          ) : (
            <Spinner small />
          )}
        </div>
      </div>
    </div>
  )
}

export default SaveDeposit
