import { ethers } from 'ethers'
import { HDNode } from '@ethersproject/hdnode'
import { pbkdf2 } from '@ethersproject/pbkdf2'

import tokenlist from './tokenlist.json'

import dpiSVG from '../images/dpi.svg'
import mviSVG from '../images/mvi.svg'
import eth2xSVG from '../images/eth2x_fli.svg'
import dataSVG from '../images/data-index.svg'
import degenPNG from '../images/degen_logo.png'

export const DEFI_INDEXES = [
  {
    ticker: 'DPI',
    name: 'DeFi Pulse Index',
    image: dpiSVG,
    url: 'https://pro-api.coingecko.com/api/v3/coins/defipulse-index'
  },
  {
    ticker: 'MVI',
    name: 'Metaverse Index',
    image: mviSVG,
    url: 'https://pro-api.coingecko.com/api/v3/coins/metaverse-index'
  },
  {
    ticker: 'ETH2x-FLI-P',
    name: 'ETH 2x Flexible Leverage Index',
    image: eth2xSVG,
    url: 'https://pro-api.coingecko.com/api/v3/coins/index-coop-eth-2x-flexible-leverage-index'
  },
  {
    ticker: 'DATA',
    name: 'Data Economy Index',
    image: dataSVG,
    url: 'https://pro-api.coingecko.com/api/v3/coins/data-economy-index'
  },
  {
    ticker: 'DEGEN',
    name: 'DEGEN Index ',
    image: degenPNG,
    url: 'https://pro-api.coingecko.com/api/v3/coins/degen-index'
  }
]

export const getTokenList = () => tokenlist.tokens

export const getBaseUrl = () => process.env.REACT_APP_API_URL
export const saveToken = token => sessionStorage.setItem('token', token)

export const rounder = (num, places, mode) => {
  // (A1) MULTIPLIER
  var mult = parseInt('1' + '0'.repeat(places))
  num = num * mult

  // (A2) ROUND OFF
  if (mode === 1) {
    num = Math.ceil(num)
  } else if (mode === 0) {
    num = Math.floor(num)
  } else {
    num = Math.round(num)
  }

  // (A3) RETURN RESULTS
  return num / mult
}

export const currencyFormat = (num, precision) => {
  let number = parseFloat(num) || 0
  let rounding_precision = precision || 2
  let rounded_number = rounder(number, rounding_precision, 0)
  return '$' + `${rounded_number}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const tokenFormat = (amount, decimals) => {
  return parseFloat(ethers.utils.formatUnits(amount, decimals)).toFixed(2)
}

export const setPaytrieToken = token => {
  let object = {
    expiresAt: Date.now() + 3600000,
    value: token
  }

  sessionStorage.setItem('paytrieToken', JSON.stringify(object))
}

export const getPaytrieToken = token => {
  let key = 'paytrieToken'
  let stringValue = sessionStorage.getItem(key)
  if (stringValue !== null) {
    let value = JSON.parse(stringValue)
    if (value.expiresAt > Date.now()) {
      return value.value
    } else {
      sessionStorage.removeItem(key)
    }
  }
  return null
}

export const getAuthToken = () => sessionStorage.getItem('token')

export const saveAddress = address =>
  sessionStorage.setItem('safeAddress', address)

export const saveSignerAddress = address =>
  sessionStorage.setItem('signerAddress', address)

export const getSafeAddress = () => sessionStorage.getItem('safeAddress')

export const getProvider = () => {
  let url = process.env.REACT_APP_ALCHEMY_URL
  let provider = new ethers.providers.JsonRpcProvider(url)

  return provider
}

export const getSigner = () => {
  let pk = sessionStorage.getItem('pk')
  let provider = getProvider()

  return new ethers.Wallet(pk, provider)
}

function handleResponse(response) {
  if (response.status === 401) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/login'
  } else {
    return response
  }
}

export const request = (path, options = {}) => {
  const token = getAuthToken()
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    ...options.headers
  }

  return fetch(`${getBaseUrl()}${path}`, { ...options, headers }).then(
    handleResponse
  )
}

export const signInSignature = async wallet => {
  let address = wallet.address
  let hash = await ethers.utils.keccak256(address)
  let signature = await wallet.signMessage(ethers.utils.arrayify(hash))

  return signature
}

export const createNode = async (password, email, salt) => {
  const enc = new TextEncoder()
  const saltBytes = ethers.utils.base64.decode(salt)
  const derivedBits = pbkdf2(
    enc.encode(`${password}${email}`),
    saltBytes,
    100000,
    32,
    'sha256'
  )
  const seedBytes = new Uint8Array(ethers.utils.arrayify(derivedBits))
  const node = await HDNode.fromSeed(seedBytes)
  return node
}

export async function validateToken() {
  try {
    await request('/me')
    return true
  } catch {
    return false
  }
}

export async function walletFromPasswordAndEmail(password, email, salt) {
  let node = await createNode(password, email, salt)
  let wallet = new ethers.Wallet(node.privateKey)

  sessionStorage.setItem('signerAddress', wallet.address)

  return wallet
}

export async function decodeAndSave({
  password,
  email,
  safe_address,
  signer_address,
  salt
}) {
  let { privateKey: pk } = await walletFromPasswordAndEmail(
    password,
    email,
    salt
  )

  saveAddress(safe_address)

  sessionStorage.setItem('pk', pk)
  sessionStorage.setItem('signerAddress', signer_address)
}

export function handleLogout(event) {
  event && event.preventDefault()
  localStorage.clear()
  sessionStorage.clear()
  window.location.href = '/'
}

export const isLoggedIn = () => Boolean(sessionStorage.getItem('pk'))

export const getEmail = () => localStorage.getItem('email')

export const getSignerAddress = () => localStorage.getItem('signerAddress')

export const chunk = (arr, size) => {
  let chunked = []
  for (let ele of arr) {
    let last = chunked[chunked.length - 1]
    if (!last || last.length === size) {
      chunked.push([ele])
    } else {
      last.push(ele)
    }
  }
  return chunked
}

export const areStringsEqual = (string1, string2) =>
  string1.toLowerCase() === string2.toLowerCase()

export const MATIC_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const truncateAddress = address => {
  let beginning = address.slice(0, 5)
  let end = address.slice(address.length - 5, address.length)

  return `${beginning}...${end}`
}
