import { ethers } from 'ethers'

export function hexToDAI(hex_string) {
  return ethers.utils.formatUnits(ethers.BigNumber.from(hex_string), 18)
}

export function tokenDecimals(symbol) {
  switch (symbol) {
    case 'DAI':
      return 18
    case 'USDC':
      return 6
    default:
      return 18
  }
}

export function formatTokenValue(symbol, value) {
  let decimals = tokenDecimals(symbol)

  return ethers.utils.formatUnits(value, decimals)
}
