import React from 'react'
import NumberFormat from 'react-number-format'

const PhoneNumberVerification = ({
  phoneVerificationSent,
  setPhoneCode,
  phoneCode,
  setPhone,
  phone,
  handlePhoneCode,
  handlePhoneSubmit
}) => {
  return (
    <div className="mr-10 md:mr-0 w-full">
      <h3 className="text-md leading-6 font-medium text-copy-800">
        {phoneVerificationSent ? (
          <p>Verification code</p>
        ) : (
          <p>You phone number</p>
        )}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        {phoneVerificationSent ? (
          <p>Please enter the code you received</p>
        ) : (
          <p>Please enter your phone number</p>
        )}
      </div>
      <div className="mt-5 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs">
          <label htmlFor="email" className="sr-only">
            Phone
          </label>

          {phoneVerificationSent ? (
            <input
              onChange={e => setPhoneCode(e.target.value)}
              type="text"
              name="code"
              id="code"
              className="w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
              value={phoneCode}
            />
          ) : (
            <NumberFormat
              value={phone}
              onChange={e => setPhone(e.target.value)}
              format="+1 (###) ###-####"
              mask="_"
              className="w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
            />
          )}
        </div>
        {phoneVerificationSent ? (
          <button
            onClick={handlePhoneCode}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Submit code
          </button>
        ) : (
          <button
            onClick={handlePhoneSubmit}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Send verification code
          </button>
        )}
      </div>
    </div>
  )
}

export default PhoneNumberVerification
