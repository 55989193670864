import React from 'react'
import NumberFormat from 'react-number-format'

const PersonalInformation = ({
  phone,
  setPhone,
  setFirstName,
  setLastName,
  setDob,
  setOccupation,
  setAddress1,
  setCity,
  setState,
  setZip,
  setPep,
  setTpd,
  handleKycSubmit,
  kycFormValid
}) => {
  const phoneWithoutCountryCode = phone => {
    let regex = /1(\d{10})/
    let found = phone.match(regex)
    if (found) {
      return found[1]
    } else {
      return phone
    }
  }

  return (
    <div className="pr-12">
      <div className="mb-10">
        <div>
          <h3 className="text-md leading-6 font-medium text-copy-800">
            Personal Information
          </h3>
          <p className="mt-1 text-sm text-gray-500">All fields are required.</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              First name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                onChange={e => setFirstName(e.target.value)}
                autoComplete="given-name"
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Last name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="last-name"
                onChange={e => setLastName(e.target.value)}
                id="last-name"
                autoComplete="family-name"
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="dob"
              className="block text-sm font-medium text-gray-700"
            >
              Date of birth (yyyy-mm-dd)
            </label>
            <div className="mt-1">
              <NumberFormat
                placeholder="yyyy-mm-dd"
                name="dob"
                id="dob"
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-1/2 sm:text-sm border-gray-300 rounded-md"
                onChange={e => setDob(e.target.value)}
                format="####-##-##"
                mask="_"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="occupation"
              className="block text-sm font-medium text-gray-700"
            >
              Occupation
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="occupation"
                id="occupation"
                onChange={e => setOccupation(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700"
            >
              Country / Region
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country"
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option>Canada</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <div className="mt-1">
              <NumberFormat
                value={phoneWithoutCountryCode(phone)}
                onChange={e => setPhone(e.target.value)}
                format="+1 (###) ###-####"
                mask="_"
                className="w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor="street-address"
              className="block text-sm font-medium text-gray-700"
            >
              Street address
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street-address"
                id="street-address"
                autoComplete="street-address"
                onChange={e => setAddress1(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                onChange={e => setCity(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="province"
              className="block text-sm font-medium text-gray-700"
            >
              State / Province
            </label>

            <div className="mt-1">
              <select
                id="province"
                name="province"
                autoComplete="province"
                onChange={e => setState(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option></option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="postal"
              className="block text-sm font-medium text-gray-700"
            >
              ZIP / Postal
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="postal"
                name="postal"
                autoComplete="postal-code"
                placeholder="A0B0C1"
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-2/3 sm:text-sm border-gray-300 rounded-md"
                onChange={e => setZip(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5">
        <div>
          <fieldset>
            <div className="space-y-4">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="pep"
                    name="pep"
                    type="checkbox"
                    onChange={e => setPep(e.target.checked)}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="pep" className="font-medium text-gray-700">
                    PEP
                  </label>
                  <p className="text-gray-500">
                    I confirm that I am not a{' '}
                    <a
                      className="text-orange-500 hover:text-orange:600"
                      href="https://faq.paytrie.com/article/29-what-is-a-politically-exposed-person-pep"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Politically Exposed Person
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="tpd"
                    name="tpd"
                    type="checkbox"
                    onChange={e => setTpd(e.target.checked)}
                    className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="tpd" className="font-medium text-gray-700">
                    TPD
                  </label>
                  <p className="text-gray-500">
                    I confirm that I am not completing this application on
                    behalf of someone else. Read more about{' '}
                    <a
                      className="text-orange-500 hover:text-orange:600"
                      rel="noreferrer"
                      target="_blank"
                      href="https://faq.paytrie.com/article/30-what-is-third-party-determination-tpd"
                    >
                      Third Party Determination
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={handleKycSubmit}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50"
            disabled={kycFormValid()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default PersonalInformation
