import { request } from './index'
import { format, subDays } from 'date-fns'

const weekAgo = () => format(subDays(new Date(), 7), 'yyyy-MM-dd')
export const today = () => format(new Date(), 'yyyy-MM-dd')

export const getSaveReturns = () =>
  request(`/api/balances/save_return`).then(res => res.json())

export const getSaveBalance = () =>
  request(`/api/balances/save_balance`).then(res => res.json())

export const getBalances = (from = weekAgo(), to = today()) =>
  request(`/api/balances/save_balances?from=${from}&to=${to}`).then(res =>
    res.json()
  )

export const getSupplyAPR = async () => {
  let { supply_apr } = await request(
    `/api/protocols/mstable/asset/USDC/supply_apr`
  ).then(res => res.json())

  return supply_apr
}

export const getExchangeRate = async () => {
  let { exchange_rate } = await request(
    `/api/protocols/mstable/exchange_rate`
  ).then(res => res.json())

  return exchange_rate
}
