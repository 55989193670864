import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { Link } from 'react-router-dom'
import { currencyFormat, rounder } from '../utils'
import { getAllCoins } from '../utils/coingecko'
import SectionHeading from './SectionHeading'

import { observer } from 'mobx-react-lite'
import { store, setCoins } from '../store'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

export default observer(function TokensTable({ tokens, title, type }) {
  let coins = store.coins
  /* eslint-disable */
  useEffect(() => {
    ;(async () => {
      if (coins.length <= 0) {
        setCoins(await getAllCoins())
      }
    })()
  }, [tokens])

  let filteredCoins = coins.filter(({ symbol }) =>
    tokens.find(token => token.contract_ticker_symbol.toLowerCase() == symbol)
  )

  const formatBalance = (balance, decimals) => {
    balance = ethers.utils.formatUnits(balance, decimals)
    return rounder(parseFloat(balance), 4, 9)
  }

  const calculate24hChange = (rate_today, rate_yesterday) => {
    let change = rate_today - rate_yesterday
    let percent_change = (change / rate_yesterday) * 100
    return rounder(percent_change, 2, 0)
  }

  let filteredTokens = tokens && tokens.filter(token => token.type === type)

  if (filteredTokens && filteredTokens.length <= 0) return ''

  return (
    <section className="mb-10">
      <SectionHeading heading={title} />
      <table className="min-w-full divide-y divide-gray-200 mt-8">
        <thead className="border-b">
          <tr>
            <th
              scope="col"
              className="pl-2 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Asset
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Price
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              24h %
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Balance
            </th>
            <th
              scope="col"
              className="py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTokens &&
            filteredTokens.map((token, tokenIdx) => {
              let tokenData = filteredCoins.find(
                ({ symbol }) =>
                  token.contract_ticker_symbol.toLowerCase() == symbol
              )

              let percentage_change = tokenData
                ? tokenData.price_change_percentage_24h.toFixed(2)
                : 'N/A'

              return (
                <tr
                  key={tokenIdx}
                  className={tokenIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td className="pl-2 py-4 whitespace-nowrap text-md font-medium text-gray-900">
                    <div className="flex items-center">
                      <Link
                        to={`/coins/${token.contract_ticker_symbol}`}
                        className="flex flex-row flex-nowrap mt-2 pb-2"
                        title={token.contract_ticker_symbol}
                      >
                        <img
                          className="h-7 w-7 mr-2"
                          alt={token.contract_ticker_symbol}
                          src={token.logo_url}
                        />
                        <span>{token.contract_ticker_symbol}</span>
                      </Link>
                    </div>
                    <div className="mt-1 ml-1 flex flex-row text-gray-500 text-sm sm:hidden">
                      <span>
                        {formatBalance(token.balance, token.contract_decimals)}
                      </span>
                      <span className="inline">&nbsp;@&nbsp;</span>
                      <span>{currencyFormat(token.quote_rate, 2)}</span>
                    </div>
                  </td>
                  <td className="hidden sm:table-cell py-4 whitespace-nowrap text-md text-gray-500">
                    {currencyFormat(token.quote_rate, 2)}
                  </td>
                  <td
                    className={classNames(
                      percentage_change > 0 ? 'text-green-500' : 'text-red-500',
                      'hidden sm:table-cell py-4 whitespace-nowrap text-md'
                    )}
                  >
                    {percentage_change} %
                  </td>
                  <td className="hidden sm:table-cell py-4 whitespace-nowrap text-md text-gray-500">
                    {formatBalance(token.balance, token.contract_decimals)}
                  </td>
                  <td className="py-4 whitespace-nowrap text-md text-gray-500">
                    {currencyFormat(token.quote, 2)}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </section>
  )
})
