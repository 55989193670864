import { useAccount, useNetwork } from 'wagmi'
import { truncateAddress } from '../utils'

import Spinner from '../components/Spinner'
import { Connect } from '../components/Connect'

const LoginWithWallet = ({ loading, callback }) => {
  const { data } = useAccount()
  const { activeChain, chains, switchNetwork } = useNetwork()

  return (
    <div className="max-w-md w-full mr-16 sm:mt-48">
      <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
        Login with your wallet
      </h2>
      <div>
        <p className="mt-2 text-center text-sm text-gray-600">
          If you signed up with your wallet, use this option instead of email to
          authenticate.
        </p>
      </div>
      <div className="text-center">
        <div className="py-8 px-4">
          {loading ? (
            <Spinner />
          ) : data ? (
            <div>
              <button
                onClick={() => callback(data)}
                className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-2"
              >
                Login with Wallet
                <div className="text-yellow-300 ml-3">
                  {truncateAddress(data?.address)}
                </div>
              </button>
            </div>
          ) : (
            <Connect callback={callback} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginWithWallet
