import React, { useState } from 'react'
//import { ethers } from 'ethers'

import { ClipboardCopyIcon, CheckIcon } from '@heroicons/react/solid'
import Transfer from './Transfer'

const Deposit = ({
  amount,
  insuranceQuote,
  nextStep,
  walletAddress,
  walletBalance,
  setWalletBalance
}) => {
  //const [transferred, setTransfer] = useState(0)
  const [copied, setCopied] = useState(false)
  //const DAI_ADDRESS = process.env.REACT_APP_DAI_ADDRESS
  //const filter = {
  //  address: DAI_ADDRESS,
  //  topics: [
  //    ethers.utils.id('Transfer(address,address,uint256)'),
  //    null,
  //    ethers.utils.hexZeroPad(walletAddress, 32)
  //  ]
  //}
  //let url = `https://${process.env.REACT_APP_NETWORK}.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`
  //let options = {
  //  method: 'POST',
  //  body: JSON.stringify({
  //    id: 1337,
  //    jsonrpc: '2.0',
  //    method: 'eth_newFilter',
  //    params: [walletAddress, 'latest']
  //  })
  //}

  //const newFilter = () => fetch(url, options).then(res => res.json())

  // Apparently WS & filters are not supported on polygon

  //  useEffect(() => {
  //    const wsProvider = new ethers.providers.WebSocketProvider(
  //      `wss://${process.env.REACT_APP_NETWORK}.infura.io/ws/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`,
  //      process.env.REACT_APP_NETWORK
  //    )
  //
  //    wsProvider.on(filter, (log, event) => {
  //      let { data } = log
  //      let received_amount_raw = ethers.BigNumber.from(data)
  //      let received_amount = parseFloat(
  //        ethers.utils.formatUnits(received_amount_raw, 18)
  //      )
  //      let newBalance =
  //        parseFloat(walletBalance) +
  //        parseFloat(transferred) +
  //        parseFloat(received_amount)
  //      setTransfer(parseFloat(transferred) + parseFloat(received_amount))
  //      setWalletBalance(newBalance)
  //    })
  //  }, [transferred, setTransfer, setWalletBalance, walletBalance, walletAddress])

  //  const { isLoading: isLoadingBalance, error: errorBalance } = useQuery(
  //    'newFilter', newFilter,
  //    {
  //      onSuccess: (log) => {
  //        console.log(log)
  //        let { data } = log
  //        let received_amount_raw = ethers.BigNumber.from(data)
  //        let received_amount = parseFloat(
  //          ethers.utils.formatUnits(received_amount_raw, 18)
  //        )
  //        let newBalance =
  //          parseFloat(walletBalance) +
  //          parseFloat(transferred) +
  //          parseFloat(received_amount)
  //
  //        setTransfer(parseFloat(transferred) + parseFloat(received_amount))
  //        setWalletBalance(newBalance)
  //      },
  //      refetchInterval: 1000
  //    }
  //  )

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress)
    setCopied(true)
  }

  return (
    <div className="min-h-screen flex flex-col py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white px-4 py-5 border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Waiting for deposit
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            The Ethereum wallet address listed below is the address of your new
            smart contract wallet
          </p>

          <div className="rounded-full border py-3 mt-5 bg-gray-50 text-center relative">
            <button
              className="absolute inset-y-0 right-0 pr-5 flex items-center"
              onClick={() => handleCopyToClipboard()}
            >
              {copied ? (
                <CheckIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              ) : (
                <ClipboardCopyIcon
                  className="h-5 w-5 text-gray-400 hover:text-gray-600"
                  aria-hidden="true"
                />
              )}
            </button>
            {walletAddress}
          </div>
        </div>

        <div className="bg-white border-gray-200 text-center">
          Available Balance:{' '}
          <span className="text-orange-500 font-bold">{walletBalance} DAI</span>
        </div>

        <Transfer
          amount={amount}
          walletBalance={walletBalance}
          nextStep={nextStep}
        />
      </div>
    </div>
  )
}

export default Deposit
