import { React, useState, useEffect } from 'react'
import { useAccount, useNetwork, useDisconnect } from 'wagmi'
import { Connect } from './Connect'
import { truncateAddress } from '../utils'
import getAvatar from 'dravatar'

import AccountModal from './AccountModal'
import Spinner from './Spinner'

export const Account = () => {
  const {
    data: accountData,
    isError,
    isLoading
  } = useAccount({
    fetchEns: true
  })

  const { disconnect } = useDisconnect()
  const { activeChain } = useNetwork()
  const [open, setOpen] = useState(false)
  const [avatar, setAvatar] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetch_avatar() {
      let address = accountData?.address
      setLoading(true)
      let avatar = await getAvatar(address)
      setAvatar(avatar)
      setLoading(false)
    }

    if (accountData) {
      fetch_avatar()
    }
  }, [accountData?.address])

  if (!accountData) return <Connect />

  if (loading || isLoading) return null

  return (
    <div className="flex justify-center items-center">
      <div
        className="text-white flex bg-white shadow hover:bg-gray-50 px-3 py-2 rounded-r-md rounded-l-md justify-between items-center cursor-pointer px-2"
        onClick={() => setOpen(true)}
      >
        <img
          className="inline-block h-4 w-4 rounded-full"
          alt=""
          src={avatar}
        />
        <div className="text-gray-800 ml-2">
          {truncateAddress(accountData?.address)}
        </div>
        <div className="flex items-center ml-4">
          <div>
            <div className="w-2 h-2 bg-green-400 rounded-full"></div>
          </div>
          <div className="text-gray-800 ml-1 text-xs">{activeChain?.name}</div>
        </div>
      </div>
      <AccountModal
        disconnect={disconnect}
        open={open}
        setOpen={setOpen}
        address={accountData?.address}
      />
    </div>
  )
}
