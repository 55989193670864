import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { ethers } from 'ethers'
import WebsocketContext from '../context/WebsocketContext'
import { BadgeCheckIcon } from '@heroicons/react/outline'
import CErc20 from '../forge/abis/CErc20.json'

function Loading() {
  return (
    <main className="flex">
      <div className="ml-8 justify-left">
        <Spinner />
      </div>

      <div className="mt-14 ml-8">
        Waiting for deposit{' '}
        <span className="text-sm text-gray-500 ml-3">
          This may take a few minutes
        </span>
      </div>
    </main>
  )
}

function Received({ amount }) {
  return (
    <div className="sm:flex sm:items-start ml-8 mt-10">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
        <BadgeCheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h1 className="text-lg">Transaction successful</h1>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            You received {amount} in your Zyield wallet.
          </p>
        </div>
      </div>
    </div>
  )
}

const FiatDepositStatus = props => {
  let [loading, setLoading] = useState(true)
  let [amountReceived, setAmountReceived] = useState(null)
  let wsProvider = React.useContext(WebsocketContext)

  const search = useLocation().search
  const safe_address = new URLSearchParams(search).get('safe_address')

  useEffect(() => {
    async function fetchData(safe_address) {
      let { result } = await fetch(
        `https://api.polygonscan.com/api?module=proxy&action=eth_blockNumber&apikey=${process.env.REACT_APP_POLYGONSCAN_API_KEY}`
      ).then(res => res.json())

      let currentBlock = ethers.BigNumber.from(result).toNumber()

      const USDC_ADDRESS = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
      let provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_ALCHEMY_URL
      )
      const usdcContract = new ethers.Contract(USDC_ADDRESS, CErc20, provider)
      const filter = usdcContract.filters.Transfer(null, safe_address)

      let transfers = await usdcContract.queryFilter(
        filter,
        currentBlock - 1000,
        'latest'
      )

      if (transfers.length > 0) {
        let transfer = transfers[0]

        let { data: rawAmountReceived } = transfer
        let decimals = 6
        let amount = ethers.utils.formatUnits(
          ethers.BigNumber.from(rawAmountReceived),
          decimals
        )

        setAmountReceived(amount)
        setLoading(false)
      } else {
        const wsFilter = {
          address: USDC_ADDRESS,
          topics: [
            ethers.utils.id('Transfer(address,address,uint256)'),
            null,
            ethers.utils.hexZeroPad(safe_address, 32)
          ]
        }

        wsProvider.on(wsFilter, (log, event) => {
          let { data: rawAmountReceived } = log
          let decimals = 6
          let amount = ethers.utils.formatUnits(
            ethers.BigNumber.from(rawAmountReceived),
            decimals
          )

          setAmountReceived(amount)
          setLoading(false)
        })
      }
    }

    fetchData(safe_address)
  }, [safe_address, setAmountReceived, setLoading, wsProvider])

  return <>{loading ? <Loading /> : <Received amount={amountReceived} />}</>
}

export default FiatDepositStatus
