import { Fragment, useState } from 'react'
import { useQuery } from 'react-query'
import { Dialog, Transition } from '@headlessui/react'
import { request, setPaytrieToken } from '../utils'
import { BadgeCheckIcon, XIcon } from '@heroicons/react/outline'
import Spinner from './Spinner'

function LoadingState() {
  return (
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10">
        <Spinner />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Authenticate with Paytrie
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Please check your email titled "Continue logging into PayTrie" and
            click on the "Login" button inside the email. Once done, please
            close the Paytrie authentication tab and return to the Zyield app."
          </p>
        </div>
      </div>
    </div>
  )
}

function SuccessState() {
  return (
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
        <BadgeCheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Authentication successful
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500"></p>
        </div>
      </div>
    </div>
  )
}

export default function PaytrieAuthModal({ open, setOpen, time, email }) {
  let [spinner, setSpinner] = useState(true)

  useQuery(
    'checkAuth',
    () =>
      request(`/api/fiat_gateway/check_auth`, {
        method: 'POST',
        body: JSON.stringify({ email, time })
      }).then(res => res.json()),
    {
      enabled: Boolean(open),
      retry: true,
      retryDelay: 5000,
      onSuccess: ({ token, status }) => {
        if (status === 'ready') {
          setOpen(false)
          setSpinner(false)
          // save token in session, it's valid for 1h
          setPaytrieToken(token)
        }
      }
    }
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {spinner ? <LoadingState /> : <SuccessState />}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
