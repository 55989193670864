import Spinner from '../../components/Spinner'

export default function ConfirmEmailForm({
  url,
  setEmail,
  email,
  error,
  loading,
  submit
}) {
  return (
    <div className="max-w-md w-full space-y-8">
      <div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Recover my acount
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Please confirm your email to begin the process.
        </p>
      </div>
      <form className="mt-8 space-y-6" action={url} method="POST">
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm rounded-md"
              placeholder="Email address"
            />
          </div>
        </div>

        <div>
          {loading ? (
            <Spinner />
          ) : (
            <button
              type="button"
              onClick={submit}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2
ocus:ring-offset-2 focus:ring-orange-500"
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  )
}
